import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

const tabs = {
   '/home': 'Overview',
   '/home/logs': 'Query History',
   '/home/queries': 'Saved Queries',
   '/home/snippets': 'Snippets',
};

export const HomeNav = ({ children, title }: { children?: React.ReactNode; title?: string }) => {
   return (
      <Nav className="flex-column">
         {Object.entries(tabs).map(([id, name]) => (
            <Nav.Link
               as={NavLink}
               className="fs-13p fw-500 text-body hover-highlight nav-link-left-menu py-1 px-3"
               end
               eventKey={id}
               key={id}
               role={'tab'}
               to={id}
            >
               {name}
            </Nav.Link>
         ))}
      </Nav>
   );
};

export default HomeNav;
