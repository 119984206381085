import { useContext, useEffect, useLayoutEffect, useMemo, useState, useCallback } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { FaChartBar, FaChartLine, FaChartPie, FaChartArea } from 'react-icons/fa';
import { TbChartScatter, TbChartTreemap } from 'react-icons/tb';
import { FaRegChartBar } from 'react-icons/fa6';
import { MdBubbleChart } from 'react-icons/md';
import { HiSparkles } from 'react-icons/hi2';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import Accessibility from 'highcharts/modules/accessibility';
import { useInjection } from 'inversify-react';
import { Button, Spinner, Stack } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { ThemeContext } from '../components/ThemeContext';
import { ChartConfig, QueryVersion, SuggestedCharts } from '../entities';
import { QueryReturn } from '../interfaces';
import { QueryService } from '../services';
import { TYPES } from '../types';
import { isText } from '../utilities';

// Initialize the accessibility module
Accessibility(Highcharts);
highchartsMore(Highcharts);
const chartTypeToIcon: { [key: string]: JSX.Element } = {
   line: <FaChartLine size={20} />, // Line chart
   bar: <FaRegChartBar size={20} />, // Bar chart
   pie: <FaChartPie size={20} />, // Pie chart
   area: <FaChartArea size={20} />, // Area chart
   scatter: <TbChartScatter size={20} />, // Scatter plot
   column: <FaChartBar size={20} />, // Column chart
   treemap: <TbChartTreemap size={20} />, // Treemap chart
   bubble: <MdBubbleChart size={20} />, // Bubble chart
};

export const QueryChart = ({
   allowChartGeneration,
   containsPython,
   isCurrentPanel,
   queryReturn,
   queryVersion,
   setShowUpgradeModal,
}: {
   allowChartGeneration?: boolean;
   containsPython: boolean;
   isCurrentPanel: boolean;
   queryReturn?: QueryReturn;
   queryVersion: QueryVersion;
   setShowUpgradeModal?: () => void;
}): JSX.Element => {
   const [chartConfig, setChartConfig] = useState<ChartConfig | undefined>(
      queryVersion.chartConfig
   );
   const [chartSuggestions, setChartSuggestions] = useState<SuggestedCharts | undefined>(
      queryVersion.chartSuggestions
   );
   const [forceChartRender, setForceChartRender] = useState(1);
   const [chartType, setChartType] = useState<string>('');
   const [errorMessage, setErrorMessage] = useState<string | undefined>();
   const defaultChartTypes = ['bar', 'line', 'pie'];
   const themeContext = useContext(ThemeContext);
   const isDarkMode = themeContext?.mode === 'dark';

   const service = useInjection<QueryService>(TYPES.queryService);

   const getRowStructure = useCallback((queryReturn?: QueryReturn) => {
      if (!queryReturn?.rows?.length) return undefined;
      else {
         const rowStructure: Record<string, string> = {};
         Object.entries(queryReturn.rows[0]).forEach(
            ([key, value]) => (rowStructure[key] = typeof value)
         );
         return rowStructure;
      }
   }, []);

   const rowStructure = getRowStructure(queryReturn);
   const isChartAllowed =
      rowStructure === undefined ||
      !Object.values(rowStructure).every((value) => value === 'string');

   const createChartMutator = useMutation({
      mutationFn: async ({
         chartType,
         queryVersion,
         queryReturn,
      }: {
         chartType?: string;
         queryReturn?: QueryReturn;
         queryVersion: QueryVersion;
      }) => {
         if (!isChartAllowed) {
            return {} as ChartConfig;
         }
         return await service.createChartConfig(
            queryVersion,
            chartType,
            getRowStructure(queryReturn)
         );
      },
      onSuccess: (data) => {
         if (data) {
            setChartConfig(data);
            setForceChartRender((i) => i + 1);
         }
      },
      onError: (error: Error) => {
         if (error.message.startsWith(`You've generated`)) {
            // Handle the "Chart AI limit exceeded" error gracefully
            setErrorMessage(error.message);
         } else {
            console.error('An unexpected error occurred:', error);
         }
      },
   });

   const suggestedChartsMutator = useMutation({
      mutationFn: async ({
         queryVersion,
         queryReturn,
      }: {
         queryReturn?: QueryReturn;
         queryVersion: QueryVersion;
      }) => {
         return await service.suggestChartTypes(queryVersion, getRowStructure(queryReturn));
      },
      onSuccess: (data) => {
         if (data) {
            setChartSuggestions(data);
            setForceChartRender((i) => i + 1);
         }
      },
   });

   const createKeyMap = (row: { [key: string]: any }) => {
      const map: { [key: string]: string } = {};
      Object.keys(row).forEach((key) => {
         map[key.toLowerCase()] = key;
      });
      return map;
   };

   const chartOptions: Highcharts.Options | undefined = useMemo(() => {
      if (!chartConfig?.dataMapping || !Array.isArray(chartConfig.dataMapping)) {
         return undefined;
      }

      return {
         ...chartConfig.chartConfig,
         credits: { enabled: false },
         plotOptions: {
            ...chartConfig.chartConfig.plotOptions,
            series: {
               ...chartConfig.chartConfig.plotOptions?.series,
               dataLabels: {
                  ...chartConfig.chartConfig.plotOptions?.series?.dataLabels,
                  style: {
                     ...chartConfig.chartConfig.plotOptions?.series?.dataLabels?.style,
                     textOutline: 'none',
                  },
               },
            },
         },
         series: chartConfig.dataMapping.map((mapping) => {
            const mappingX = mapping.x?.toLowerCase() ?? '';
            const mappingY = mapping.y?.toLowerCase() ?? '';
            return {
               name: mapping.seriesName,
               data:
                  Array.isArray(queryReturn?.rows) &&
                  queryReturn?.rows.map((row: { [key: string]: any }) => {
                     const rowKeyMap = createKeyMap(row);
                     const x = rowKeyMap[mappingX] ?? mapping.x;
                     const y = rowKeyMap[mappingY] ?? mapping.y;
                     let xVal = undefined;
                     if (x) {
                        xVal = Number(row[x]);
                        xVal = isNaN(xVal) ? row[x] : xVal;
                     }
                     let yVal = Number(row[y]);
                     yVal = isNaN(yVal) ? row[y] : yVal;
                     return xVal !== undefined ? [xVal, yVal] : [yVal];
                  }),
            };
         }),
      };
   }, [queryReturn?.rows, chartConfig]);

   useLayoutEffect(() => {
      setChartConfig((chartConfig) =>
         chartConfig ?? queryVersion.chartConfig
            ? { ...((chartConfig ?? queryVersion.chartConfig) as ChartConfig) }
            : undefined
      );
   }, [queryVersion.chartConfig, forceChartRender]);

   const handleCreateChart = useCallback(
      async (chartType?: string): Promise<void> => {
         setChartType(chartType ?? '');
         if (!queryVersion.id) throw new Error('Query version id not found');
         try {
            suggestedChartsMutator.mutateAsync({ queryVersion, queryReturn });
            await createChartMutator.mutateAsync({
               chartType: chartType,
               queryReturn: queryReturn,
               queryVersion: queryVersion,
            });
         } catch (e) {
            console.error(e);
         }
      },
      [queryVersion, queryReturn, suggestedChartsMutator, createChartMutator]
   );

   useEffect(() => {
      if (
         allowChartGeneration &&
         chartConfig === undefined &&
         queryReturn !== undefined &&
         isCurrentPanel
      ) {
         handleCreateChart();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [chartConfig, queryReturn, isCurrentPanel, allowChartGeneration]);

   const getErrorMessage = (
      <div className="card border-1 m-2 p-2">
         {errorMessage?.startsWith(`You've generated`) ? (
            <div
               className="pt-2"
               style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}
            >
               {errorMessage.split('Click here')[0]}{' '}
               <Button
                  onClick={() => {
                     if (setShowUpgradeModal) {
                        setShowUpgradeModal();
                     }
                  }}
                  style={{
                     backgroundColor: 'transparent',
                     border: 'none',
                     color: 'inherit',
                     padding: 0,
                     margin: 0,
                     textDecoration: 'underline',
                     cursor: 'pointer',
                     fontSize: '12px',
                  }}
               >
                  Click here
               </Button>{' '}
               to upgrade and keep going!
            </div>
         ) : (
            <span className="text-danger">
               {errorMessage}
               <br />
               The treasure you seek in the Data Temple remains elusive. Fear not! Unleash your
               inner Indiana Jones, grab your hat and rewrite your query with utmost care, for only
               then shall you conquer the forbidden depths and retrieve the data treasure you seek!
            </span>
         )}
      </div>
   );

   if (!themeContext) {
      return <div>Loading...</div>;
   }
   if (!isChartAllowed) {
      return <i>Charts cannot be generated from tables with only text data.</i>;
   }
   if (queryReturn === undefined) {
      return <i>Run this query to see or generate a chart.</i>;
   } else if (queryReturn.error !== undefined) {
      setErrorMessage(isText(queryReturn.error) ? queryReturn.error : queryReturn.error.message);
      return getErrorMessage;
   } else if (!queryReturn.rows?.length) {
      if (queryReturn.affectedRows > 0) {
         return (
            <div className="card border-1 m-2 p-2">
               Your query affected {queryReturn.affectedRows} rows, and took {queryReturn.runtime}{' '}
               ms.
            </div>
         );
      }
      return (
         <div className="card border-1 m-2 p-2">Your query was successful but returned 0 rows.</div>
      );
   }
   return (
      <div className="d-flex flex-column h-100">
         <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            {allowChartGeneration ? (
               <Stack gap={3}>
                  {createChartMutator.isError && (
                     <div className="d-flex flex-row justify-content-center px-2">
                        {getErrorMessage}
                     </div>
                  )}
                  {chartConfig !== undefined && (
                     <Stack gap={3}>
                        <div className="d-flex flex-row justify-content-center">
                           {suggestedChartsMutator.isLoading || createChartMutator.isLoading ? (
                              <div className="d-flex flex-row justify-content-center align-items-center">
                                 <Spinner size="sm" />
                                 <div className="m-2">Regenerating {chartType} chart...</div>
                              </div>
                           ) : (
                              <>
                                 {!errorMessage && (
                                    <>
                                       {chartSuggestions?.chartTypes?.length ? (
                                          // If there are suggested chart types, display them
                                          chartSuggestions.chartTypes.map((chartTypeObj, index) =>
                                             Object.entries(chartTypeObj).map(
                                                ([key, chartType]) => (
                                                   <button
                                                      className="btn btn-primary mx-1"
                                                      disabled={createChartMutator.isLoading}
                                                      key={`${index}-${key}`}
                                                      onClick={() => handleCreateChart(chartType)}
                                                      style={{ height: '30px' }}
                                                   >
                                                      {chartTypeToIcon[chartType] ??
                                                         chartType.charAt(0).toUpperCase() +
                                                            chartType.slice(1)}
                                                   </button>
                                                )
                                             )
                                          )
                                       ) : (
                                          <div className="d-flex flex-row gap-2">
                                             {defaultChartTypes.map((chartType) => (
                                                <button
                                                   className="btn btn-primary"
                                                   disabled={createChartMutator.isLoading}
                                                   key={chartType}
                                                   onClick={() => handleCreateChart(chartType)}
                                                   style={{ height: '30px' }}
                                                >
                                                   {chartTypeToIcon[chartType] ??
                                                      chartType.charAt(0).toUpperCase() +
                                                         chartType.slice(1)}
                                                </button>
                                             ))}
                                          </div>
                                       )}
                                       <button
                                          className="btn btn-primary btn-sm"
                                          disabled={createChartMutator.isLoading}
                                          onClick={() => handleCreateChart()}
                                          style={{
                                             height: '30px',
                                             fontSize: '12px',
                                             padding: '2px 5px',
                                             marginLeft: '10px',
                                             borderRadius: '5px',
                                          }} // Adjust size to match 20px
                                       >
                                          <HiSparkles className="me-2" size={20} />
                                          Regenerate Chart
                                       </button>
                                    </>
                                 )}
                              </>
                           )}
                        </div>
                        <div
                           className={isDarkMode ? 'highcharts-dark' : 'highcharts-light'}
                           id="container"
                        >
                           <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        </div>
                     </Stack>
                  )}
                  {createChartMutator.isLoading && chartConfig === undefined && (
                     <div className="d-flex flex-row justify-content-center align-items-center">
                        <Spinner size="sm" />
                        <div className="m-2">Generating chart...</div>
                     </div>
                  )}
               </Stack>
            ) : (
               <Stack>
                  {chartConfig !== undefined ? (
                     <div
                        className={isDarkMode ? 'highcharts-dark' : 'highcharts-light'}
                        id="container"
                     >
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                     </div>
                  ) : (
                     <i>
                        No chart generated. Ask someone with edit access to create a chart. Charts
                        must be generated on a new version.
                     </i>
                  )}
               </Stack>
            )}
         </div>
      </div>
   );
};
