import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { QueryReviewPage } from '../components';
import LoadingError from '../components/UI/LoadingError';
import { MdCheck } from 'react-icons/md';
import { QueryVersion, QueryVersionPatch } from '../entities';
import { getErrorMessage, getShortDateString, useTitle } from '../utilities';
import { useListQueryQuery, useUpdateQueryMutator } from '../hooks';
import ErrorBoundary from '../components/ErrorBoundary';

export function QueryUpdates() {
   useTitle('Schema Change - Query Updates');

   // Page State
   const [savingVersions, setSavingVersions] = useState<Record<number, boolean>>({});
   const [publishingAll, setPublishingAll] = useState(false);

   const savedQueriesQuery = useListQueryQuery({
      listOptions: {
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         version: 'ai-suggestions',
         includeWorkspace: true,
         includeParent: true,
      },
      callbacks: {
         onSuccess(data) {
            setSavingVersions(_.mapValues(data.items, () => false));
         },
      },
   });

   const updateQueryMutation = useUpdateQueryMutator({
      onSuccessCallback(newSavedQuery, variables, context) {
         setSavingVersions((previousVersions) => ({
            ...previousVersions,
            [variables.queryVersion.id!]: false,
         }));
      },
   });

   const publish = useCallback(
      async (queryVersion: QueryVersion) => {
         if (!queryVersion?.id) return;

         let queryUpdate: QueryVersionPatch = {
            queryVersion: {
               id: queryVersion.id,
            },
            publish: true,
         };
         setSavingVersions((previousVersions) => ({
            ...previousVersions,
            [queryVersion.id!]: true,
         }));
         return await updateQueryMutation.mutateAsync(queryUpdate);
      },
      [updateQueryMutation]
   );

   const publishAll = useCallback(async () => {
      if (!savedQueriesQuery.data) return;
      const saveCount = 5;
      setPublishingAll(true);
      for (let i = 0; i < savedQueriesQuery.data.items.length; i += saveCount) {
         const batch = savedQueriesQuery.data.items.slice(i, i + saveCount);
         await Promise.all(batch.map((q) => publish(q)));
      }
   }, [savedQueriesQuery.data, publish]);

   if (savedQueriesQuery.isError)
      return <LoadingError message={getErrorMessage(savedQueriesQuery.error)} />;

   const saving = publishingAll || _.filter(savingVersions, (value) => value).length > 0;

   const queryVersions = savedQueriesQuery.data?.items ?? [];

   return (
      <ErrorBoundary title={'An error occurred getting query updates.'}>
         <QueryReviewPage
            diffVersion={(queryVersion) => queryVersion.parent}
            extraActions={(queryVersion) => [
               <Button
                  disabled={saving}
                  key="accept"
                  onClick={() => publish(queryVersion)}
                  size="sm"
                  variant="success"
               >
                  {savingVersions[queryVersion.id!] || publishingAll ? (
                     'Saving...'
                  ) : (
                     <span>
                        <MdCheck /> Accept Changes{' '}
                     </span>
                  )}
               </Button>,
            ]}
            extraTabs={(queryVersion) => [
               {
                  title: 'Schema Changes',
                  body: (
                     <div className="ps-1">
                        <div className="fw-600 fs-11p pt-1">
                           Change Date: {getShortDateString(queryVersion?.created)}
                        </div>
                        {/* Changes */}
                        {((queryVersion?.schemaChanges?.tableNameChanges?.length ?? 0) > 0 ||
                           (queryVersion?.schemaChanges?.columnNameChanges?.length ?? 0) > 0) && (
                           <>
                              <div className="fw-600 fs-13p pt-3">Changes</div>
                              {(queryVersion?.schemaChanges?.tableNameChanges?.length ?? 0) > 0 && (
                                 <>
                                    <div>Tables:</div>
                                    {queryVersion?.schemaChanges?.tableNameChanges.map((t, i) => (
                                       <div key={i}>
                                          {t.oldTableName} <FaArrowRight /> {t.newTableName}
                                       </div>
                                    ))}
                                 </>
                              )}
                              {(queryVersion?.schemaChanges?.columnNameChanges?.length ?? 0) >
                                 0 && (
                                 <>
                                    <div className="pt-2">Columns:</div>
                                    {queryVersion?.schemaChanges?.columnNameChanges.map((c, i) => (
                                       <div key={i}>
                                          {c.tableName}.{c.oldColumnName} <FaArrowRight />{' '}
                                          {c.tableName}.{c.newColumnName}
                                       </div>
                                    ))}
                                 </>
                              )}
                           </>
                        )}

                        {/* Deletions */}
                        {(queryVersion?.schemaChanges?.tableDeletions?.length ?? 0) > 0 ||
                           ((queryVersion?.schemaChanges?.columnDeletions?.length ?? 0) > 0 && (
                              <>
                                 <div className="fw-600 fs-13p pt-3">Deletions</div>
                                 {(queryVersion?.schemaChanges?.tableDeletions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div>Tables:</div>
                                       {queryVersion?.schemaChanges?.tableDeletions.map((t, i) => (
                                          <div key={i}>{t.tableName}</div>
                                       ))}
                                    </>
                                 )}
                                 {(queryVersion?.schemaChanges?.columnDeletions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div className="pt-2">Columns:</div>
                                       {queryVersion?.schemaChanges?.columnDeletions.map((c, i) => (
                                          <div key={i}>
                                             {c.tableName}.{c.columnName}
                                          </div>
                                       ))}
                                    </>
                                 )}
                              </>
                           ))}

                        {/* Additions */}
                        {(queryVersion?.schemaChanges?.tableAdditions?.length ?? 0) > 0 ||
                           ((queryVersion?.schemaChanges?.columnAdditions?.length ?? 0) > 0 && (
                              <>
                                 <div className="fw-600 fs-13p pt-3">Additions</div>
                                 {(queryVersion?.schemaChanges?.tableAdditions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div>Tables:</div>
                                       {queryVersion?.schemaChanges?.tableAdditions.map((t, i) => (
                                          <div key={i}>{t.tableName}</div>
                                       ))}
                                    </>
                                 )}
                                 {(queryVersion?.schemaChanges?.columnAdditions?.length ?? 0) >
                                    0 && (
                                    <>
                                       <div className="pt-2">Columns:</div>
                                       {queryVersion?.schemaChanges?.columnAdditions.map((c, i) => (
                                          <div key={i}>
                                             {c.tableName}.{c.columnName} ({c.columnType})
                                          </div>
                                       ))}
                                    </>
                                 )}
                              </>
                           ))}
                     </div>
                  ),
               },
            ]}
            header={
               <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-inline-block">Schema Change - Query Updates</div>
                  <div className="d-inline-block ps-1">
                     {queryVersions.length > 0 && (
                        <Button
                           disabled={saving}
                           key="accept"
                           onClick={publishAll}
                           size="sm"
                           variant="success"
                        >
                           <span className="fs-10p">
                              <MdCheck /> Accept All Changes
                           </span>
                        </Button>
                     )}
                  </div>
               </div>
            }
            hideTabs={{ logs: true, comments: true }}
            queryVersions={queryVersions}
         >
            {savedQueriesQuery.data?.items.length === 0 && <p>No suggested updates.</p>}
         </QueryReviewPage>
      </ErrorBoundary>
   );
}

export default QueryUpdates;
