import { useInjection } from 'inversify-react';
import { NavLink } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { Card, Col, Nav, Row, Stack } from 'react-bootstrap';

import { AiPulse, Page, SearchInput, Button } from '../../components';
import WorkspaceCard from '../Workspace/WorkspaceCard';
import AddWorkspaceModal from '../../components/UI/AddWorkspaceModal';
import { Workspace, walkthroughStep } from '../../entities';
import { QueryKey } from '../../enums';
import { useGetAuthorizedExplorerQuery, useWorkspaces, useWalkthroughStep } from '../../hooks';
import { WorkspaceService } from '../../services';
import { TYPES } from '../../types';
import { handleError, notUndefined, useTitle } from '../../utilities';

const WorkspaceList = ({ my }: { my?: boolean }) => {
   const [searchText, setSearchText] = useState('');
   const [stepOne, setStepOne] = useWalkthroughStep(walkthroughStep.PERSONAL_DEMO_WORKSPACE);

   const cleanAndSetSearchText = (newValue: string) => {
      newValue = newValue.toLowerCase().trim();
      setSearchText(newValue);
   };
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   const filterByText = (value: Workspace, index: number, array: Workspace[]) => {
      if (value.name?.toLowerCase().includes(searchText)) {
         return true;
      }
      return false;
   };

   const workspaces = useWorkspaces();

   const workspaceCardsMarkup = (): JSX.Element[] => {
      if (workspaces !== undefined) {
         let tabWorkspacesArray: Workspace[] = [];
         if (my) {
            // only include workspaces with the current explorer as a collaborator
            tabWorkspacesArray = workspaces.filter(
               (workspace: Workspace) =>
                  workspace.workspaceCollaborators?.find(
                     (collab) => collab.personId === authPersonQuery.data?.person.id
                  ) !== undefined
            );
         } else {
            tabWorkspacesArray = workspaces;
         }
         if (searchText.length < 3) {
            return tabWorkspacesArray.map((workspace: Workspace) => (
               <AiPulse
                  key={workspace.id}
                  on={stepOne && workspace.name === 'Demo Playground'}
                  onClick={() => {
                     if (stepOne) {
                        setStepOne();
                     }
                  }}
                  sparkleAfter
               >
                  <WorkspaceCard key={workspace.id} workspace={workspace} />
               </AiPulse>
            ));
         } else if (workspaces !== undefined && searchText.length >= 3) {
            return tabWorkspacesArray.filter(filterByText).map((workspace: Workspace) => (
               <AiPulse
                  key={workspace.id}
                  on={stepOne && workspace.name === 'Demo Playground'}
                  onClick={() => {
                     if (stepOne) {
                        setStepOne();
                     }
                  }}
                  sparkleAfter
               >
                  <WorkspaceCard workspace={workspace} />
               </AiPulse>
            ));
         }
      }
      return [];
   };

   return (
      <Stack gap={2}>
         <SearchInput entityName="workspaces" onTextChanged={cleanAndSetSearchText} />
         <Card className="custom-card-transparent border-0 py-0 px-3">
            <Row className="justify-content-center">
               <Col xs={4}>
                  <span className="fs-10p">Workspace</span>
               </Col>
               <Col xs={3}>
                  <span className="fs-10p">Databases</span>
               </Col>
               <Col xs={1}>
                  <span className="fs-10p">Saved Queries</span>
               </Col>
               <Col xs={2}>
                  <span className="fs-10p">Collaborators</span>
               </Col>
               <Col xs={2}>
                  <span className="fs-10p">Created</span>
               </Col>
            </Row>
         </Card>
         {workspaceCardsMarkup()}
      </Stack>
   );
};

export const WorkspaceListPage = () => {
   useTitle('Workspaces');

   const [showAddWorkspaceModal, setShowAddWorkspaceModal] = useState(false);

   const authPersonQuery = useGetAuthorizedExplorerQuery();

   const queryClient = useQueryClient();
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const addWorkspaceMutation = useMutation({
      mutationFn: async (variables: {
         description: string;
         membersCanInvite: boolean;
         name: string;
      }) => {
         try {
            return await workspaceService.post({
               name: variables.name,
               description: variables.description,
               orgId: authPersonQuery?.data?.person?.orgId,
               createdByPerson: authPersonQuery?.data?.person,
               modifiedByPerson: authPersonQuery?.data?.person,
               membersCanInvite: variables.membersCanInvite,
            });
         } catch (err) {
            handleError(err);
         }
      },
      onSuccess: async (newWorkspace: Workspace | undefined) => {
         if (!newWorkspace) return;
         setShowAddWorkspaceModal(false);
         await queryClient.invalidateQueries([QueryKey.Workspace, 'list']);
         window.location.href = `/workspaces/${notUndefined(newWorkspace?.id).toString()}`;
      },
   });

   const handleCloseModal = (name: string, description: string, canInvite: boolean) => {
      addWorkspaceMutation.mutate({
         name: name,
         description: description,
         membersCanInvite: canInvite,
      });
   };

   const handleModalExit = () => {
      setShowAddWorkspaceModal(false);
   };

   const handleAddWorkspace = () => {
      setShowAddWorkspaceModal(true);
   };

   return (
      <Page
         header={
            <Stack className="justify-content-between w-100" direction="horizontal" gap={2}>
               <div>Workspaces</div>
               <Button
                  className="fs-10p"
                  disabled={addWorkspaceMutation.isLoading}
                  onClick={handleAddWorkspace}
                  size="sm"
               >
                  Add Workspace
               </Button>
            </Stack>
         }
         nav={
            <Nav className="flex-column">
               <Nav.Link
                  as={NavLink}
                  className="fs-13p fw-500 text-body hover-highlight nav-link-left-menu py-1 px-3"
                  end
                  role={'tab'}
                  to="/workspaces"
               >
                  Browse Workspaces
               </Nav.Link>
            </Nav>
         }
         tabs={[
            { title: 'My Workspaces', content: <WorkspaceList my /> },
            { title: 'All Workspaces', content: <WorkspaceList /> },
         ]}
      >
         <AddWorkspaceModal
            handleCancel={handleModalExit}
            handleClose={handleCloseModal}
            show={showAddWorkspaceModal}
         />
      </Page>
   );
};

export default WorkspaceListPage;
