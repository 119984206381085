import { Stack } from 'react-bootstrap';

export const PageHeader = ({ children }: { children?: React.ReactNode }) => {
   return (
      <Stack
         as="header"
         className="bg-nav ps-3 pe-2 d-flex flex-row align-items-center w-100 lh-1 min-height-44 fs-14p text-dark border-bottom-line"
         direction="horizontal"
         gap={2}
      >
         {children}
      </Stack>
   );
};

export default PageHeader;
