import { useEffect, useState } from 'react';

export function useTitle(title?: string, enabled = true) {
   useEffect(() => {
      if (enabled) {
         document.title = title ? `${title} | runQL` : 'runQL';
      }
      return () => {
         if (enabled) {
            document.title = 'runQL';
         }
      };
   }, [title, enabled]);
}

export function useSetTitle() {
   const [title, setTitle] = useState<string | undefined>(document.title);
   useTitle(title);
   return setTitle;
}
