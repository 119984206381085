import { useInjection } from 'inversify-react';
import { QueryCommentListOptions, QueryCommentService } from '../../services';
import { TYPES } from '../../types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PaginatedResult, QueryComment } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';

const useService = () => {
   return useInjection<QueryCommentService>(TYPES.queryCommentService);
};

export const useListQueryCommentsQuery = (options?: QueryCommentListOptions) => {
   const service = useService();
   return useQuery<PaginatedResult<QueryComment>>(
      getQueryCommentKey({ type: QueryKeyType.LIST, options }),
      () => service.listOptions(options),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   );
};

export const usePostCommentMutator = () => {
   const queryClient = useQueryClient();
   const service = useService();
   return useMutation({
      mutationFn: (comment: QueryComment) => service.post(comment),
      onSettled: () => {
         queryClient.invalidateQueries(getQueryCommentKey({ type: QueryKeyType.LIST }));
      },
   });
};

export function getQueryCommentKey({
   id,
   options,
   type,
}: {
   id?: number;
   options?: QueryCommentListOptions;
   type?: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.QueryComments];
   if (type) queryKey.push(type);
   if (id) queryKey.push(id);
   if (options) queryKey.push(options);

   return queryKey;
}
