import { useEffect } from 'react';
import { signInWithMS, signInWithGoogle } from '../utilities/socialLogin';

export const AuthRedirect = ({ provider }: { provider: 'google' | 'ms' }) => {
   useEffect(() => {
      sessionStorage.setItem('closeOnAuth', 'true');
      if (provider === 'google') {
         signInWithGoogle();
      } else if (provider === 'ms') {
         signInWithMS();
      }
   }, [provider]);
   return null;
};
