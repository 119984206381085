import { useEffect, useMemo } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';
import { findParams } from '../entities';
import { useCurrentQuery } from '../hooks';
import { QueryParameterCard } from './QueryParameterCard';

// Uses <QueryProvider> for context rather than params.
const QueryParams = (): JSX.Element => {
   const { queryVersion, onChange, onParamChange, paramOverrides } = useCurrentQuery();
   const parameters = useMemo(() => queryVersion?.parameters ?? [], [queryVersion?.parameters]);
   const newParameters = useMemo(
      () => findParams(queryVersion?.steps ?? []),
      [queryVersion?.steps]
   );

   useEffect(() => {
      if (
         newParameters.length === parameters.length &&
         newParameters.every((obj, index) => obj.name === parameters[index].name)
      ) {
         return;
      }

      // restore parameters by name
      const unrestored: number[] = [];
      for (let i = 0; i < parameters.length; i++) {
         const newParam = newParameters.find((p) => p.name === parameters[i].name);
         if (newParam) {
            newParam.defaultValue = parameters[i].defaultValue;
            newParam.exclude = parameters[i].exclude;
         } else {
            unrestored.push(i);
         }
      }

      // restore old parameters by index
      for (const oldIndex of unrestored) {
         if (newParameters[oldIndex] !== undefined && newParameters[oldIndex].defaultValue === '') {
            newParameters[oldIndex].defaultValue = parameters[oldIndex].defaultValue;
            newParameters[oldIndex].exclude = parameters[oldIndex].exclude;
         }
      }

      onChange?.({ parameters: newParameters }, { debounce: true });
   }, [parameters, newParameters, onChange]);

   const updateParam = (name: string, changes: { exclude?: boolean; value?: string }) => {
      const newParameterList = Array.from(parameters);
      const paramIndex = newParameterList.findIndex((param) => param.name === name);
      if (paramIndex >= 0) {
         newParameterList[paramIndex] = {
            ...newParameterList[paramIndex],
            name: name,
            ...(changes.value !== undefined ? { defaultValue: changes.value } : {}),
            ...(changes.exclude !== undefined ? { exclude: changes.exclude } : {}),
         };
         onChange?.({ parameters: newParameterList }, { debounce: true });
         if (changes.value !== undefined) {
            onParamChange(name, changes.value);
         }
      }
   };

   return (
      <Stack gap={3}>
         {parameters.length > 0 && (
            <div>
               <Row>
                  <Col xs={2}>
                     <Form.Label className="text-muted fs-10p fw-normal">Parameter</Form.Label>
                  </Col>
                  <Col xs={2}>
                     <Form.Label className="text-muted fs-10p fw-normal">Value</Form.Label>
                  </Col>
                  {onChange && (
                     <Col xs={1}>
                        <Form.Label className="text-muted fs-10p fw-normal">Ignore</Form.Label>
                     </Col>
                  )}
               </Row>
               {parameters.map((param) => (
                  <QueryParameterCard
                     hideExclude={!onChange}
                     key={param.name}
                     onDefaultValueChange={(name, value) => updateParam(name, { value })}
                     onExcludeChanged={(name, exclude) => updateParam(name, { exclude })}
                     queryParam={{
                        ...param,
                        ...(paramOverrides[param.name] === undefined
                           ? {}
                           : { defaultValue: paramOverrides[param.name] }),
                     }}
                  />
               ))}
               <hr />
            </div>
         )}
         <div className="fs-12p">
            <div className="fw-bold">
               Parameters
               <br />
            </div>
            You can add variables/parameters in a query which will show value fields above in this
            tab.
            <br />
            eg. <code className="text-primary">{'{{countryName}}'}</code>
         </div>
         <div className="fs-12p">
            <div className="fw-bold">
               Workflows
               <br />
            </div>
            You can access the data results of a previous step/query via:
            <br />
            <code className="text-primary">{'{{COLUMN_NAME}}'}</code> which will access a single row
            of results. Or
            <br />
            <code className="text-primary">{'[[COLUMN_NAME]]'}</code> which will access multi row
            results as an array rendered as (result1, result2, ...)
         </div>
      </Stack>
   );
};

export default QueryParams;
