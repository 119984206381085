import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

interface Props {
   handleCancel(): void;
   handleClose(name: string, description: string, canInvite: boolean): void;
   show: boolean;
   workspaceId?: number;
}

const AddWorkspaceModal = (props: Props): JSX.Element => {
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [canInvite, setCanInvite] = useState(false);
   const [validated, setValidated] = useState(false);
   const [nameTouched, setNameTouched] = useState(false);

   const handleSubmit = (event: {
      currentTarget: any;
      preventDefault: () => void;
      stopPropagation: () => void;
   }) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
         event.preventDefault();
         event.stopPropagation();
      }
      setValidated(true);
      setNameTouched(false);
   };

   return (
      <Modal
         centered
         onHide={() => {
            props.handleCancel();
            setName('');
            setDescription('');
         }}
         show={props.show}
      >
         <Modal.Header className="border-0" closeButton>
            <Modal.Title as="span" className="fs-4 workspaceName">
               Add Workspace
            </Modal.Title>
         </Modal.Header>
         <Modal.Body as="span" className="fs-s workspaceDescription">
            <Form noValidate onSubmit={handleSubmit} validated={validated}>
               <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label>Workspace Name</Form.Label>
                  <Form.Control
                     isInvalid={nameTouched && name.length < 3}
                     onBlur={() => setNameTouched(true)}
                     onChange={(event) => setName(event.currentTarget.value)}
                     placeholder="Enter name"
                     required
                     type="text"
                     value={name}
                  />
                  <Form.Control.Feedback type="invalid">
                     Workspace name is required and must be at least 3 characters.
                  </Form.Control.Feedback>
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                     onChange={(event) => setDescription(event.currentTarget.value)}
                     placeholder="Enter workspace description"
                     type="text"
                     value={description}
                  />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Workspace members can invite others</Form.Label>
                  <Form.Check
                     checked={canInvite}
                     onChange={(event) => setCanInvite(event.currentTarget.checked)}
                     type="switch"
                  />
               </Form.Group>
            </Form>
         </Modal.Body>
         <Modal.Footer className="border-0">
            <Button
               className="btn btn-sm btn-secondary"
               onClick={() => {
                  props.handleCancel();
                  setName('');
                  setDescription('');
                  setNameTouched(false);
               }}
               variant="secondary"
            >
               Cancel
            </Button>
            <Button
               className="btn btn-sm btn-primary"
               disabled={name.length < 3}
               onClick={() => {
                  props.handleClose(name, description, canInvite);
                  setNameTouched(false);
               }}
               type="submit"
               variant="primary"
            >
               Create
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default AddWorkspaceModal;
