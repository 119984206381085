import { Col, Form } from 'react-bootstrap';
import LoadingError from '../../../components/UI/LoadingError';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { DBMS, PersonRole } from '../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../hooks';
import { assertNever, getErrorMessage } from '../../../utilities';
import BigQueryCard from './BigQueryCard';
import DatabricksCard from './DatabricksCard';
import MongoDBCard from './MongoDBCard';
import MsSQLCard from './MsSQLCard';
import MySqlCard from './MySqlCard';
import Neo4jCard from './Neo4jCard';
import OracleCard from './OracleCard';
import PostgreCard from './PostgreCard';
import RedshiftCard from './RedshiftCard';
import SnowflakeCard from './SnowflakeCard';
import TrinoCard from './TrinoCard';
import { useMemo } from 'react';
import { ConnectionAccessType } from '../../../entities';

export function ConnectionCardField({
   label,
   value,
   xs,
   bold,
}: {
   bold?: boolean;
   label: string;
   value?: string | boolean;
   xs: number;
}) {
   return (
      <Col xs={xs}>
         <div className="fs-12p">
            <div className="fs-10p fw-normal text-muted div-form-label">{label}</div>
            {value === undefined || typeof value === 'string' ? (
               <div className={`fs-10p ${bold ? 'fw-600' : ''} div-form-control`}>
                  {value ?? ''}
               </div>
            ) : (
               <Form.Check
                  checked={value}
                  className="div-form-control-switch"
                  disabled
                  type="switch"
               />
            )}
         </div>
      </Col>
   );
}

export function DataConnectionCard({ dataConnectionId }: { dataConnectionId: number }) {
   // Queries
   const dataConnectionQuery = useGetDataConnectionQuery({
      id: dataConnectionId,
      getOptions: { includeCredentials: true },
   });
   const explorerQuery = useGetAuthorizedExplorerQuery();
   const disabled = useMemo(() => {
      if (explorerQuery.data === undefined) return true;
      if (dataConnectionQuery.data === undefined) return true;
      // demos are always disabled
      if (dataConnectionQuery.data.connectionAccessType === ConnectionAccessType.DEMO) return true;
      // connections with hidden details are disabled for everyone except org admins and the creator
      if (
         dataConnectionQuery.data.hideDetails &&
         explorerQuery.data.person.role !== PersonRole.ORG_ADMIN &&
         dataConnectionQuery.data.createdByPersonId !== explorerQuery.data.person.id
      )
         return true;
      return false;
   }, [dataConnectionQuery.data, explorerQuery.data]);

   // Render
   if (dataConnectionQuery.isLoading || explorerQuery.isLoading) return <LoadingSpinner />;

   if (dataConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(dataConnectionQuery.error)} />;

   if (explorerQuery.isError)
      return <LoadingError message={getErrorMessage(explorerQuery.error)} />;

   if (dataConnectionQuery.data === undefined || explorerQuery.data === undefined) return <></>;

   switch (dataConnectionQuery.data?.dbms) {
      case DBMS.MySQL:
         return <MySqlCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Big_Query:
         return <BigQueryCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Databricks:
         return <DatabricksCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.MSSQL:
         return <MsSQLCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Neo4j:
         return <Neo4jCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Postgres:
         return <PostgreCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Redshift:
         return <RedshiftCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Snowflake:
         return <SnowflakeCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Oracle:
         return <OracleCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Trino:
         return <TrinoCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.MongoDB:
         return <MongoDBCard dataConnection={dataConnectionQuery.data} disabled={disabled} />;
      case DBMS.Hugging_Face:
      case DBMS.CHAT_GPT:
      case DBMS.Python:
      case DBMS.Federated:
      case undefined:
         return <></>;
      default:
         assertNever(dataConnectionQuery.data.dbms);
   }
}
