import { useMemo, useState } from 'react';
import { Button, Modal, Nav, Spinner, Stack, Tab } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SourceNav, Page } from '../../components';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { DataConnection, DataCredential, getAllowableEditType } from '../../entities';
import {
   useUpdateDataConnectionMutator,
   useReTestDataConnectionMutator,
   useDeleteDataConnectionMutator,
   useGetDataConnectionQuery,
   useGetAuthorizedExplorerQuery,
   useNewDataCredentialMutator,
} from '../../hooks';
import ConnectionDetailsForm, {
   connectionDetailsFormId,
} from './AddConnectionWizard/ConnectionDetailForm/ConnectionDetailsForm';
import SchemaSelectForm, { schemaSelectFormId } from './AddConnectionWizard/SchemaSelectForm';
import ErrorBoundary from '../../components/ErrorBoundary';
import { getErrorMessage } from '@runql/util';

enum tabs {
   CONNECTION_DETAILS = 'connection-details',
   VISIBLE_SCHEMAS = 'visible-schemas',
}

function DataConnectionDetailsPage() {
   const navigate = useNavigate();
   const params = useParams();
   const dataConnectionId = Number(params.dataConnectionId);
   // Page State
   const [connectionDetailsState, setConnectionDetailsState] = useState<'clean' | 'dirty'>('clean');
   const [formMode, setFormMode] = useState<'save' | 'test'>('save');
   const [showDeleteModal, setShowDeleteModal] = useState(false);

   const [schemaSelectState, setSchemaSelectState] = useState<'clean' | 'dirty' | 'saving'>(
      'clean'
   );
   const [serverResult, setServerResult] = useState<
      { message?: string; success: Boolean } | undefined
   >(undefined);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: dataConnectionId });
   const explorerQuery = useGetAuthorizedExplorerQuery();

   // Mutators
   const deleteDataConnectionMutation = useDeleteDataConnectionMutator();
   const editFormMutator = useUpdateDataConnectionMutator({
      onSuccessCallback(data, variables, context) {
         navigate('/sources');
      },
   });
   const testConnectionMutation = useReTestDataConnectionMutator({
      callbacks: {
         onSuccess(data, variables, context) {
            setServerResult(data);
         },
      },
   });
   const newCredentialMutation = useNewDataCredentialMutator({
      onSuccess(data, variables, context) {
         navigate('/sources');
      },
   });

   const allowedEdit = useMemo(() => {
      return getAllowableEditType(selectedConnectionQuery.data, explorerQuery.data?.person);
   }, [selectedConnectionQuery.data, explorerQuery.data?.person]);
   const readOnly = allowedEdit === 'read-only';

   // Functions
   const updateSavedState = (state: 'clean' | 'dirty') => {
      if (state !== connectionDetailsState) {
         setConnectionDetailsState(state);
      }
   };

   const testConnection = async (newConnection: DataConnection) => {
      try {
         await testConnectionMutation.mutateAsync(newConnection);
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   // Handlers
   const handleSubmit = async (newConnection?: DataConnection, newCredential?: DataCredential) => {
      if (!newConnection) return;
      const editType = getAllowableEditType(newConnection, explorerQuery.data?.person);
      if (formMode === 'save') {
         if (editType === 'connection') await editFormMutator.mutateAsync(newConnection);
         if (editType === 'credential' && newCredential)
            await newCredentialMutation.mutateAsync({ newCredential: newCredential });
      }
      if (formMode === 'test') await testConnection(newConnection);
   };

   const onDeleteClick = async () => {
      await deleteDataConnectionMutation.mutateAsync(dataConnectionId);
      navigate('/sources');
   };

   const showDelete = allowedEdit === 'connection';
   return (
      <ErrorBoundary title={'An error occurred when loading data collections.'}>
         <Page header="Data Sources & Metadata" nav={<SourceNav />}>
            {showDelete && (
               <Modal onHide={() => setShowDeleteModal(false)} show={showDeleteModal}>
                  <Modal.Header closeButton>
                     <Modal.Title className="fs-14p">Remove Data Source</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <span>
                        Are you sure you want to remove{' '}
                        {selectedConnectionQuery.data?.name ?? 'this data source'}?
                     </span>
                     <div className="d-flex justify-content-end mt-2">
                        <Button
                           className={'btn-xs btn-secondary'}
                           onClick={() => setShowDeleteModal(false)}
                        >
                           Cancel
                        </Button>
                        <Button
                           className={'btn-xs ms-2'}
                           onClick={() => onDeleteClick?.()}
                           type="button"
                           variant="danger"
                        >
                           Delete
                        </Button>
                     </div>
                  </Modal.Body>
               </Modal>
            )}

            <div style={{ maxWidth: '800px' }}>
               <Tab.Container defaultActiveKey={tabs.CONNECTION_DETAILS}>
                  <Nav
                     as="ul"
                     className="fs-12p nav-tabs secondaryTabs flex-nowrap text-nowrap"
                     role="tablist"
                  >
                     <Nav.Item as="li">
                        <Nav.Link eventKey={tabs.CONNECTION_DETAILS}>Connection Details</Nav.Link>
                     </Nav.Item>
                     <Nav.Item as="li">
                        <Nav.Link eventKey={tabs.VISIBLE_SCHEMAS}>Visible Schemas</Nav.Link>
                     </Nav.Item>
                  </Nav>
                  <Tab.Content>
                     <Tab.Pane eventKey={tabs.CONNECTION_DETAILS}>
                        <ConnectionDetailsForm
                           onSaveStateChange={updateSavedState}
                           onSubmit={handleSubmit}
                           selectedConnectionId={dataConnectionId}
                        />
                        <Stack
                           className="mt-3 justify-content-between"
                           direction="horizontal"
                           gap={3}
                        >
                           <Stack direction="horizontal" gap={3}>
                              <Button
                                 form={connectionDetailsFormId}
                                 onClick={() => setFormMode('test')}
                                 size="sm"
                                 type="submit"
                                 variant="secondary"
                              >
                                 {testConnectionMutation.isLoading ? (
                                    <Spinner size="sm" />
                                 ) : (
                                    <>Test Connection</>
                                 )}
                              </Button>
                              {(testConnectionMutation.isSuccess ||
                                 testConnectionMutation.isError) &&
                                 serverResult !== undefined &&
                                 (serverResult.success ? (
                                    <span className="text-success fs-10p d-flex justify-content-end">
                                       Connection Successful
                                    </span>
                                 ) : (
                                    <span>
                                       {serverResult?.message !== undefined ? (
                                          <div>
                                             <span className="text-danger fs-10p d-flex justify-content-end">
                                                {serverResult?.message}
                                             </span>
                                          </div>
                                       ) : (
                                          'Unknown error during test'
                                       )}
                                    </span>
                                 ))}
                           </Stack>
                           <Stack direction="horizontal" gap={3}>
                              {!readOnly && (
                                 <Button
                                    className="btn btn-sm "
                                    disabled={editFormMutator.isLoading}
                                    onClick={() => setShowDeleteModal(true)}
                                    variant="secondary"
                                 >
                                    Delete
                                 </Button>
                              )}
                              <Link className="btn btn-sm btn-secondary" to="/sources">
                                 {readOnly ? 'Close' : 'Cancel'}
                              </Link>
                              {!readOnly && (
                                 <Button
                                    className="btn btn-sm btn-primary"
                                    disabled={
                                       connectionDetailsState === 'clean' ||
                                       editFormMutator.isLoading
                                    }
                                    form={connectionDetailsFormId}
                                    onClick={() => setFormMode('save')}
                                    type="submit"
                                 >
                                    {editFormMutator.isLoading && <LoadingSpinner />} Save
                                 </Button>
                              )}
                           </Stack>
                        </Stack>
                     </Tab.Pane>
                     <Tab.Pane eventKey={tabs.VISIBLE_SCHEMAS}>
                        <SchemaSelectForm
                           dataConnectionId={dataConnectionId}
                           onSaveStateChange={setSchemaSelectState}
                        />
                        <Stack className="mt-3 justify-content-end" direction="horizontal" gap={3}>
                           <Link className="btn btn-sm btn-secondary" to="/sources">
                              {schemaSelectState === 'clean' ? 'Done' : 'Cancel'}
                           </Link>
                           <Button
                              className="btn btn-sm btn-primary"
                              disabled={schemaSelectState !== 'dirty'}
                              form={schemaSelectFormId}
                              type="submit"
                           >
                              Save
                           </Button>
                        </Stack>
                     </Tab.Pane>
                  </Tab.Content>
               </Tab.Container>
            </div>
         </Page>
      </ErrorBoundary>
   );
}

export default DataConnectionDetailsPage;
