import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
   firstName: string;
   lastName: string;
}

const CollaboratorIcon = (props: Props): JSX.Element => {
   return (
      <OverlayTrigger
         overlay={<Tooltip id="name">{props.firstName + ' ' + props.lastName}</Tooltip>}
         placement="top"
      >
         <div className="person-img-rounded img-thumbnail bg-secondary">
            {props.firstName.slice(0, 1).toUpperCase() + props.lastName.slice(0, 1).toUpperCase()}
         </div>
      </OverlayTrigger>
   );
};
export default CollaboratorIcon;
