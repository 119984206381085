import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { post } from '../utilities';
import Button from '../components/Button';

export const ChangePasswordPage = (): JSX.Element => {
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [oldPassword, setOldPassword] = useState<string>('');
   const [newPassword, setNewPassword] = useState<string>('');

   const mutation = useMutation(() => {
      return post('auth/setpwd', {
         body: {
            old: oldPassword,
            new: newPassword,
         },
      });
   });

   const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      try {
         await mutation.mutateAsync();
         setErrorMessage('');
         setOldPassword('');
         setNewPassword('');
      } catch (err) {
         setErrorMessage(`${err}`);
      }
   };

   return (
      <section className="position-relative h-100 p-2 p-md-5 d-flex justify-content-center align-items-start">
         <div className="card p-4 border-1 shadow-sm" style={{ maxWidth: '600px' }}>
            <h1 className="">
               <span>Change Password</span>
            </h1>
            <p className="pb-2 mb-2">
               {new URLSearchParams(window.location.search).has('first') && (
                  <span className="text-danger">
                     Your current password is about to expire.
                     <br />
                  </span>
               )}
               <span>
                  It's time to envision a password as strong as the ancient walls that guard
                  long-lost treasures, and with a stroke of the keyboard, you shall wield the power
                  to safeguard the Data Temple.
               </span>
            </p>
            <form onSubmit={onFormSubmit}>
               <div className="position-relative mb-4">
                  <Form.Group>
                     <Form.Label className="form-label fs-base" htmlFor="inputPassword">
                        Current Password
                     </Form.Label>
                     <Form.Control
                        className="form-control-sm"
                        onChange={(event) => setOldPassword(event.target.value)}
                        type="password"
                        value={oldPassword}
                     />
                  </Form.Group>
               </div>
               <div className="mb-4">
                  <Form.Group>
                     <Form.Label className="form-label fs-base" htmlFor="inputPassword">
                        New Password
                     </Form.Label>
                     <Form.Control
                        autoComplete="new-password"
                        className="form-control-sm"
                        onChange={(event) => setNewPassword(event.target.value)}
                        type="password"
                        value={newPassword}
                     />
                  </Form.Group>
                  {mutation.isError && <Alert variant="danger">{errorMessage}</Alert>}
                  {mutation.isSuccess && (
                     <Alert variant="primary">Password changed successfully.</Alert>
                  )}
               </div>
               <Form.Group>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                     <Button
                        colorScheme="primary"
                        disabled={mutation.isLoading}
                        size="sm"
                        type={'submit'}
                     >
                        {mutation.isLoading ? 'Please wait...' : 'Change'}
                     </Button>
                     <Button
                        colorScheme="secondary"
                        disabled={mutation.isLoading}
                        onClick={() => window.history.back()}
                        size="sm"
                     >
                        Cancel
                     </Button>
                  </div>
               </Form.Group>
            </form>
         </div>
      </section>
   );
};

export default ChangePasswordPage;
