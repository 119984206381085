import { FormEvent, useState } from 'react';
import { Alert, Form, Spinner } from 'react-bootstrap';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
   GoogleLoginButton,
   MicrosoftLoginButton,
   AppleLoginButton,
} from 'react-social-login-buttons';

import { handleError, post } from '../utilities';
import { signInWithMS, signInWithGoogle } from '../utilities/socialLogin';
import PublicPage from '../components/PublicPage';
import { useExtension, useAcceptInviteMutator, useCheckInviteLinkQuery } from '../hooks';
import { isDesktop } from '../services/DesktopQueryService';

const VERSION_PLACEHOLDER = 'VERSION';

const getVersion = async (url: string) => {
   const response = await fetch(url);
   if (!response.ok) {
      throw new Error(`Network error: ${response.statusText}`);
   }
   const textData = await response.text();

   // Simple regex to extract the version number
   const versionMatch = textData.match(/version:\s*(.*)/);
   if (versionMatch && versionMatch[1]) {
      return versionMatch[1].trim();
   } else {
      throw new Error('Version not found');
   }
};

const download = async (filename: string, ymlSuffix: string = '') => {
   filename = filename.replace(
      VERSION_PLACEHOLDER,
      await getVersion(`/download/latest${ymlSuffix}.yml`)
   );
   const url = `https://app.runql.com/download/${filename}`;
   const anchor = document.createElement('a');
   anchor.href = url;
   anchor.download = filename;
   document.body.appendChild(anchor);
   anchor.click();
   document.body.removeChild(anchor);
};

export const SignUpPage = (): React.JSX.Element => {
   const params = useParams();
   const navigate = useNavigate();
   const [emailSent, setEmailSent] = useState(false);
   const [formProcessing, setFormProcessing] = useState(false);
   const [searchParams] = useSearchParams();
   const extension = useExtension();
   const [error, setError] = useState<string>();

   const invite = params.link;
   const inviteData = useCheckInviteLinkQuery(invite, {
      onSuccess(data) {
         if (data && !data.ok) {
            setError(data.message ?? 'Unknown Error');
         }
      },
   });
   const acceptInviteMutator = useAcceptInviteMutator();

   const emailSignup = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (!event.currentTarget.checkValidity()) {
         event.stopPropagation();
         return;
      }
      setFormProcessing(true);
      try {
         const data = new FormData(event.currentTarget);
         const body = Object.fromEntries(data.entries());
         if (invite) {
            const firstName = data.get('firstName') as string;
            const lastName = data.get('lastName') as string;
            const password = data.get('password') as string;
            if (!firstName || !lastName || !password) return;
            await acceptInviteMutator.mutateAsync({
               link: invite,
               convertBody: {
                  firstName,
                  lastName,
                  password,
               },
            });
            navigate('/');
         } else {
            await post('/auth/makelink', { body });
            setEmailSent(true);
         }
      } catch (e) {
         handleError(e);
      }
      setFormProcessing(false);
   };

   return emailSent ? (
      <PublicPage>
         <div className="card pt-4 pb-1 ps-4 pe-4">
            <div className="mb-3">
               <h4 className="text-center mb-1">Check Your Inbox</h4>
               <div className="fs-12p text-center mb-1">
                  A confirmation link has been dispatched to your email address. Keep your eyes
                  peeled for the message and click the confirmation link for your adventure to
                  begin!
               </div>
            </div>
         </div>
      </PublicPage>
   ) : error ? (
      <PublicPage>
         <Alert variant="danger">{error}</Alert>
      </PublicPage>
   ) : (
      <PublicPage>
         <div className="row gap-4">
            <div className="col-sm">
               <div className="card pt-4 pb-1 ps-4 pe-4">
                  <div className="mb-3">
                     <h4 className="text-center mb-1 fw-500">
                        {isDesktop() ? (
                           <>Create Account</>
                        ) : (
                           <>
                              Start with the runQL <span className="fw-800">Cloud</span> IDE
                           </>
                        )}
                     </h4>
                     <div className="fs-12p text-center">
                        To get started, please create your account
                     </div>
                  </div>
                  <div className="row mb-3">
                     <div className="col-12">
                        <GoogleLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() => signInWithGoogle(!!extension)}
                        >
                           <div className="text-center fw-normal">Sign in with Google</div>
                        </GoogleLoginButton>
                     </div>
                     <div className="col-12">
                        <MicrosoftLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() => signInWithMS(!!extension)}
                        >
                           <div className="text-center fw-normal">Sign in with Microsoft</div>
                        </MicrosoftLoginButton>
                     </div>
                  </div>
                  <div className="py-3 d-flex align-items-center">
                     <hr className="flex-grow-1" />
                     <div className="badge bg-secondary">OR</div>
                     <hr className="flex-grow-1" />
                  </div>

                  <form onSubmit={emailSignup}>
                     <div className="row">
                        <div className="col-sm-6">
                           <div className="position-relative mb-3">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 First name
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 name="firstName"
                                 required
                                 type="text"
                                 value={inviteData?.data?.firstName}
                              ></Form.Control>
                           </div>
                        </div>
                        <div className="col-sm-6">
                           <div className="position-relative mb-3">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 Last name
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 name="lastName"
                                 required
                                 type="text"
                                 value={inviteData?.data?.lastName}
                              ></Form.Control>
                           </div>
                        </div>
                        {!invite && (
                           <div className="col-12 mb-1">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 Email address
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 name="email"
                                 required
                                 type="email"
                              ></Form.Control>
                           </div>
                        )}
                        {invite && (
                           <div className="col-12 mb-1">
                              <Form.Label className="form-label fs-12p fw-normal">
                                 Password
                              </Form.Label>
                              <Form.Control
                                 className="form-control-sm"
                                 name="password"
                                 required
                                 type="password"
                              ></Form.Control>
                           </div>
                        )}
                     </div>
                     <button
                        className="btn btn-secondary w-100 mt-4"
                        disabled={formProcessing}
                        type="submit"
                     >
                        {formProcessing ? <Spinner /> : 'Sign Up'}
                     </button>
                  </form>
                  <div className="m-3"></div>
                  <div className="row">
                     <div className="col fs-11p">
                        {searchParams.has('error') && (
                           <Alert variant="danger">{searchParams.get('error')}</Alert>
                        )}
                     </div>
                  </div>
               </div>
            </div>
            {!isDesktop() && (
               <div className="col-sm card pt-4 pb-1 ps-4 pe-4">
                  <div className="mb-3">
                     <h4 className="text-center mb-1 fw-500">
                        Start with the runQL <span className="fw-800">Desktop</span> IDE
                     </h4>
                     <div className="fs-12p text-center">
                        To get started, please download for your platform
                     </div>
                  </div>
                  <div className="row mb-3">
                     <div className="col-12">
                        <AppleLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() =>
                              download(`runQL-${VERSION_PLACEHOLDER}-arm64.dmg`, '-mac')
                           }
                        >
                           <div className="text-center fw-normal plausible-event-name--download-mac-apple">
                              Download for Apple Silicon Mac
                           </div>
                        </AppleLoginButton>
                     </div>
                     <div className="col-12">
                        <AppleLoginButton
                           align="center"
                           className="button-social-signin"
                           onClick={() => download(`runQL-${VERSION_PLACEHOLDER}.dmg`, '-mac')}
                        >
                           <div className="text-center fw-normal plausible-event-name--download-mac-intel">
                              Download for Intel Mac
                           </div>
                        </AppleLoginButton>
                     </div>
                     <div className="col-12">
                        <MicrosoftLoginButton
                           align="center"
                           className="button-social-signin plausible-event-name--download-windows"
                           onClick={() => download(`runQL-${VERSION_PLACEHOLDER}.exe`)}
                        >
                           <div className="text-center fw-normal">Download for Windows</div>
                        </MicrosoftLoginButton>
                     </div>
                  </div>
               </div>
            )}
         </div>
         <div className="row gap-4">
            <div className="col-sm">
               {!invite && (
                  <div className="fs-12p mt-3 text-center">
                     <div className="text-decoration-none">
                        Already have an account?&nbsp;&nbsp;
                        <Link className="text-decoration-none" to="/">
                           Sign In
                        </Link>
                     </div>
                  </div>
               )}
               <div className="mt-3 mb-1 text-center">
                  <div className="fs-11p">
                     By signing up you agree to the&nbsp;
                     <a
                        className="text-decoration-none"
                        href="https://runql.com/terms-of-service.html"
                        rel="noreferrer"
                        target="_Blank"
                     >
                        Terms of Service
                     </a>
                     &nbsp;and&nbsp;
                     <a
                        className="text-decoration-none"
                        href="https://runql.com/acceptable-use.html"
                        rel="noreferrer"
                        target="_Blank"
                     >
                        Acceptable Use Policy
                     </a>
                     .
                  </div>
               </div>
            </div>
            {!isDesktop() && <div className="col-sm" />}
         </div>
      </PublicPage>
   );
};

export default SignUpPage;
