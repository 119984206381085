import { HomeNav, Page, StartingBlockHistoryLog } from '../components';
import { useTitle } from '../utilities';

export const LogListPage = () => {
   useTitle('Query History');
   return (
      <Page
         bodyPadding={false}
         header="Home"
         nav={<HomeNav />}
         tabs={[
            {
               title: 'My Logs',
               content: <StartingBlockHistoryLog myQueries />,
            },
            {
               title: 'All Logs',
               content: <StartingBlockHistoryLog />,
            },
         ]}
      />
   );
};

export default LogListPage;
