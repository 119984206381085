import { useState, useMemo } from 'react';
import { Button, Modal, Collapse, Spinner, Form } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useRunSystemQuery } from '../hooks';
import CodeViewer from '../components/UI/CodeViewer';
import { queryBuilderFactory } from '../utilities/QueryBuilder';
import { QueryKey } from '../enums';
import { handleError } from '../utilities/error';
import { DBMS } from '../enums/dbms';

import type { TreeNode } from '../components/UI/TreeView';

export type TableNode = TreeNode & {
   dataConnectionId: number;
   databaseName: string;
   tableName: string;
   type: 'tableNode';
};

export const isTableNode = (node: TreeNode): node is TableNode => {
   return (
      node.type === 'tableNode' &&
      typeof node.dataConnectionId === 'number' &&
      typeof node.databaseName === 'string' &&
      typeof node.tableName === 'string'
   );
};

export const canDeleteTable = ({ dbms }: { dbms: DBMS }) => {
   switch (dbms) {
      case DBMS.Big_Query:
      case DBMS.MSSQL:
      case DBMS.MySQL:
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
      case DBMS.Oracle:
      case DBMS.Trino:
         return true;
      default:
         return false;
   }
};

const DeleteTableModalBody = ({
   onHide,
   tableNode,
   workspaceId,
}: {
   onHide: () => void;
   tableNode: TableNode;
   workspaceId: number;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const [force, setForce] = useState(false);

   const { run, isRunning } = useRunSystemQuery();
   const queryClient = useQueryClient();

   const qb = useMemo(() => queryBuilderFactory(tableNode.dbms), [tableNode.dbms]);

   const query = useMemo(
      () =>
         qb
            .deleteTable({
               catalog: tableNode.catalogName,
               schema: tableNode.databaseName,
               table: tableNode.tableName,
               force: qb.supportsDisablingForeignKeyChecks && force,
            })
            .join('\n'),
      [qb, tableNode, force]
   );

   const handleChangeForce = (e: React.ChangeEvent<HTMLInputElement>) => {
      setForce(e.target.checked);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
         await run({
            dataConnection: tableNode.dataConnectionId,
            query,
            workspaceId,
            updateSchema: true,
            catalog: tableNode.catalogName,
         });

         await queryClient.invalidateQueries([QueryKey.WorkspaceSchemaTree]);
         toast.success('Table deleted successfully');
         onHide();
      } catch (err) {
         handleError(err);
      }
   };

   return (
      <>
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">Delete Table '{tableNode.tableName}'?</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className="col-12 fs-14p">
                  <p>
                     Are you sure you want to delete the table{' '}
                     <span className="fw-medium">
                        `{tableNode.databaseName}`.`
                        {tableNode.tableName}`
                     </span>
                     ?
                  </p>
                  <p> This operation cannot be undone.</p>
               </div>
            </div>
            <Form id="deleteTableForm" onSubmit={handleSubmit}>
               {qb.supportsDisablingForeignKeyChecks ? (
                  <div className="row">
                     <div className="col-12">
                        <Form.Check
                           checked={force}
                           id="toggleIntegrityChecksSwitch"
                           label="Disable integrity checks?"
                           onChange={handleChangeForce}
                           type="switch"
                        />
                     </div>
                  </div>
               ) : null}
               <div className="row">
                  <div className="col-12 d-flex justify-content-end mt-3">
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                           setShowQuery(!showQuery);
                        }}
                     >
                        {showQuery ? `Hide` : `Show`} Query
                     </Button>
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        disabled={isRunning}
                        onClick={onHide}
                     >
                        Cancel
                     </Button>
                     <Button className="btn btn-sm btn-primary" disabled={isRunning} type="submit">
                        {isRunning ? (
                           <>
                              <Spinner
                                 animation="border"
                                 aria-hidden="true"
                                 as="span"
                                 role="status"
                                 size="sm"
                              />
                              <span className="visually-hidden">Loading...</span>&nbsp;
                           </>
                        ) : null}
                        Confirm
                     </Button>
                  </div>
               </div>
            </Form>
            <div className="row">
               <div className="col-12">
                  <Collapse in={showQuery}>
                     <div className="card query-card border-1 mt-4 p-4 fs-14p">
                        <CodeViewer query={query} />
                     </div>
                  </Collapse>
               </div>
            </div>
         </Modal.Body>
      </>
   );
};

export const DeleteTableModal = ({
   onHide,
   tableNode,
   workspaceId,
}: {
   onHide: () => void;
   tableNode: TableNode | null;
   workspaceId: number;
}) => {
   return (
      <Modal onHide={onHide} show={tableNode !== null}>
         {tableNode !== null ? (
            <DeleteTableModalBody onHide={onHide} tableNode={tableNode} workspaceId={workspaceId} />
         ) : null}
      </Modal>
   );
};

export default DeleteTableModal;
