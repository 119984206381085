import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { Snippet } from '../entities';

const PATH = '/v1/snippet';

@injectable()
export class SnippetService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   async list(dataConnectionId?: number): Promise<Snippet[]> {
      try {
         return (
            (await this.apiService.get(
               `${PATH}/`,
               dataConnectionId
                  ? {
                       dataConnectionId: dataConnectionId.toString(),
                    }
                  : undefined
            )) ?? []
         );
      } catch (error) {
         console.error(error);
         return [];
      }
   }

   async create(dataConnectionId: number, snippet: string): Promise<Snippet[]> {
      try {
         return (
            (await this.apiService.post(`${PATH}/`, {
               dataConnectionId,
               snippet,
            })) ?? []
         );
      } catch (error) {
         console.error(error);
         return [];
      }
   }

   async use({ id }: { id: number }) {
      try {
         await this.apiService.put(`${PATH}/${id}`, {});
      } catch (error) {
         console.error(error);
      }
   }
}
