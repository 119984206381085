import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DBMS } from '../../../../enums';
import { useGetDataConnectionQuery } from '../../../../hooks';
import { connectionName, description, password, username } from '../validators';
import { ConnectionAccessType } from '../../../../entities';
import { AccessTypeField } from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   accountName: username,
   password: password,
});

const snowflakeSchema = z
   .object({
      dbms: z.literal(DBMS.Snowflake),
      connectionAccessType: z.nativeEnum(ConnectionAccessType),
      connectionName: connectionName,
      description: description,
      snowflakeAccount: z.string().min(1, 'Required'),
      warehouse: z.string(),
      database: z.string().min(1, 'Required'),
   })
   .merge(credentialsSchema);

type SnowflakeDetailFormData = z.infer<typeof snowflakeSchema>;

function SnowflakeDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const { register, handleSubmit, formState, reset, setValue, setError } =
      useForm<SnowflakeDetailFormData>({
         resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : snowflakeSchema),
      });
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const formData: SnowflakeDetailFormData = {
            dbms: DBMS.Snowflake,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            snowflakeAccount: selectedConnectionQuery.data.snowflakeAccount ?? '',
            warehouse: selectedConnectionQuery.data.snowflakeWarehouse ?? '',
            database: selectedConnectionQuery.data.dbName ?? '',
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: SnowflakeDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof SnowflakeDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof SnowflakeDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof SnowflakeDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof SnowflakeDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   return (
      <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
         <Stack gap={3}>
            {!onlyCreds && (
               <>
                  <input type="hidden" {...register('dbms')} value={DBMS.Snowflake} />
                  <Form.Group>
                     <Form.Label>
                        Connection Name <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('connectionName')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.connectionName && !!errors.connectionName}
                        isValid={touchedFields.connectionName && !errors.connectionName}
                        placeholder="Connection Name"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.connectionName?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>Description</Form.Label>
                     <Form.Control
                        {...register('description')}
                        as="textarea"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.description && !!errors.description}
                        isValid={touchedFields.description && !errors.description}
                        placeholder="Description"
                        rows={3}
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.description?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Snowflake Account <span className="text-danger">*</span>{' '}
                        <a
                           className="fs-11p"
                           href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-1-preferred-account-name-in-your-organization"
                           rel="noreferrer"
                           target={'_blank'}
                        >
                           (find your account identifier)
                        </a>
                     </Form.Label>
                     <Form.Control
                        {...register('snowflakeAccount')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.snowflakeAccount && !!errors.snowflakeAccount}
                        isValid={touchedFields.snowflakeAccount && !errors.snowflakeAccount}
                        placeholder="<org_name>.<account_name>"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.snowflakeAccount?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Warehouse <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('warehouse')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.warehouse && !!errors.warehouse}
                        isValid={touchedFields.warehouse && !errors.warehouse}
                        placeholder="Warehouse"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.warehouse?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>
                        Database <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('database')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.database && !!errors.database}
                        isValid={touchedFields.database && !errors.database}
                        placeholder="Database"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.database?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <AccessTypeField
                     disabled={editType !== 'connection'}
                     {...register('connectionAccessType', {
                        setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                        onChange: () => {
                           setValue('accountName', '');
                           setValue('password', '');
                        },
                     })}
                  />
               </>
            )}
            <Form.Group>
               <Form.Label>
                  Username <span className="text-danger">*</span>
               </Form.Label>
               <Form.Control
                  {...register('accountName')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.accountName && !!errors.accountName}
                  isValid={touchedFields.accountName && !errors.accountName}
                  placeholder="User Name"
                  required
               />
               <Form.Control.Feedback type="invalid">
                  {errors.accountName?.message}
               </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
               <Form.Label>
                  Password <span className="text-danger">*</span>
               </Form.Label>
               <Form.Control
                  {...register('password')}
                  disabled={editType === 'read-only'}
                  isInvalid={touchedFields.password && !!errors.password}
                  isValid={touchedFields.password && !errors.password}
                  onBlur={handleBlur('', 'CURRENT', 'password')}
                  onFocus={handleFocus('CURRENT', '')}
                  placeholder="Password"
                  required
                  type="password"
               />
               <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
               </Form.Control.Feedback>
            </Form.Group>
         </Stack>
      </Form>
   );
}

export default SnowflakeDetailForm;
