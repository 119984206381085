import { AdminNav, HistoryLog, Page, WorkspaceLog } from '../components';
import { useTitle } from '../utilities';

export const CompliancePage = () => {
   useTitle('Compliance');

   return (
      <Page
         header="Admin"
         nav={<AdminNav />}
         tabs={[
            { title: 'Query History', content: <HistoryLog /> },
            { title: 'Workspace History', content: <WorkspaceLog /> },
         ]}
      />
   );
};
export default CompliancePage;
