import { useInjection } from 'inversify-react';
import { AskRunaRequest, AskRunaResponse, AskService } from '../services';
import { TYPES } from '../types';
import { useMutation } from 'react-query';
import React, { createContext, useState } from 'react';

const useAskService = (): AskService => {
   return useInjection<AskService>(TYPES.askService);
};

type AskRunaContextType = {
   askRuna: ({
      prompt,
      dataConnectionId,
      optimize,
      currentQuery,
   }: {
      currentQuery?: string;
      dataConnectionId?: number;
      optimize?: boolean;
      prompt?: string;
   }) => Promise<AskRunaResponse[] | undefined>;
   conversation: AskRunaResponse[];
   isLoading: boolean;
};

const AskRunaContext = createContext<AskRunaContextType>({
   async askRuna() {
      return undefined;
   },
   isLoading: false,
   conversation: [],
});

export function AskRunaProvider({ children }: { children: React.ReactNode }) {
   const askService = useAskService();
   const askRunaRequest = useMutation({
      mutationFn: async (req: AskRunaRequest) => {
         return askService.askRuna(req);
      },
   });
   const [conversation, setConversation] = useState<AskRunaResponse[]>([]);

   const askRuna = async ({
      prompt,
      dataConnectionId,
      optimize,
      currentQuery,
   }: {
      currentQuery?: string;
      dataConnectionId?: number;
      optimize?: boolean;
      prompt?: string;
   }) => {
      const response = await askRunaRequest.mutateAsync({
         prompt,
         previous: conversation,
         dataConnectionId,
         optimize,
         currentQuery,
      });
      if (!response) return;
      setConversation([...conversation, response]);
      return conversation;
   };

   return (
      <AskRunaContext.Provider
         value={{ askRuna, conversation, isLoading: askRunaRequest.isLoading }}
      >
         {children}
      </AskRunaContext.Provider>
   );
}

export function useAskRunaContext() {
   return React.useContext(AskRunaContext);
}
