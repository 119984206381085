import type { QueryReturn } from '@runql/util';
import { DataConnection, WorkspaceDataConnection } from '../entities';

export type { QueryReturn } from '@runql/util';

export * from './syntaxItem';

export interface Tag {
   tag: string;
   type: string;
}

export interface Variables {
   [key: string]: string;
}

export enum QueryType {
   Draft = 'draft',
   Saved = 'saved',
}

export interface HistoricalQuery {
   dateTime?: string;
   query: string;
   systemTags: SystemTag[];
   workspaceId?: number;
}
export interface SystemTag {
   tag: string;
   type: string;
}

export function isQueryReturn(data: any): data is QueryReturn {
   let isQuery: boolean = true;
   if ('row' in data === false) {
      isQuery = false;
   }

   if ('fields' in data === false) {
      isQuery = false;
   }

   return isQuery;
}

export interface SelectOption {
   label: string;
   value: number | string | undefined;
}

export interface SelectOptionDataConnection extends SelectOption {
   dataConnection: DataConnection | undefined;
}

export interface SelectOptionWorkspaceConnection extends SelectOption {
   needsCredentials: boolean;
   workspaceConnection?: WorkspaceDataConnection;
}

export const selectOptionWorkspaceConnectionNone: SelectOptionWorkspaceConnection = {
   label: 'None',
   value: undefined,
   needsCredentials: false,
   workspaceConnection: undefined,
};

export function createSelectOptionWorkspaceConnection(
   connection: WorkspaceDataConnection | undefined
): SelectOptionWorkspaceConnection {
   if (connection === undefined) {
      return selectOptionWorkspaceConnectionNone;
   } else {
      return {
         label: connection.dataConnection?.name ?? 'Unknown',
         needsCredentials:
            connection.dataConnection?.dataCredentials === undefined ||
            (connection.dataConnection.dataCredentials &&
               connection.dataConnection.dataCredentials.length === 0),
         value: connection.dataConnectionId,
         workspaceConnection: connection,
      };
   }
}
