import { Spinner } from 'react-bootstrap';

export const LoadingSpinner = ({ centered }: { centered?: boolean }): JSX.Element => {
   centered = centered ?? true;
   const spinner = (
      <Spinner animation="border" role="status">
         <span className="visually-hidden">Loading...</span>
      </Spinner>
   );
   if (centered) {
      return <div className="text-center">{spinner}</div>;
   }
   return spinner;
};

export default LoadingSpinner;
