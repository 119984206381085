import React from 'react';
import Page from '../components/Page';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaHome } from 'react-icons/fa';

export const Error404 = (): JSX.Element => {
   const navigate = useNavigate();
   return (
      <Page>
         <div className="parallax parallax-custom-1 mx-auto">
            <div className="parallax-layer parallax-layer-custom-1" data-depth="-0.15"></div>
            <div className="parallax-layer parallax-layer-custom-2" data-depth="0.12"></div>
            <div
               className="parallax-layer parallax-layer-custom-3 zindex-5"
               data-depth="-0.12"
            ></div>
         </div>
         <div
            style={{
               width: '100%',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
            }}
         >
            <h2 className="display-5">This page doesn't exist.</h2>
            <span style={{ fontSize: '18px' }}>
               Check the spelling of the URL and try again. If there should be a page here, contact
               us at contact us at <a href="mailto:support@runql.com">support@runql.com</a>.
            </span>
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                  gap: '1rem',
               }}
            >
               <Button colorScheme="primary" onClick={() => window.history.back()} size="md">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                     <IoMdArrowRoundBack size="20px" />
                     <span style={{ fontSize: '18px' }}>Go Back</span>
                  </div>
               </Button>
               <Button colorScheme="primary" onClick={() => navigate('/home')} size="md">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                     <FaHome size="20px" />
                     <span style={{ fontSize: '18px' }}>Go Home</span>
                  </div>
               </Button>
            </div>
         </div>
      </Page>
   );
};

export default Error404;
