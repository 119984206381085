import { Nav, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { NavLink, To } from 'react-router-dom';
import { IconType } from 'react-icons';

export const TopNavRailButton = ({
   eventKey,
   icon,
   title,
   to,
}: {
   count?: number;
   eventKey?: string;
   icon: IconType;
   title: string;
   to?: To;
}): JSX.Element => {
   return (
      <>
         <OverlayTrigger
            delay={{ show: 500, hide: 0 }}
            key={title}
            overlay={<Tooltip>{title}</Tooltip>}
            placement="bottom"
         >
            <Nav.Link
               as={to ? NavLink : undefined}
               className="position-relative"
               eventKey={eventKey}
               role={'tab'}
               // @ts-ignore
               to={to}
            >
               {icon({ size: '14px' })}
            </Nav.Link>
         </OverlayTrigger>
      </>
   );
};

export const TopNavRail = ({ children }: { children: React.ReactNode }): JSX.Element => {
   return (
      <Nav className="w-100">
         <Stack direction="horizontal">{children}</Stack>
      </Nav>
   );
};

export default TopNavRail;
