import { createContext, useContext } from 'react';

export type ExtensionContext = {
   extension?: boolean;
   openTab: (url: string) => void;
};

const ctx = createContext({} as ExtensionContext);

export const ExtensionContextProvider = ctx.Provider;

export const useExtension = (): ExtensionContext | undefined => {
   const ret = useContext(ctx);
   if (!ret.extension) return undefined;
   return ret;
};
