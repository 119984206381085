import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { WEB_VERSION } from '../version';
import { Button } from '../components/Button';

// versionCheck.ts
export async function fetchCurrentVersion(): Promise<string | null> {
   try {
      const response = await fetch('/version.json', { cache: 'no-store' });
      const data = (await response.json()) as { version: string };
      return data.version;
   } catch (error) {
      console.error('Failed to fetch version file:', error);
      return null;
   }
}

const updateToast = ({
   closeToast,
   onUpdate,
}: {
   closeToast: () => void;
   onUpdate: () => void;
}) => (
   <div>
      <b>runQL just got better!</b>
      <br />
      <Button
         onClick={() => {
            onUpdate();
            closeToast();
         }}
      >
         Update Now
      </Button>
   </div>
);

export function useVersionCheck() {
   const [ready, setReady] = useState(false);
   const [upgrade, setUpgrade] = useState<string>();
   useEffect(() => {
      const checkVersion = async () => {
         const latestVersion = await fetchCurrentVersion();
         if (latestVersion && latestVersion !== WEB_VERSION) {
            setUpgrade(latestVersion);
         } else {
            setReady(true);
         }
      };

      checkVersion(); // Check version when the page loads

      const intervalId = setInterval(checkVersion, 300000); // Check every 5 minutes

      return () => clearInterval(intervalId);
   }, []);
   useEffect(() => {
      if (!upgrade) return;
      // We only auto-refresh once for a given version, just in case something
      // is wrong with the update process (e.g. version.txt is out of sync or
      // there's a caching issue).
      const attempt = localStorage.getItem('upgrade-attempt');
      if (ready || attempt === upgrade) {
         // If we were ready and then detected an upgrade, prompt
         toast(updateToast({ closeToast() {}, onUpdate: () => window.location.reload() }), {
            autoClose: false,
         });
         setReady(true);
      } else {
         // If we detect an upgrade on initial load, simply refresh
         localStorage.setItem('upgrade-attempt', upgrade);
         window.location.reload();
      }
   }, [ready, upgrade]);
   return ready;
}
