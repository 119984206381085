import { PropsWithChildren } from 'react';
import { Nav } from 'react-bootstrap';

export const Navbar = (props: PropsWithChildren<{}>): JSX.Element => {
   return (
      <div className="d-flex flex-column justify-content-between h-100 border-right-line-light overflow-hidden pt-1">
         <div className="flex-column flex-grow-1 overflow-hidden navigation-menu-width">
            {props.children}
         </div>
         <Nav className="flex-column flex-grow-0 border-top-line"></Nav>
      </div>
   );
};

export default Navbar;
