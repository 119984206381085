import { PersonRole } from '../enums';
import { Base } from './Base';
import { Workspace } from './Workspace';
import { CollaboratorRole, WorkspaceCollaborator } from './WorkspaceCollaborator';

export interface Person extends Base {
   adminWorkspaces?: Workspace[];
   createdWorkspaces?: Workspace[];
   email?: string;
   firstName?: string;
   /** output only */
   inviteWorkspace?: number;
   /** output only */
   inviteWorkspaceRole?: CollaboratorRole;
   lastName?: string;
   modifiedWorkspaces?: Workspace[];
   myWorkspaceId?: number;
   orgId?: number;
   paidSeat?: boolean;
   /** password is output only */
   password?: string;
   role?: PersonRole;
   workspaceCollaborators?: WorkspaceCollaborator[];
}

export class PersonUtilities {
   static getFullName(person: Partial<Person> | string | undefined): string {
      if (typeof person === 'string') {
         return person;
      }
      if (person?.firstName && person?.lastName) {
         return `${person.firstName} ${person.lastName}`;
      }
      return person?.firstName ?? person?.lastName ?? '';
   }
}
