import { inject, injectable } from 'inversify';
import { QuerySavedCustomTag } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/querySavedCustomTag';

@injectable()
export class QuerySavedCustomTagService implements ApiServiceInterface<QuerySavedCustomTag> {
   private path = '/v1/querySavedCustomTag';
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${this.path}/${id}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<QuerySavedCustomTag | undefined> {
      const result = await this.apiService.get<QuerySavedCustomTag>(`${this.path}/${id}`, params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async list(params: Record<string, string>): Promise<QuerySavedCustomTag[]> {
      const result = await this.apiService.get<QuerySavedCustomTag[]>(path, params);
      if (!result) {
         return [];
      }

      return result;
   }

   listOptions(filter: { workspaceId: number }): Promise<QuerySavedCustomTag[]> {
      const params: Record<string, string> = {};

      if (filter.workspaceId) {
         params['workspaceId'] = filter.workspaceId.toString();
      }

      return this.list(params);
   }

   async patch(
      id: string | number,
      body: QuerySavedCustomTag,
      params?: Record<string, string> | undefined
   ): Promise<QuerySavedCustomTag | undefined> {
      const result = await this.apiService.patch<QuerySavedCustomTag>(
         `${path}/${id}`,
         body,
         params
      );
      if (!result) {
         return undefined;
      }
      return result;
   }

   async post(
      body: QuerySavedCustomTag,
      params?: Record<string, string> | undefined
   ): Promise<QuerySavedCustomTag | undefined> {
      const result = await this.apiService.post<QuerySavedCustomTag>(path, body, params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async put(
      _body: QuerySavedCustomTag,
      _params?: Record<string, string> | undefined
   ): Promise<QuerySavedCustomTag | undefined> {
      throw new Error('Method not implemented.');
   }
}
