import { inject, injectable } from 'inversify';
import { PaginatedResult, SchemaCache } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/schemaCache';

export interface BulkUpdateSchemaCache {
   description?: string;
   exampleData?: string;
   id: number;
}

export interface GetTableMetaResponse {
   columns?: SchemaCache[];
   indexes: SchemaCache[];
   relations?: SchemaCache[];
   tableCache: SchemaCache;
}
@injectable()
export class SchemaCacheService implements ApiServiceInterface<SchemaCache> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async bulkUpdate(dataConnectionId: number, schemaCache: BulkUpdateSchemaCache[]): Promise<null> {
      const body = {
         dataConnectionId,
         schemaCache,
      };
      return await this.apiService.patch<null>(`${path}/bulkUpdate`, body);
   }

   async getTableMeta(tableSchemaId: number): Promise<GetTableMetaResponse | undefined> {
      const result = await this.apiService.get<GetTableMetaResponse | undefined>(
         `${path}/${tableSchemaId}/getMeta`
      );

      if (!result) {
         return undefined;
      }
      result.columns?.forEach((column) => {
         column.columnIsPrimaryKey = (column.columnIsPrimaryKey as any) === 1;
      });
      result.indexes.forEach((index) => {
         index.columnIsPrimaryKey = (index.columnIsPrimaryKey as any) === 1;
         index.indexNonUnique = (index.indexNonUnique as any) === 1;
      });
      return result;
   }

   delete(id: number | string): Promise<null> {
      throw new Error('Method not implemented.');
   }
   get(id: number | string): Promise<any> {
      throw new Error('Method not implemented.');
   }
   list(params: Record<string, string>): Promise<SchemaCache[] | PaginatedResult<SchemaCache>> {
      throw new Error('Method not implemented.');
   }
   listOptions(filter?: Object): Promise<SchemaCache[] | PaginatedResult<SchemaCache>> {
      throw new Error('Method not implemented.');
   }
   patch(id: number | string, body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
   post(body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
   put(body: SchemaCache, params?: Record<string, string>): Promise<any> {
      throw new Error('Method not implemented.');
   }
}
