import { Base } from './Base';
import { DataConnection } from './DataConnection';
import { SchemaItemType } from '@runql/util';

export enum ColumnNull {
   NOT_NULL = 'NOT NULL',
   NULL = 'NULL',
}

export enum RelationAction {
   CASCADE = 'CASCADE',
   NO_ACTION = 'NO ACTION',
   RESTRICT = 'RESTRICT',
   SET_DEFAULT = 'SET DEFAULT',
   SET_NULL = 'SET NULL',
   UNKNOWN = 'UNKNOWN',
}

export interface SchemaCache extends Base {
   catalogName?: string;
   columnCharSet?: string;
   columnCollation?: string;
   columnComment?: string;
   columnDefault?: string;
   columnDefaultConstraint?: string;
   columnExtra?: string;
   columnIsPrimaryKey?: boolean;
   columnKey?: string;
   columnName: string;
   columnNullable?: ColumnNull;
   columnType: string;
   dataConnection: DataConnection;
   dataConnectionId: number;
   indexName: string;
   indexNonUnique: boolean;
   indexType: string;
   relationColumnNames?: string[];
   relationDeleteRule?: string;
   relationName?: string;
   relationReferencedColumnNames?: string[];
   relationReferencedTableName: string;
   relationSchema?: string;
   relationUpdateRule?: string;
   schemaDefaultCharSet?: string;
   schemaDefaultCollation?: string;
   schemaName: string;
   tableCharSet?: string;
   tableCollation?: string;
   tableEngine?: string;
   tableName: string;
   type: SchemaItemType;
   visible?: boolean;
}
