import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { useOpenQuery } from '../entities';
import { useLatestQuery, useQueryToken } from '../hooks';

export const SourceTokenRedirect = (): JSX.Element => {
   const { token } = useParams<{ token: string }>();
   const { data: tokenData, error } = useQueryToken({ token });
   const queryVersion = useLatestQuery(tokenData?.queryId);
   const openQuery = useOpenQuery();
   const redirecting = useRef(false);

   useEffect(() => {
      if (queryVersion && !redirecting.current) {
         redirecting.current = true;
         openQuery({
            queryVersion,
            source: 'runsource',
         });
      }
   }, [queryVersion, openQuery]);

   return (
      <div className="p-4 container-fluid">
         {error && error}
         {!error && <LoadingSpinner />}
      </div>
   );
};
