import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
   workspaceName: string;
}

const WorkspaceIcon = (props: Props): JSX.Element => {
   return (
      <OverlayTrigger overlay={<Tooltip id="name">{props.workspaceName}</Tooltip>} placement="top">
         <div className="img-square-small workspace-img-square img-thumbnail bg-secondary">
            {props.workspaceName.slice(0, 1).toUpperCase()}
         </div>
      </OverlayTrigger>
   );
};
export default WorkspaceIcon;
