import { inject, injectable } from 'inversify';
import { ApiServiceInterface } from './ApiServiceInterface';
import { PaginatedResult, QueryComment } from '../entities';
import { ApiService } from '.';
import { TYPES } from '../types';

const path = '/v1/queryComment';

export interface QueryCommentListOptions {
   preview?: number;
   queryVersionId?: number;
   scope?: 'version' | 'query';
}

@injectable()
export class QueryCommentService implements ApiServiceInterface<QueryComment> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   delete(id: number | string): Promise<null> {
      throw new Error('Method not implemented.');
   }

   get(id: number | string): Promise<QueryComment | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(
      params: Record<string, string>,
      options?: QueryCommentListOptions
   ): Promise<PaginatedResult<QueryComment>> {
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      const result = await this.apiService.get<PaginatedResult<QueryComment>>(`${path}`, params);

      if (!result) {
         return { items: [], totalItems: 0 };
      }
      return result;
   }

   listOptions(filter?: QueryCommentListOptions): Promise<PaginatedResult<QueryComment>> {
      return this.list({}, filter);
   }

   patch(
      id: number | string,
      body: QueryComment,
      params?: Record<string, string>
   ): Promise<QueryComment | undefined> {
      throw new Error('Method not implemented.');
   }

   async post(
      body: QueryComment,
      params?: Record<string, string>
   ): Promise<QueryComment | undefined> {
      if (body.queryVersionId === undefined) {
         throw new Error('QueryVersionId is required');
      }
      if (body.text === undefined) {
         throw new Error('Text is required');
      }

      const result = await this.apiService.post<QueryComment>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   put(body: QueryComment, params?: Record<string, string>): Promise<QueryComment | undefined> {
      throw new Error('Method not implemented.');
   }
}
