import { zodResolver } from '@hookform/resolvers/zod';
import { useLayoutEffect, useRef, useState } from 'react';
import { Button, Card, Form, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { QueryComment, QueryVersion } from '../entities';
import { useExplorer, useListQueryCommentsQuery, usePostCommentMutator } from '../hooks';
import { getErrorMessage, getShortDateTimeString } from '../utilities';
import { LoadingSpinner } from './UI';
import CollaboratorIcon from './UI/CollaboratorIcon';
import LoadingError from './UI/LoadingError';

const PREVIEW_COMMENTS = 1;

const CommentBlock = ({ comment }: { comment: QueryComment }) => {
   return (
      <Card className="p-2">
         <Stack gap={1}>
            <div style={{ whiteSpace: 'pre-wrap' }}>{comment.text}</div>
            <Stack direction="horizontal" gap={1}>
               <CollaboratorIcon
                  firstName={
                     comment.createdByPerson?.firstName ?? comment.createdByPerson?.email ?? ''
                  }
                  lastName={comment.createdByPerson?.lastName ?? ''}
               />
               <div className="text-muted">
                  {comment.createdByPerson?.firstName} {comment.createdByPerson?.lastName} -{' '}
                  {getShortDateTimeString(comment.created)}
               </div>
            </Stack>
         </Stack>
      </Card>
   );
};

const addCommentSchema = z.object({
   comment: z.string().min(1, 'Comment is required'),
});

export type AddCommentFormData = z.infer<typeof addCommentSchema>;

export const QueryComments = ({
   canPost,
   queryVersion,
}: {
   canPost?: boolean;
   queryVersion: QueryVersion;
}) => {
   canPost ??= true;
   // register form
   const {
      handleSubmit,
      register,
      formState: { errors, dirtyFields },
      reset,
   } = useForm<AddCommentFormData>({
      resolver: zodResolver(addCommentSchema),
      mode: 'onTouched',
      defaultValues: {
         comment: '',
      },
   });

   // State
   const [showAllComments, setShowAllComments] = useState(false);

   // queries
   const explorer = useExplorer();
   const commentsQuery = useListQueryCommentsQuery({
      queryVersionId: queryVersion.id!,
      preview: !showAllComments ? PREVIEW_COMMENTS : undefined,
      scope: 'version',
   });
   const postCommentMutator = usePostCommentMutator();

   const commentRef = useRef<HTMLTextAreaElement>(null);
   const updateLayout = () => {
      if (!commentRef.current) return;
      commentRef.current.style.height = '0px';
      const scrollHeight = Math.max(commentRef.current.scrollHeight, 26);
      commentRef.current.style.height = scrollHeight + 'px';
   };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   useLayoutEffect(updateLayout, [commentRef.current]);
   const commentProps = register('comment', { onChange: updateLayout });

   if (!explorer) return null;

   const onSubmitHandler = (data: AddCommentFormData) => {
      setShowAllComments(true);
      postCommentMutator.mutate({
         queryVersionId: queryVersion.id!,
         text: data.comment,
      });
      reset();
      updateLayout();
   };
   return (
      <Stack className="p-2 pt-0" gap={2}>
         {commentsQuery.isLoading && <LoadingSpinner />}
         {commentsQuery.isError && <LoadingError message={getErrorMessage(commentsQuery.error)} />}
         {commentsQuery.data &&
            commentsQuery.data.items.map((comment) => (
               <CommentBlock comment={comment} key={comment.id} />
            ))}
         {!showAllComments &&
            commentsQuery.data &&
            commentsQuery.data.totalItems > PREVIEW_COMMENTS && (
               <Button
                  className="nav-link"
                  onClick={() => {
                     setShowAllComments(true);
                  }}
                  variant="Link"
               >
                  {commentsQuery.data && commentsQuery.data.totalItems - 1} more comment
                  {commentsQuery.data.totalItems > 2 && 's'}
               </Button>
            )}

         {canPost && (
            <Form id="add-comment-form" onSubmit={handleSubmit(onSubmitHandler)}>
               <Form.Group className="flex-grow-1" controlId="comment">
                  <Form.Control
                     {...commentProps}
                     as="textarea"
                     disabled={
                        (commentsQuery.isLoading || commentsQuery.isError) && dirtyFields.comment
                     }
                     placeholder="Add a comment..."
                     ref={(e: any) => {
                        commentProps.ref(e);
                        // @ts-ignore
                        commentRef.current = e;
                     }}
                     style={{
                        minHeight: 'unset',
                        resize: 'none',
                     }}
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.comment?.message}
                  </Form.Control.Feedback>
               </Form.Group>
               <div className="mt-1 text-end">
                  <Button
                     disabled={!dirtyFields.comment}
                     size="sm"
                     type="submit"
                     variant="secondary"
                  >
                     Add Comment
                  </Button>
               </div>
            </Form>
         )}
      </Stack>
   );
};
