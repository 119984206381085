import { memo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useListQueryQuery } from '../hooks';
import QueryWidget from './QueryWidget';
import SearchInput from './UI/SearchInput';
import { useDebounce } from '../hooks/debounce';
import Paginator from './UI/Paginator';
import LoadingSpinner from './UI/LoadingSpinner';
import { VersionType } from '../services/QueryService';
import { QuerySuggestion } from '../entities/QuerySuggestion';
import { SearchService } from '../services';
import { useInjection } from 'inversify-react';
import { useEffect } from 'react';
import { TYPES } from '../types';
import { QueryVersion } from '../entities';

const PAGE_SIZE = 10;

export const StartingBlockSavedQueries = memo(
   ({
      myQueries,
      versions,
      workspaceId,
      collapse,
   }: {
      collapse?: boolean;
      myQueries?: boolean;
      versions?: VersionType;
      workspaceId?: number;
   }): JSX.Element => {
      const searchService = useInjection<SearchService>(TYPES.searchService);
      versions ??= 'shared';

      const [skip, setSkip] = useState(0);

      const [search, setSearch] = useState('');
      const searchDebounced = useDebounce(search, 750);
      const [searchLoading, setSearchLoading] = useState(false);
      const [searchResults, setSearchResults] = useState<QuerySuggestion[]>([]);

      useEffect(() => {
         if (searchDebounced.replace(/\s/g, '').length === 0) {
            setSearchLoading(false);
            setSearchResults([]);
            return;
         }
         searchService
            .search(workspaceId, searchDebounced)
            .then((val) => {
               setSearchResults(val);
            })
            .finally(() => {
               setSearchLoading(false);
            });
      }, [searchDebounced, workspaceId, searchService]);

      const onSearchChange = (value: string) => {
         setSearch(value);
         if (value !== searchDebounced && value.replace(/\s/g, '').length > 0) {
            setSearchLoading(true);
         } else {
            setSearchLoading(false);
         }
      };

      const savedQueriesQuery = useListQueryQuery({
         listOptions: {
            expandedPersonData: true,
            includeDataConnectionDetails: true,
            workspaceId: workspaceId,
            query: undefined,
            skip,
            take: PAGE_SIZE,
            version: versions,
         },
      });

      if (savedQueriesQuery.isError) {
         return <>Error</>;
      }

      const loading = savedQueriesQuery.isLoading || savedQueriesQuery.isFetching;
      const results = savedQueriesQuery.data?.items ?? [];

      const handleSearchChange = (searchTerm: string) => {
         onSearchChange(searchTerm);
      };

      const renderQueries = (
         versions: (QueryVersion | QuerySuggestion)[],
         isSearchResults: boolean = false
      ) => (
         <Stack className="dimmed-queries" gap={3}>
            {versions.map((version) => {
               const id = isSearchResults
                  ? (version as QuerySuggestion).query.id
                  : (version as QueryVersion).id;

               const queryVersion = isSearchResults
                  ? (version as QuerySuggestion).query
                  : (version as QueryVersion);

               return (
                  <QueryWidget
                     collapse={collapse}
                     key={`${id}-saved-query`}
                     popoverPlacement={collapse ? 'right' : 'auto-start'}
                     queryVersion={queryVersion}
                     source="saved"
                  />
               );
            })}
            <div className="dimmed d-flex justify-content-center">
               <Paginator
                  onChange={(page) => setSkip(page * PAGE_SIZE)}
                  page={Math.floor(skip / PAGE_SIZE)}
                  pageSize={PAGE_SIZE}
                  totalItems={
                     isSearchResults
                        ? searchResults.length
                        : savedQueriesQuery.data?.totalItems ?? 0
                  }
               />
            </div>
         </Stack>
      );

      return (
         <Stack gap={3}>
            <SearchInput
               entityName={`${myQueries ? 'your ' : workspaceId ? '' : 'all '}${
                  versions === 'latest' ? 'draft queries ' : 'saved queries '
               }${workspaceId ? 'in this workspace' : ''}`}
               onTextChanged={handleSearchChange}
            />
            {(searchLoading || loading) && <LoadingSpinner />}
            {!searchLoading && search && searchResults.length === 0 && (
               <div className="fs-11p">No matches</div>
            )}
            {!searchLoading && !loading && searchResults.length === 0 && results.length === 0 && (
               <div className="fs-11p">
                  <span className="">It looks like you don't have any saved queries yet.</span>
                  <br />
                  Unleash your inner Indiana Jones to uncover the treasure in the Data Temple. Grab
                  your hat, crack that whip, and click on Explore to dive into a thrilling
                  adventure!
               </div>
            )}
            {!loading && !search && results.length !== 0 && renderQueries(results)}
            {!searchLoading &&
               search.length !== 0 &&
               searchResults.length > 0 &&
               renderQueries(searchResults, true)}
         </Stack>
      );
   }
);

export default StartingBlockSavedQueries;
