import { inject, injectable } from 'inversify';
import { QuerySuggestion, ApiQuerySuggestion } from '../entities/QuerySuggestion';
import { TYPES } from '../types';
import { ApiService } from './ApiService';

const PATH = '/v1/search';
// This is the largest a query can be without chrome or express throwing an error
const MAX_QUERY_LENGTH = 10000;

@injectable()
export class SearchService {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async querySuggest(workspaceId: number | undefined, query: string): Promise<QuerySuggestion[]> {
      if (workspaceId === undefined) {
         return [];
      }
      if (query.length > MAX_QUERY_LENGTH) {
         // Truncate the query to prevent errors
         query = query.slice(-MAX_QUERY_LENGTH);
      }

      const result = await this.apiService.get<ApiQuerySuggestion[]>(`${PATH}/querySuggest`, {
         workspaceId: workspaceId.toString(),
         query: query,
      });
      if (!result) {
         return [];
      }

      return result.map((r) => ({
         ...r,
         query: (r.query.approvedVersion ?? r.query.latestVersion)!,
      }));
   }

   private processQuery(r: ApiQuerySuggestion): QuerySuggestion {
      return {
         ...r,
         query: {
            ...(r.query.approvedVersion ?? r.query.latestVersion),
            query: r.query,
            steps: (r.query.approvedVersion ?? r.query.latestVersion)?.steps ?? [],
         },
      };
   }

   async globalSearch(q: string): Promise<QuerySuggestion[]> {
      const result = await this.apiService.get<ApiQuerySuggestion[]>(`${PATH}/globalSearch`, { q });
      return result ? result.map(this.processQuery) : [];
   }

   async search(workspaceId: number | undefined, q: string): Promise<QuerySuggestion[]> {
      if (!workspaceId) {
         return this.globalSearch(q);
      }
      const result = await this.apiService.get<ApiQuerySuggestion[]>(`${PATH}`, {
         workspaceId: workspaceId.toString(),
         q,
      });
      return result ? result.map(this.processQuery) : [];
   }

   cast(item: QuerySuggestion): void {
      if (!item) {
         return;
      }
   }
}
