import { useCallback, useEffect } from 'react';
import PubSub from 'pubsub-js';

export function usePublish<T = any>(topic: string) {
   return useCallback(
      (message: T) => {
         PubSub.publish(topic, JSON.stringify(message));
      },
      [topic]
   );
}

export function useSubscribe<T = any>(topic: string, handler: (message: T) => void, deps: any[]) {
   const wrappedHandler = useCallback(
      (_token: string | undefined, message: any) => {
         if (message) handler(JSON.parse(message));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [handler, ...deps]
   );
   return useEffect(() => {
      PubSub.subscribe(topic, wrappedHandler);
      return () => {
         PubSub.unsubscribe(wrappedHandler);
      };
   }, [topic, wrappedHandler]);
}
