import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

export const AiPulse = ({
   children,
   on,
   onClick,
   sparkleAfter,
}: {
   children: React.ReactNode;
   on?: boolean;
   onClick?: () => void;
   sparkleAfter?: boolean;
}): JSX.Element => {
   const [trigger, setTrigger] = useState<boolean | undefined>(undefined);
   useEffect(() => {
      if (!sparkleAfter) return;
      if (on) {
         setTrigger(false);
      } else {
         // Only set the trigger if we're transitioning from on === true
         setTrigger((currentTrigger) => (currentTrigger === false ? true : undefined));
      }
   }, [on, sparkleAfter]);

   const handleClick = () => {
      if (onClick?.()) {
         onClick();
      }
   };

   return (
      <AiSparkles trigger={!!trigger}>
         {(targetRef) => (
            <div onClick={() => handleClick()} ref={targetRef}>
               <div className={on ? 'rainbowAnimation' : ''}>{children}</div>
            </div>
         )}
      </AiSparkles>
   );
};

export const AiSparkles = ({
   children,
   resetOnChange,
   trigger,
   triggerOnChange,
}: {
   children: (targetRef: React.RefObject<HTMLDivElement>) => React.ReactNode;
   resetOnChange?: any; // if this value changes, ignore a change in triggerOnChange
   trigger?: boolean;
   triggerOnChange?: any;
}): JSX.Element => {
   const targetRef = useRef<HTMLDivElement>(null);
   const triggerRef = useRef(trigger === false);
   const triggerValue = useRef(triggerOnChange);
   const resetValue = useRef(resetOnChange);
   const [enabled, setEnabled] = useState(trigger === undefined);
   useEffect(() => {
      if (resetOnChange && resetValue.current !== resetOnChange) {
         resetValue.current = resetOnChange;
         triggerValue.current = triggerOnChange;
      } else if (trigger && triggerValue.current !== triggerOnChange) {
         triggerValue.current = triggerOnChange;
         setEnabled(false);
         setTimeout(() => {
            setEnabled(true);
         }, 10);
      } else if (triggerRef.current && trigger) {
         setEnabled(true);
      }
   }, [trigger, triggerOnChange, resetOnChange]);

   return (
      <CSSTransition
         appear={true}
         classNames="rainbowAnimation"
         in={enabled}
         nodeRef={targetRef}
         timeout={2_000}
      >
         {children(targetRef)}
      </CSSTransition>
   );
};
export default AiSparkles;
