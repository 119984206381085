import { toast } from 'react-toastify';

export const getErrorMessage = (error: unknown) => {
   if (error instanceof Error) {
      return error.message;
   }
   return String(error);
};

export const handleError = (error: unknown) => {
   console.error(error);
   toast.error(getErrorMessage(error));
};
