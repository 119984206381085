import { useMemo, useRef, useState } from 'react';
import { Collapse, Tab } from 'react-bootstrap';
import { BiChevronDown, BiChevronRight, BiDownload } from 'react-icons/bi';
import { DBMS } from '../enums';
import { useExplorer } from '../hooks';
import { QueryReturn } from '../interfaces';
import {
   convertQueryReturnToSigmaGraph,
   IconGraph,
   IconTable,
   isText,
   SigmaGraph,
} from '../utilities';
import { QueryResultsGraph } from './QueryResultsGraph';
import { ResultTable, ResultTableRef } from './UI';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';

export const QueryResults = ({
   queryReturn,
}: {
   queryReturn: QueryReturn | undefined;
}): JSX.Element => {
   const [showQueryDetails, setShowQueryDetails] = useState(false);
   const [activeTab, setActiveTab] = useState<string | null>('table');

   const resultTableRef = useRef<ResultTableRef>(null);

   const explorer = useExplorer();

   const graphData: SigmaGraph | undefined = useMemo(() => {
      if (queryReturn && queryReturn.dbms === DBMS.Neo4j) {
         const data = convertQueryReturnToSigmaGraph(queryReturn);
         if (data.nodes.length === 0) {
            return undefined;
         }
         setActiveTab('graph');
         return data;
      }
      return undefined;
   }, [queryReturn]);

   if (queryReturn === undefined) {
      return <></>;
   } else if (queryReturn.error !== undefined) {
      let errorMessage: string = '';
      if (isText(queryReturn.error)) {
         errorMessage = queryReturn.error;
      } else {
         errorMessage = queryReturn.error.message;
      }

      return (
         <div className="card border-1 m-2 p-2">
            {errorMessage && (
               <span className="text-danger">
                  {errorMessage}
                  <br />
               </span>
            )}
            The treasure you seek in the Data Temple remains elusive. Fear not! Unleash your inner
            Indiana Jones, grab your hat and rewrite your query with utmost care, for only then
            shall you conquer the forbidden depths and retrieve the data treasure you seek!
         </div>
      );
   } else if (!queryReturn.rows?.length) {
      if (queryReturn.affectedRows > 0) {
         return (
            <div className="card border-1 m-2 p-2">
               Your query affected {queryReturn.affectedRows} rows, and took {queryReturn.runtime}{' '}
               ms
            </div>
         );
      }
      return (
         <div className="card border-1 m-2 p-2">Your query was successful but returned 0 rows</div>
      );
   }
   /* eslint-disable jsx-a11y/anchor-is-valid */
   return (
      <div className="d-flex flex-column h-100 resultset-row">
         <div className="mb-2 mt-1 mx-1">
            <div className="row d-flex align-items-center">
               <div className="col-10 fs-10p">
                  <a
                     className="versionHistory me-3 text-decoration-none"
                     href="#"
                     onClick={(e) => {
                        e.preventDefault();
                        setShowQueryDetails(!showQueryDetails);
                     }}
                  >
                     {showQueryDetails ? <BiChevronDown size={16} /> : <BiChevronRight size={16} />}
                     Result Set Details
                  </a>
               </div>

               <div className="col-2 d-flex justify-content-end">
                  {graphData ? (
                     <>
                        <button
                           className="btn btn-link btn-sm p-0 fs-10p"
                           onClick={() => {
                              setActiveTab('graph');
                           }}
                        >
                           <IconGraph size={10} style={{ maxWidth: 10 }} />
                           &nbsp;Graph
                        </button>
                        &nbsp;
                        <button
                           className="btn btn-link btn-sm p-0 fs-10p ms-1"
                           onClick={() => {
                              setActiveTab('table');
                           }}
                        >
                           <IconTable className="" size={10} style={{ maxWidth: 10 }} />
                           &nbsp;Table
                        </button>
                        &nbsp;
                     </>
                  ) : (
                     <>
                        <button
                           className="btn btn-link btn-sm p-0 fs-10p ms-1"
                           onClick={() => {
                              setActiveTab('table');
                           }}
                        >
                           <IconTable className="" size={10} style={{ maxWidth: 10 }} />
                           &nbsp;Table
                        </button>
                     </>
                  )}
                  {typeof queryReturn.rows === 'string' && <span className="mt-3">&nbsp;</span>}
                  {explorer && (explorer?.csvDownload || explorer?.csvDownload === undefined) ? (
                     <button
                        className="btn btn-link btn-sm p-0 fs-10p ms-1 plausible-event-name--csvDownload"
                        onClick={() => resultTableRef.current?.downloadCSV()}
                     >
                        <BiDownload />
                        &nbsp;CSV
                     </button>
                  ) : (
                     <></>
                  )}
               </div>
            </div>
            <Collapse in={showQueryDetails}>
               <div>
                  <div className="fs-11p mt-2 mx-1 text-muted">{queryReturn.query}</div>
                  {queryReturn.runtime && (
                     <div className="fs-11p mt-2 mx-1 text-muted">{`run in ${queryReturn.runtime.toFixed(
                        2
                     )} ms`}</div>
                  )}
               </div>
            </Collapse>
         </div>
         {typeof queryReturn.rows === 'string' ? (
            <div className="card border-1 mt-2 mb-4 p-4 flex-grow">
               <div className="fs-14p" style={{ whiteSpace: 'pre-wrap' }}>
                  {queryReturn.rows}
               </div>
            </div>
         ) : (
            <Tab.Container activeKey={activeTab ?? 'table'} onSelect={(k) => setActiveTab(k)}>
               <Tab.Content className="flex-grow-1 overflow-hidden">
                  {graphData && (
                     <Tab.Pane className="h-100" eventKey="graph">
                        <QueryResultsGraph graphData={graphData} />
                     </Tab.Pane>
                  )}
                  <Tab.Pane className="h-100" eventKey="table">
                     <ResultTable queryReturn={queryReturn} ref={resultTableRef} />
                  </Tab.Pane>
               </Tab.Content>
            </Tab.Container>
         )}
      </div>
   );
};
