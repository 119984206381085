import { useCallback, useEffect, useRef, useState } from 'react';
import {
   Button,
   Dropdown,
   DropdownButton,
   Modal,
   OverlayTrigger,
   Spinner,
   Stack,
   Tooltip,
} from 'react-bootstrap';
import { useHotkeys } from 'react-hotkeys-hook';
import { HiEllipsisHorizontal } from 'react-icons/hi2';
import { MdOutlinePlaylistAdd, MdOutlinePlaylistRemove, MdPlaylistPlay } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import {
   ImperativePanelHandle,
   Panel,
   PanelGroup,
   PanelResizeHandle,
} from 'react-resizable-panels';
import { format } from 'sql-formatter';

import { useInjection } from 'inversify-react';
import { AiPulse, QueryHeader, QueryPanel, queryPanelTabs, RunButton } from '../../../components';
import ManageWorkspaceConnections from '../../../components/ManageWorkspaceConnections';
import WorkspaceConnectionSelector from '../../../components/WorkspaceConnectionSelector';
import {
   CollaboratorRole,
   ExploreTab,
   nextQueryVersion,
   QueryStep,
   QueryVersion,
   QueryVersionPatch,
   StepType,
   useOpenQuery,
} from '../../../entities';
import { walkthroughStep } from '../../../entities/Walkthrough';
import { QueryKey } from '../../../enums';
import {
   QueryProvider,
   useGetAuthorizedExplorerQuery,
   useRunQuery,
   useSetCurrentStep,
} from '../../../hooks';
import { useAskRunaContext } from '../../../hooks/askHooks';
import {
   useEditExploreTabMutation,
   useExplorerWorkspaceRole,
   useListWorkspaceConnectionsQuery,
   useNewQueryMutator,
   useUpdateQueryMutator,
} from '../../../hooks/entities';
import { useQueryPanelContext } from '../../../hooks/QueryPanelContext';
import { useWalkthroughStep } from '../../../hooks/walkthrough';
import { UpgradeModal } from '../../../pages';
import { PersonService, SeeOther } from '../../../services';
import { TYPES } from '../../../types';
import { handleError, notUndefined } from '../../../utilities';
import { QueryEditor, QueryEditorMethods } from './QueryEditor';

const formatQuery = (unformattedQuery: string) => {
   try {
      let formattedQuery = unformattedQuery;
      // temporary replace parameters so formatter can work
      formattedQuery.replaceAll('{{', '`{{');
      formattedQuery.replaceAll('}}', '}}`');
      formattedQuery = format(unformattedQuery);
      // change parameters back
      formattedQuery.replaceAll('`{{', '{{');
      formattedQuery.replaceAll('}}`', '}}');
      return formattedQuery;
   } catch (err) {
      console.warn('Query could not be automatically formatted');
      return unformattedQuery;
   }
};

// Reference a consistent empty array to avoid unnecessary re-renders
const NO_STEPS: QueryStep[] = [];

const QueryPage = ({
   active,
   exploreTab,
}: {
   active: boolean;
   exploreTab: ExploreTab;
}): JSX.Element => {
   const workspaceId = notUndefined(exploreTab.workspaceId);

   // State Variables
   const [queryVersion, setQueryVersion] = useState<QueryVersion | undefined>(
      exploreTab?.queryVersion
   );

   const steps = queryVersion?.steps ?? NO_STEPS;
   const [focusedStep, setFocusedStep] = useState<QueryStep>();
   const [saving, setSaving] = useState(false);
   const [publishing, setPublishing] = useState(false);
   const [duplicateQuery, setDuplicateQuery] = useState<QueryVersion>();
   const [promptRemoveStep, setPromptRemoveStep] = useState<number>();
   const [showConnections, setShowConnections] = useState(false);

   const [isGeneratingDocumentation, setIsGeneratingDocumentation] = useState(false);
   const [isGeneratingQuery, setIsGeneratingQuery] = useState(false);
   const [defaultTab, setDefaultTab] = useState<keyof typeof queryPanelTabs>(
      !queryVersion?.query?.latestVersion?.version ? 'suggestions' : 'docs'
   );
   const [actionsBarMsg, setActionsBarMsg] = useState<string | null>(null);
   const { expanded, setExpanded, collapsedSize, setCollapsedSize } = useQueryPanelContext();
   const { askRuna } = useAskRunaContext();
   const panelRef = useRef<ImperativePanelHandle>(null);
   const debouncedSave = useRef<{
      patch: QueryVersionPatch;
      timeout: ReturnType<typeof setTimeout>;
   }>();
   const expectNewVersion = useRef<QueryVersion | undefined>(queryVersion);
   const isWorkflow = queryVersion && steps.length > 1;
   const openQuery = useOpenQuery();
   const currentEditorRef = useRef<QueryEditorMethods | undefined>();
   const editorRefs = useRef<QueryEditorMethods[]>([]);
   useEffect(() => {
      editorRefs.current = editorRefs.current.slice(0, steps.length);
   }, [steps.length]);
   const [isFormatting, setIsFormatting] = useState(false);
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const [canGenerateDocumentation, setCanGenerateDocumentation] = useState<boolean>(true);

   const personService = useInjection<PersonService>(TYPES.personService);
   const explorer = useGetAuthorizedExplorerQuery();
   useEffect(() => {
      const personId = explorer?.data?.person?.id;
      if (personId) {
         personService.getPlan(personId).then((res) => {
            setCanGenerateDocumentation(res?.canGenerateDocumentation ?? false);
         });
      }
   }, [personService, explorer?.data?.person?.id]);

   const [stepFour, setStepFour] = useWalkthroughStep(walkthroughStep.FIRST_RUN_BUTTON);
   const [stepSeven, setStepSeven] = useWalkthroughStep(walkthroughStep.PYTHON_RUN_BUTTON);
   const [stepEight, setStepEight] = useWalkthroughStep(walkthroughStep.PYTHON_DOCUMENTATION);
   const [stepNine, setStepNine] = useWalkthroughStep(walkthroughStep.PYTHON_SAVE);
   const setSteps = () => {
      if (stepFour) {
         setStepFour();
      } else if (stepSeven) {
         setStepSeven();
      }
   };
   const runSourceEnabled = !!queryVersion?.query?.token;

   // Data Services
   const queryClient = useQueryClient();
   const {
      modals: runModals,
      run,
      isRunning,
      results,
   } = useRunQuery(queryVersion, {
      exploreTabId: exploreTab.id,
   });
   const editTabMutator = useEditExploreTabMutation();
   // Queries
   const role = useExplorerWorkspaceRole(workspaceId);
   const readOnly = role === CollaboratorRole.READER;
   // Once a Query has been saved, `latestVersion.version` will never be `null`.
   const isInitialUnsavedQueryVersion = !queryVersion?.query?.latestVersion?.version;
   const dirty = !queryVersion?.version || !!debouncedSave.current || saving || publishing;

   const workspaceConnectionList = useListWorkspaceConnectionsQuery({
      workspaceId,
      includeConnectionDetails: true,
   });
   const firstDataConnectionId =
      steps[0]?.dataConnectionId ?? workspaceConnectionList.isLoading
         ? undefined
         : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? null;

   const cancelDebouncedSave = useCallback(() => {
      if (debouncedSave.current) {
         clearTimeout(debouncedSave.current.timeout);
         debouncedSave.current = undefined;
      }
   }, []);

   const formatAndRun = async (onlyStep?: number) => {
      setIsFormatting(true);
      try {
         // Format and apply any pending saves
         const newQueryVersion = readOnly
            ? queryVersion
            : await saveDraft({ steps: formatSteps(steps ?? []) }, { throwOnError: true });

         if (
            readOnly &&
            queryVersion?.parameters &&
            !queryVersion?.parameters.every(
               (p, i) =>
                  p.name === exploreTab.parameters?.[i]?.name &&
                  p.exclude === exploreTab.parameters?.[i]?.exclude &&
                  p.defaultValue === exploreTab.parameters?.[i]?.defaultValue
            )
         ) {
            await editTabMutator.mutateAsync({
               id: exploreTab.id,
               parameters: queryVersion?.parameters ?? [],
            });
         }

         await run({
            queryVersion: newQueryVersion,
            step: onlyStep,
         });
      } catch (err) {
         handleError(err);
      }
      setIsFormatting(false);
   };

   useHotkeys(
      ['Shift+Enter', 'Alt+Enter'],
      () => {
         formatAndRun();
      },
      { enabled: active },
      [formatAndRun]
   );

   const createQueryMutation = useNewQueryMutator({
      async onSuccessCallback(data, _newQuerySave, _context) {
         setDuplicateQuery(undefined);
         if (!data?.id) return;
         expectNewVersion.current = data;
         await openQuery({
            exploreTabId: exploreTab.id,
            queryVersion: data,
            workspaceId,
            source: null, // don't track as query reuse
         });
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', workspaceId]);
         // setSaveType(undefined) is performed in the useEffect to avoid jank
      },
      onErrorCallback(error) {
         setSaving(false);
         setDuplicateQuery(undefined);
         if (error instanceof SeeOther) {
            setDuplicateQuery(error.details.queryVersion);
         } else {
            handleError(error);
         }
      },
   });

   const updateQueryMutation = useUpdateQueryMutator({
      async onSuccessCallback(data, update, _context) {
         setDuplicateQuery(undefined);
         if (!data?.id) return;
         if (update.publish) {
            await openQuery({
               exploreTabId: exploreTab.id,
               queryVersion: data,
               workspaceId,
               source: null, // don't track as query reuse
            });
         }
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', workspaceId]);
         setSaving(false);
      },
      onErrorCallback(error) {
         setSaving(false);
         setDuplicateQuery(undefined);
         if (error instanceof SeeOther) {
            const duplicate = error.details.queryVersion;
            if (duplicate?.id !== queryVersion?.id) {
               setDuplicateQuery(duplicate);
            }
         } else {
            handleError(error);
         }
      },
   });

   const formatSteps = useCallback((steps: QueryStep[]) => {
      const newSteps = steps.map((step) => ({
         ...step,
         queryText:
            step.queryText && step.type !== StepType.PYTHON
               ? formatQuery(step.queryText)
               : step.queryText,
      }));
      return newSteps;
   }, []);

   const save = useCallback(
      async ({
         debounce,
         patch,
      }: {
         debounce?: boolean;
         patch?: QueryVersionPatch;
      } = {}): Promise<QueryVersion | undefined> => {
         if (debounce && (patch?.publish || patch?.approve)) {
            debounce = false;
         }

         const newSteps = patch?.queryVersion.steps;
         if (newSteps) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion,
               steps: newSteps,
            }));
         }
         if (
            patch?.queryVersion?.title ||
            patch?.queryVersion?.description ||
            patch?.queryVersion?.question
         ) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion!,
               title: patch!.queryVersion.title ?? queryVersion?.title,
               description: patch!.queryVersion.description ?? queryVersion?.description,
               question: patch!.queryVersion.question ?? queryVersion?.question,
            }));
         }
         const newParams = patch?.queryVersion?.parameters;
         if (newParams) {
            setQueryVersion((queryVersion) => ({
               ...queryVersion!,
               parameters: newParams,
            }));
         }

         if (debouncedSave.current) {
            if (debouncedSave.current.patch) {
               patch = {
                  ...debouncedSave.current.patch,
                  ...patch,
                  queryVersion: {
                     ...debouncedSave.current.patch.queryVersion,
                     ...patch?.queryVersion,
                  },
               };
            }
            cancelDebouncedSave();
         }
         if (debounce && patch) {
            debouncedSave.current = {
               patch,
               timeout: setTimeout(async () => {
                  try {
                     await save();
                  } catch (e) {
                     // Errors are displayed by the mutation
                  }
               }, 5000),
            };
            return undefined;
         }
         let ret;
         setSaving(true);
         if (patch) {
            if (exploreTab.queryVersion?.version) {
               // We can't mutate a saved version, so we must create a new draft with the changes.
               const {
                  id: _1,
                  version: _2,
                  ...newVersion
               } = {
                  ...exploreTab.queryVersion,
                  ...patch.queryVersion,
                  parentId: exploreTab.queryVersion.id,
               };

               ret = await createQueryMutation.mutateAsync({
                  ...patch,
                  queryVersion: newVersion,
                  workspaceId,
               });
            } else {
               ret = await updateQueryMutation.mutateAsync(patch);
            }
         }

         debouncedSave.current = undefined;
         return ret;
      },
      [
         cancelDebouncedSave,
         createQueryMutation,
         updateQueryMutation,
         workspaceId,
         exploreTab?.queryVersion,
      ]
   );

   const share = useCallback(
      async (queryVersion: QueryVersion, share = true) => {
         return await updateQueryMutation.mutateAsync({
            queryVersion: {
               id: queryVersion.id!,
            },
            share,
         });
      },
      [updateQueryMutation]
   );

   const openAnotherQuery = useCallback(
      async (queryVersion: QueryVersion, newTab?: boolean) => {
         newTab ??= true;
         if (!queryVersion?.queryId) return;
         await save();
         openQuery({
            queryVersion: queryVersion,
            workspaceId,
            newTab,
            source: 'duplicate',
         });
      },
      [save, openQuery, workspaceId]
   );

   const saveDraft = useCallback(
      async (
         {
            steps: newSteps,
            description,
            question,
            title,
            parameters,
         }: Partial<
            Pick<QueryVersion, 'steps' | 'title' | 'description' | 'question' | 'parameters'>
         >,
         {
            debounce,
            throwOnError,
            generateComments,
            generateDocumentation,
         }: {
            debounce?: boolean;
            generateComments?: boolean;
            generateDocumentation?: boolean;
            throwOnError?: boolean;
         } = {}
      ) => {
         if (readOnly) {
            if (parameters) {
               setQueryVersion((queryVersion) => ({
                  ...queryVersion!,
                  parameters,
               }));
            }
            return queryVersion;
         }

         if (!queryVersion?.id) {
            throw new Error('No query version to save');
         }
         const updateVersion: Partial<QueryVersion> & { id: number } = {
            id: queryVersion.id!,
         };

         let changed = false;
         if (newSteps) {
            updateVersion.steps = newSteps;
            changed = true;
         }

         if (title !== undefined && title !== queryVersion.title) {
            updateVersion.title = title;
            updateVersion.generatedTitle = false;
            changed = true;
         }
         if (
            description !== undefined &&
            description !== queryVersion.description &&
            (description !== '' || queryVersion.description !== null)
         ) {
            updateVersion.description = description;
            updateVersion.generatedDescription = false;
            changed = true;
         }
         if (
            question !== undefined &&
            question !== queryVersion.question &&
            (question !== '' || queryVersion.question !== null)
         ) {
            updateVersion.question = question;
            updateVersion.generatedQuestion = false;
            changed = true;
         }
         if (parameters) {
            updateVersion.parameters = parameters;
            changed = true;
         }

         if (!changed && !generateComments && !generateDocumentation && !debouncedSave.current) {
            return queryVersion;
         }

         setDuplicateQuery(undefined);
         try {
            const result = await save({
               debounce,
               patch: { queryVersion: updateVersion, generateComments, generateDocumentation },
            });

            return result;
         } catch (e) {
            if (throwOnError) {
               throw e;
            }
         }
      },
      [readOnly, queryVersion, save]
   );

   const forkQuery = useCallback(
      async (queryVersion: QueryVersion) => {
         await saveDraft({}, { throwOnError: true });
         await openQuery({
            queryVersion: queryVersion,
            workspaceId,
            fork: true,
            newTab: true,
            source: 'fork',
         });
      },
      [openQuery, workspaceId, saveDraft]
   );

   const handleClickRevert = useCallback(async () => {
      cancelDebouncedSave();
      if (exploreTab.queryVersion?.version) {
         // If the current version is a saved version, we reset to the original.
         setQueryVersion(exploreTab.queryVersion);
      } else if (queryVersion?.parent?.version) {
         // If the current version is a draft, go back to the previous, saved
         // version.
         setSaving(true);
         await openQuery({
            queryVersion: queryVersion.parent,
            workspaceId,
            source: null, // don't track as query reuse
         });
         setSaving(false);
      }
   }, [cancelDebouncedSave, exploreTab.queryVersion, queryVersion?.parent, openQuery, workspaceId]);

   const handleClickGenerateInlineComments = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      if (stepEight) {
         setStepEight();
      }

      setActionsBarMsg('Generating inline comments');
      setIsGeneratingQuery(true);

      const updatedQueryVersion = await saveDraft(
         { steps: formatSteps(steps ?? []) },
         { generateComments: true }
      );

      setIsGeneratingQuery(false);
      setActionsBarMsg(null);

      // Manually set the queryVersion since the useEffect we use to detect other changes from the
      // exploreTab.queryVersion is not watching for changes to the steps.
      if (updatedQueryVersion) {
         setQueryVersion(updatedQueryVersion);
      }
   }, [formatSteps, queryVersion?.id, saveDraft, steps, stepEight, setStepEight]);

   const handleClickGenerateDocumentation = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      setActionsBarMsg('Generating documentation');
      setIsGeneratingDocumentation(true);

      await saveDraft({ steps: formatSteps(steps ?? []) }, { generateDocumentation: true });

      setIsGeneratingDocumentation(false);
      setActionsBarMsg(null);
      setIsGeneratingDocumentation(false);
   }, [formatSteps, queryVersion?.id, saveDraft, steps]);

   const handleClickOptimize = useCallback(async () => {
      setDefaultTab('runa');
      setActionsBarMsg('Optimizing query');
      setIsGeneratingQuery(true);

      try {
         await askRuna({
            optimize: true,
            currentQuery: steps[0].queryText,
            dataConnectionId: steps[0].dataConnectionId,
         });
      } catch (err) {
         handleError(err);
      }

      setActionsBarMsg(null);
      setIsGeneratingQuery(false);
   }, [askRuna, steps]);

   const publish = useCallback(async () => {
      if (!queryVersion?.id) {
         return;
      }

      setPublishing(true);

      const generateDocumentation =
         isInitialUnsavedQueryVersion &&
         (!queryVersion.title?.trim() ||
            !queryVersion.description?.trim() ||
            !queryVersion.question?.trim());

      if (generateDocumentation) {
         setActionsBarMsg(
            isInitialUnsavedQueryVersion
               ? 'Generating initial documentation'
               : 'Generating documentation'
         );
         setIsGeneratingDocumentation(true);
      }

      let queryUpdate: QueryVersionPatch = {
         queryVersion: {
            id: queryVersion.id,
            steps: formatSteps(steps ?? []),
         },
         publish: true,
         generateDocumentation,
      };

      try {
         await save({ patch: queryUpdate });
      } catch (e) {
         // handled by the mutation
      }

      setIsGeneratingDocumentation(false);
      setActionsBarMsg(null);
      setPublishing(false);
   }, [formatSteps, queryVersion, save, steps, isInitialUnsavedQueryVersion]);
   useHotkeys(`Alt+p`, () => publish(), { enabled: active }, [publish]);

   const _setCurrentStep = useSetCurrentStep();
   const setCurrentStep = useCallback(
      ({ queryStep, selection }: { queryStep?: QueryStep; selection?: string }) => {
         queryStep ??= focusedStep;
         if (!queryVersion || !queryStep || !currentEditorRef.current) return;
         _setCurrentStep?.({
            insert: currentEditorRef.current?.insertSnippet,
            queryStep,
            queryVersion,
            replaceAll: (steps: QueryStep[]) => {
               saveDraft({
                  steps,
               });
            },
            selection,
         });
      },
      [focusedStep, _setCurrentStep, queryVersion, saveDraft]
   );
   const lastQueryVersion = useRef<QueryVersion | undefined>(queryVersion);
   useEffect(
      () => {
         cancelDebouncedSave();
         // Sync changes from server.
         setQueryVersion(exploreTab.queryVersion);
         setSaving(false);
         if (
            exploreTab.queryVersion &&
            expectNewVersion.current?.id !== exploreTab.queryVersion.id &&
            exploreTab.queryVersion.id !== lastQueryVersion.current?.id &&
            !exploreTab.queryVersion.version
         ) {
            setCurrentStep({ queryStep: exploreTab.queryVersion.steps?.[0] });
         }
         setDuplicateQuery(undefined);
         lastQueryVersion.current = exploreTab.queryVersion;
      },
      // Intentionally only update the queryVersion on specific changes.
      //
      // eslint-disable-next-line
      [
         // the tab is switched to a new version
         exploreTab.queryVersion?.id,
         // the version is published
         exploreTab.queryVersion?.version,
         // the version is approved
         exploreTab.queryVersion?.query?.approvedVersionId,
         // the query is shared
         exploreTab.queryVersion?.query?.state,
         // any update to documentation tab
         exploreTab.queryVersion?.title,
         exploreTab.queryVersion?.description,
         exploreTab.queryVersion?.question,
         // The runsource token is generated
         exploreTab.queryVersion?.query?.token,
      ]
   );

   const hasQuery = !!steps?.[0]?.queryText;

   const moreActions: Array<{
      className?: string;
      label: string;
      onClick: React.MouseEventHandler<HTMLButtonElement>;
   }> = [
      // Generate Inline Comments
      ...(!readOnly && canGenerateDocumentation
         ? [
              {
                 className: stepEight ? 'rainbowAnimation' : '',
                 label: 'Generate Inline Comments',
                 onClick: handleClickGenerateInlineComments,
              },
           ]
         : []),
      // Duplicate
      ...(!readOnly && !isInitialUnsavedQueryVersion
         ? [{ label: 'Duplicate', onClick: () => forkQuery(queryVersion) }]
         : []),
      // Revert
      ...(!readOnly ? [{ label: 'Revert', onClick: handleClickRevert }] : []),
      // Generate Optimization
      ...(!readOnly ? [{ label: 'Ask Runa to Optimize', onClick: handleClickOptimize }] : []),
   ];

   const actionButtons = [
      // Save
      ...(!readOnly
         ? [
              <AiPulse
                 key="save"
                 on={stepNine}
                 onClick={() => {
                    if (stepNine) {
                       setStepNine();
                    }
                 }}
              >
                 <Button
                    disabled={!dirty || publishing || !hasQuery}
                    onClick={publish}
                    size="sm"
                    style={{ minWidth: '70px' }}
                    variant="secondary"
                 >
                    {publishing ? (
                       <>
                          &nbsp;
                          <Spinner
                             animation="border"
                             aria-hidden="true"
                             as="span"
                             role="status"
                             size="sm"
                          />
                          &nbsp;
                       </>
                    ) : (
                       `Save ${nextQueryVersion(queryVersion)}`
                    )}
                 </Button>
              </AiPulse>,
           ]
         : []),
      // More...
      ...(moreActions.length > 0
         ? [
              <AiPulse key="more" on={stepEight} sparkleAfter>
                 <DropdownButton size="sm" title={<HiEllipsisHorizontal />} variant="secondary">
                    {moreActions.map((action) => (
                       <Dropdown.Item
                          as="button"
                          className={action.className}
                          key={action.label}
                          onClick={action.onClick}
                       >
                          {action.label}
                       </Dropdown.Item>
                    ))}
                 </DropdownButton>
              </AiPulse>,
           ]
         : []),
      // Run
      ...(steps.every(
         (step) => step.dataConnectionId !== undefined || step.type !== StepType.DATA_CONNECTION
      )
         ? [
              <AiPulse key="run" on={stepFour || stepSeven} onClick={() => setSteps()} sparkleAfter>
                 <RunButton
                    disabled={!hasQuery}
                    key="run"
                    onClick={() => formatAndRun()}
                    running={isFormatting || isRunning}
                 >
                    Run
                 </RunButton>
              </AiPulse>,
           ]
         : [
              <OverlayTrigger
                 key="select-db"
                 overlay={<Tooltip>Please select a database first</Tooltip>}
              >
                 <div>
                    <RunButton disabled={true} />
                 </div>
              </OverlayTrigger>,
           ]),
   ];

   const onStepChange = (step: QueryStep) => {
      if (stepTwo) {
         setStepTwo();
      }
      const newSteps = steps.slice() ?? [];
      newSteps.splice(step.order - 1, 1, step);
      saveDraft(
         {
            steps: newSteps,
         },
         {
            debounce: true,
         }
      );
      if (queryVersion) {
         setCurrentStep({ queryStep: step });
      }
   };

   const addStep = () => {
      if (!queryVersion) return;
      saveDraft({
         steps: [
            ...steps,
            {
               order: steps.length + 1,
               queryText: '',
               dataConnectionId:
                  steps[steps.length - 1]?.dataConnectionId ?? firstDataConnectionId ?? undefined,
               type: steps[steps.length - 1]?.type ?? StepType.DATA_CONNECTION,
            },
         ],
      });
   };
   useEffect(() => {
      // loading?
      if (firstDataConnectionId === undefined) return;

      // no data connections in workspace?
      if (firstDataConnectionId === null) {
         setShowConnections(true);
         return;
      }

      // This may no longer be necessary since we add an empty first step when an ExploreTab is
      // created.
      if (queryVersion?.steps.length === 0) {
         const newStep = {
            order: 1,
            queryText: '',
            dataConnectionId: firstDataConnectionId,
            type: StepType.DATA_CONNECTION,
         };
         setQueryVersion((queryVersion) => ({
            ...queryVersion,
            steps: [newStep],
         }));
         setCurrentStep({ queryStep: newStep });
      } else if (
         queryVersion?.steps.some(
            (step) => step.type === StepType.DATA_CONNECTION && !step.dataConnectionId
         )
      ) {
         setQueryVersion((queryVersion) => ({
            ...queryVersion,
            steps: (queryVersion?.steps ?? []).map((step) => ({
               ...step,
               dataConnectionId: step.dataConnectionId ?? firstDataConnectionId,
            })),
         }));
      }
   }, [queryVersion, firstDataConnectionId, setCurrentStep]);

   const removeStep = (index: number) => {
      saveDraft({
         steps: steps.slice(0, index).concat(steps.slice(index + 1)),
      });
      setPromptRemoveStep(undefined);
   };

   const addStepOverlay = (
      <Tooltip id="workflowStepHelpInfo">
         Add workflow step
         <br />
         Chain queries across different databases or add python
      </Tooltip>
   );

   const editorControls = (step: QueryStep, index: number) => (
      <Stack className="d-flex align-items-start" direction="horizontal" gap={2}>
         {!readOnly && (
            <Stack className="d-flex align-items-start" direction="horizontal" gap={2}>
               {isWorkflow && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={<Tooltip id="removeWorkflowStepInfo">Delete this step</Tooltip>}
                     placement="top"
                  >
                     <a
                        className="versionHistory"
                        href="#addWorkflowStep"
                        onClick={(e) => {
                           e.preventDefault();
                           setPromptRemoveStep(index);
                        }}
                     >
                        <MdOutlinePlaylistRemove size={18} />
                     </a>
                  </OverlayTrigger>
               )}
               {index === steps.length - 1 && steps[steps.length - 1].queryText && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={addStepOverlay}
                     placement="top"
                  >
                     <a className="versionHistory" href="#addWorkflowStep" onClick={addStep}>
                        <MdOutlinePlaylistAdd size={18} />
                     </a>
                  </OverlayTrigger>
               )}
               {isWorkflow && (
                  <OverlayTrigger
                     delay={{ show: 500, hide: 0 }}
                     overlay={<Tooltip>Run this step only</Tooltip>}
                     placement="top"
                  >
                     <a
                        className="versionHistory"
                        href="#runStep"
                        onClick={(e) => {
                           e.preventDefault();
                           formatAndRun(index + 1);
                        }}
                     >
                        <MdPlaylistPlay size={18} />
                     </a>
                  </OverlayTrigger>
               )}
            </Stack>
         )}
      </Stack>
   );
   const editorStep = (step: QueryStep) =>
      readOnly
         ? {
              ...step,
              queryText: step.queryText
                 ?.split('\n')
                 .map((line, index) => {
                    const comment = step.comments?.find((c) => c.line === index)?.comment;
                    if (comment) {
                       return `${line} -- ${comment}`;
                    }
                    return line;
                 })
                 .join('\n'),
           }
         : step;
   const [stepTwo, setStepTwo] = useWalkthroughStep(walkthroughStep.IDE_CELL);

   const handlePanelResize = (size: number, prevSize?: number) => {
      // Check for prevSize === undefined: Edge case when expanding dialog and then reloading page. prevSize is undefined on first render.
      // If dragged down to less than half height, button will automatically change to Expand.
      if (size < 100 && (prevSize === undefined || size < prevSize) && expanded) {
         setExpanded(false);
         // If expanded up to more than half height, button will automatically change to Collapse.
      } else if (size >= 100 && (prevSize === undefined || size > prevSize) && !expanded) {
         setExpanded(true);
      }
   };

   const collapsePanel = () => {
      const panel = panelRef.current;
      if (panel) {
         panel.resize(collapsedSize > 80 ? 80 : collapsedSize);
      }
   };

   const expandPanel = () => {
      const panel = panelRef.current;
      if (panel) {
         setCollapsedSize(panel.getSize());
         panel.resize(100);
      }
   };

   return (
      <QueryProvider onChange={saveDraft} queryVersion={queryVersion}>
         <>
            <PanelGroup autoSaveId="query" direction="vertical">
               <Panel defaultSize={70} id="main" minSize={0} order={1}>
                  <Stack className="h-100 query-page-background overflow-auto" gap={4}>
                     <AiPulse on={stepTwo} onClick={() => {}} sparkleAfter>
                        {steps.map((step, index) => (
                           <div key={index}>
                              <div className="d-flex justify-content-end px-2 pt-1" key={index}>
                                 <WorkspaceConnectionSelector
                                    dataConnectionId={step.dataConnectionId}
                                    onChange={(dataConnection, type) => {
                                       onStepChange({
                                          ...step,
                                          dataConnection: dataConnection,
                                          dataConnectionId: dataConnection?.id,
                                          type,
                                       });
                                    }}
                                    onlyShared={runSourceEnabled}
                                    readOnly={readOnly}
                                    type={step.type ?? StepType.DATA_CONNECTION}
                                    workspaceId={workspaceId}
                                 />
                              </div>
                              <AiPulse on={isGeneratingQuery} sparkleAfter>
                                 <QueryEditor
                                    dataConnections={
                                       workspaceConnectionList.data?.map(
                                          (c) => c.dataConnection!
                                       ) ?? []
                                    }
                                    loading={false}
                                    onChange={onStepChange}
                                    onFocus={() => {
                                       if (!queryVersion) return;
                                       currentEditorRef.current = editorRefs.current[index];
                                       setFocusedStep(step);
                                       setCurrentStep?.({
                                          queryStep: step,
                                       });
                                    }}
                                    onSelection={(selection) => {
                                       if (!queryVersion) return;
                                       setCurrentStep?.({
                                          selection,
                                       });
                                    }}
                                    readOnly={readOnly || isGeneratingQuery || publishing}
                                    ref={(el: QueryEditorMethods) => {
                                       editorRefs.current[index] = el;
                                    }}
                                    step={editorStep(step)}
                                 />
                              </AiPulse>
                              <div className="d-flex justify-content-end px-2 pb-1">
                                 <div>{editorControls(step, index)}</div>
                              </div>
                           </div>
                        ))}
                     </AiPulse>
                  </Stack>
               </Panel>
               <Stack direction="vertical" gap={2} style={{ flex: 'none' }}>
                  {duplicateQuery && (
                     <div
                        style={{
                           display: 'flex',
                           flexDirection: 'row',
                           justifyContent: 'space-between',
                           minWidth: '100%',
                           gap: '0.25rem',
                           padding: '0 1rem',
                        }}
                     >
                        <div style={{ alignSelf: 'center', color: 'darkgoldenrod' }}>
                           A saved query matching this one already exists.{' '}
                        </div>
                        <Button
                           onClick={() => {
                              openAnotherQuery(duplicateQuery);
                           }}
                           size="sm"
                        >
                           Open Existing Query
                        </Button>
                     </div>
                  )}
                  <Stack
                     className="px-3 py-2 query-page-button-bar border-top-line justify-content-between"
                     direction="horizontal"
                     gap={2}
                  >
                     <Stack className="align-items-center" direction="horizontal">
                        {!isInitialUnsavedQueryVersion && queryVersion && (
                           <QueryHeader
                              queryVersion={queryVersion}
                              setShared={readOnly ? undefined : share}
                           />
                        )}
                     </Stack>
                     <div>
                        {actionsBarMsg ? (
                           <span className="fs-11p text-muted">{actionsBarMsg}...</span>
                        ) : null}
                     </div>
                     <Stack direction="horizontal" gap={1}>
                        {actionButtons}
                     </Stack>
                  </Stack>
               </Stack>
               {queryVersion && (
                  <>
                     <PanelResizeHandle className="panelHandle horizontal">
                        <div className="panelHandleLine" />
                     </PanelResizeHandle>
                     <Panel
                        defaultSize={30}
                        id="bottom"
                        onResize={handlePanelResize}
                        order={2}
                        ref={panelRef}
                     >
                        <QueryPanel
                           allowDocGeneration={canGenerateDocumentation}
                           collapsePanel={collapsePanel}
                           defaultTab={defaultTab}
                           expandPanel={expandPanel}
                           hideTabs={{
                              admin: readOnly,
                              comments: true,
                           }}
                           isGeneratingDocumentation={isGeneratingDocumentation}
                           onChangeDocumentation={(params) => saveDraft(params, { debounce: true })}
                           onClickGenerateDocumentation={handleClickGenerateDocumentation}
                           queryResults={isRunning ? [] : results}
                           queryVersion={queryVersion}
                           readOnly={readOnly}
                           setShowUpgradeModal={() => setShowUpgradeModal(true)}
                        />
                     </Panel>
                  </>
               )}
            </PanelGroup>
            {runModals}
            <Modal
               onHide={() => setPromptRemoveStep(undefined)}
               show={promptRemoveStep !== undefined}
            >
               <Modal.Header className="border-0 mb-0 pb-0" closeButton>
                  <Modal.Title className="fs-14p">Delete Step</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  <span>Are you sure you wish to delete this step?</span>
                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        onClick={() => setPromptRemoveStep(undefined)}
                        size="sm"
                        variant="secondary"
                     >
                        Cancel
                     </Button>
                     <Button
                        className="ms-2"
                        onClick={() =>
                           promptRemoveStep !== undefined && removeStep(promptRemoveStep)
                        }
                        size="sm"
                        type="button"
                     >
                        Delete
                     </Button>
                  </div>
               </Modal.Body>
            </Modal>
            <ManageWorkspaceConnections
               onClose={() => setShowConnections(false)}
               show={showConnections}
               workspaceId={workspaceId}
            />

            {showUpgradeModal && (
               <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
            )}
         </>
      </QueryProvider>
   );
};
export default QueryPage;
