import { Button, Form, Spinner } from 'react-bootstrap';
import { FormEvent, useState } from 'react';
import { post } from '../utilities';
import { Link } from 'react-router-dom';
import React from 'react';
import PublicPage from '../components/PublicPage';

export const PasswordResetPage = (): React.JSX.Element => {
   const [emailSent, setEmailSent] = useState(false);
   const [formProcessing, setFormProcessing] = useState(false);

   const emailEntered = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (!event.currentTarget.checkValidity()) {
         event.stopPropagation();
         return;
      }
      setFormProcessing(true);
      try {
         const data = new FormData(event.currentTarget);
         const body = Object.fromEntries(data.entries());
         await post('/auth/makelink', { body });
      } catch (e) {}
      setEmailSent(true);
      setFormProcessing(false);
   };

   return emailSent ? (
      <PublicPage>
         <div className="card p-4 border-1 shadow-sm" style={{ maxWidth: '600px' }}>
            <h3 className="">Check Your Inbox</h3>
            <p className="mb-1">
               Your journey back to runQL has begun!
               <br />A secret code will appear in your email inbox any second now.
            </p>
         </div>
      </PublicPage>
   ) : (
      <PublicPage>
         <div className="card p-4 border-1 shadow-sm" style={{ maxWidth: '600px' }}>
            <h3 className="">Reset Password</h3>
            <p className="fs-12p">
               Please enter your email below. If we recognize it, we'll send you a link to reset
               your password.
            </p>
            <p className="fs-12p">
               Remembered your password?{' '}
               <Link className="text-decoration-none" to="/">
                  Sign In
               </Link>
               .
            </p>

            <form onSubmit={emailEntered}>
               <div className="row">
                  <div className="col-12 mb-4">
                     <Form.Label>Email address</Form.Label>
                     <Form.Control name="email" required type="email"></Form.Control>
                  </div>
               </div>
               <Button disabled={formProcessing} type="submit">
                  {formProcessing ? <Spinner /> : 'Send'}
               </Button>
            </form>
         </div>
      </PublicPage>
   );
};

export default PasswordResetPage;
