import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { QueryKey, QueryKeyType } from '../../enums';
import { GetOptionsQueryLog, ListOptionsQueryLog, QueryLogService } from '../../services';
import { PaginatedResult, QueryLog } from '../../entities';
import { TYPES } from '../../types';

const useQueryLogService = () => {
   return useInjection<QueryLogService>(TYPES.querylogService);
};

export const useListQueryLogs = (
   {
      callbacks,
      listOptions,
      queryOptions,
   }: {
      callbacks?: {
         onError?: (err: unknown) => void;
         onSuccess?: (data: PaginatedResult<QueryLog>) => void;
      };
      listOptions?: ListOptionsQueryLog;
      queryOptions?: { enabled?: boolean };
   } = { callbacks: undefined, listOptions: undefined, queryOptions: undefined }
) => {
   const querylogService = useQueryLogService();
   return useQuery<PaginatedResult<QueryLog>>(
      getQueryLogQueryKey({ type: QueryKeyType.LIST, options: listOptions }),
      () => querylogService.listOptions(listOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         enabled: queryOptions?.enabled,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export function getQueryLogQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsQueryLog | GetOptionsQueryLog;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.QueryLog, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
