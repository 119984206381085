import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ConnectionAccessType, DataConnection } from '../../../entities';

import { getShortDateString } from '../../../utilities';
import { ConnectionCardField } from './DataConnectionCard';

function DatabricksCard({
   dataConnection,
   disabled,
}: {
   dataConnection: DataConnection;
   disabled?: boolean;
}) {
   return (
      <Card
         as={Link}
         className={`other-cards border-0 bg-secondary py-2 px-3 ${disabled ? 'disabled' : ''}`}
         to={disabled ? '' : `/sources/${dataConnection.id}`}
      >
         <Row className="align-items-center">
            <ConnectionCardField bold label="Connection Name" value={dataConnection.name} xs={2} />
            <ConnectionCardField label="Host" value={dataConnection.dbHost} xs={4} />
            <Col xs={1} />
            <ConnectionCardField label="Type" value={dataConnection.dbms} xs={1} />
            <ConnectionCardField
               label="Added"
               value={getShortDateString(dataConnection.created)}
               xs={1}
            />
            <ConnectionCardField
               label="Shared"
               value={dataConnection.connectionAccessType === ConnectionAccessType.SHARED}
               xs={1}
            />
            <ConnectionCardField
               label="Hide Details"
               value={dataConnection.hideDetails === undefined ? false : dataConnection.hideDetails}
               xs={1}
            />
         </Row>
      </Card>
   );
}

export default DatabricksCard;
