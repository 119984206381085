import { Alert } from 'react-bootstrap';

interface Props {
   message?: string;
}

const LoadingError = (props: Props): JSX.Element => {
   return (
      <Alert variant="danger">
         {props.message !== undefined ? props.message : 'Unknown Loading Error'}
      </Alert>
   );
};

export default LoadingError;
