import { QueryVersion } from '../../entities';
import BusinessQueryVersion from './BusinessQueryVersion';

const StartingBlockSavedQuery = ({ query }: { query: QueryVersion }): JSX.Element => {
   return (
      <div className="border-0 query-card card vstack gap-0">
         {query && <BusinessQueryVersion queryVersion={query} />}
      </div>
   );
};
export default StartingBlockSavedQuery;
