import { SyntaxItem, QueryReturn } from '../interfaces';
import { Base } from './Base';
import { DataConnection, Person, QueryVersion, StepType, Workspace, QueryStep } from '.';

export enum QueryLogContext {
   EXPLORER = 1,
   SYSTEM = 2,
}

export interface QueryLog extends Base {
   context: QueryLogContext;
   dataConnection?: DataConnection;
   dataConnectionId?: number;
   exploreTabId?: number;
   orgId?: number;
   person?: Person;
   personId?: number;
   queryId?: number;
   querySyntaxTree?: SyntaxItem[];
   queryText?: string;
   queryVersionId?: number;
   runId?: number;
   runtime?: number;
   step?: number;
   workspace?: Workspace;
   workspaceId?: number;
}

export function logsFromQueryReturn({
   exploreTabId,
   queryVersion,
   results,
   steps,
}: {
   exploreTabId?: number;
   queryVersion: QueryVersion;
   results: QueryReturn[];
   steps: QueryStep[];
}): QueryLog[] {
   return results.map((result, i) => ({
      context: QueryLogContext.EXPLORER,
      dataConnectionId: steps[i].dataConnectionId,
      queryId: queryVersion.queryId,
      queryText: steps[i].queryText,
      queryVersionId: queryVersion.id,
      runtime: result.runtime,
      step: steps[i].order,
      workspaceId: queryVersion.query?.workspaceId,
      exploreTabId,
   }));
}

export function groupLogs(queryLogs: QueryLog[]): QueryLog[][] {
   let runId: number | undefined = undefined;
   const groupedLogs = queryLogs.reduce((acc, log) => {
      if (log.runId === undefined || log.runId !== runId) {
         acc.push([log]);
         runId = log.runId;
      } else {
         acc[acc.length - 1].push(log);
      }
      return acc;
   }, [] as QueryLog[][]);
   groupedLogs.map((steps) =>
      steps.sort((a, b) => (a.step !== undefined && b.step !== undefined ? a.step - b.step : 0))
   );
   return groupedLogs;
}

export function createQueryVersionFromLogs(logs: QueryLog[]): QueryVersion {
   return {
      created: logs[0].created,
      createdByPerson: logs[0].createdByPerson,
      createdByPersonId: logs[0].createdByPersonId,
      id: logs[0].queryVersionId,
      isLog: true,
      modified: logs[0].modified,
      modifiedByPerson: logs[0].modifiedByPerson,
      modifiedByPersonId: logs[0].modifiedByPersonId,
      queryId: logs[0].queryId,
      steps: logs.map((log, i) => ({
         dataConnectionId: log.dataConnectionId,
         type: StepType.DATA_CONNECTION,
         order: log.step ?? i,
         queryText: log.queryText ?? '',
      })),
   };
}
