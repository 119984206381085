import { useInjection } from 'inversify-react';
import { Stack } from 'react-bootstrap';
import { useQuery } from 'react-query';

import { SnippetService } from '../services';
import { Snippet, StepType } from '../entities';
import { QueryKey } from '../enums';
import { TYPES } from '../types';
import { handleError, useTitle } from '../utilities';
import { HomeNav, Page, QueryWidget } from '../components';

export const SnippetsPage = (): JSX.Element => {
   useTitle('Snippets');
   const snippetService = useInjection<SnippetService>(TYPES.snippetService);
   const snippetKey = [QueryKey.Snippet];
   const snippets = useQuery<Snippet[] | undefined>(snippetKey, () => snippetService.list(), {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      onError(err) {
         handleError(err);
      },
   });

   return (
      <Page
         header={<div>Home</div>}
         nav={<HomeNav />}
         tabs={[
            {
               title: 'My Snippets',
               content: (
                  <Stack className="dimmed-queries snippets" gap={2}>
                     {!!snippets.data?.length && (
                        <Stack gap={2}>
                           {snippets.data?.map((snippet, i) => (
                              <QueryWidget
                                 action="none"
                                 queryVersion={{
                                    steps: [
                                       {
                                          order: 0,
                                          queryText: snippet.snippet,
                                          type: StepType.DATA_CONNECTION,
                                       },
                                    ],
                                 }}
                                 source="snippet"
                              />
                           ))}
                        </Stack>
                     )}
                  </Stack>
               ),
            },
         ]}
      />
   );
};

export default SnippetsPage;
