import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import {
   Button,
   Collapse,
   Form,
   InputGroup,
   OverlayTrigger,
   Tooltip,
   Stack,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import LoadingError from '../../../../components/UI/LoadingError';
import { ConnectionAccessType, ConnectionLanguage } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage, IconInformation, IconX } from '../../../../utilities';
import { connectionName, description, host, withHostRefinement, port } from '../validators';
import { AccessTypeField, HideDetailsField } from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   accountName: z.string(),
   password: z.string(),
});

const mongoSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.MongoDB),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         language: z.literal(ConnectionLanguage.SQL),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         //authenticate: z.boolean().default(false),
         database: z.string().min(1, 'required').trim(),
         sharedConnection: z.boolean(),
         hideDetails: z.boolean(),
         authSource: z.string().optional(),
         useSSL: z.boolean().optional(),
         sslCaCert: z.any().optional(),
         sslCaCertId: z.number().optional(),
         sslClientCert: z.any().optional(),
         sslClientCertId: z.number().optional(),
         sslClientKey: z.any().optional(),
         sslClientKeyId: z.number().optional(),
      })
      .merge(credentialsSchema)
);

type MongoDetailFormData = z.infer<typeof mongoSchema>;

function MongoDetailForm({
   editType,
   formId,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const {
      handleSubmit,
      register,
      formState: { errors, touchedFields, dirtyFields },
      reset,
      watch,
      setValue,
      setError,
   } = useForm<MongoDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : mongoSchema),
      mode: 'onTouched',
      defaultValues: {
         dbms: DBMS.MongoDB,
         language: ConnectionLanguage.SQL,
         connectionAccessType: ConnectionAccessType.INDIVIDUAL,
         connectionName: '',
         description: '',
         host: '',
         port: 27017,
         //authenticate: false,
         database: '',
         sharedConnection: false,
         hideDetails: false,
         accountName: '',
         password: '',
         authSource: '',
         useSSL: false,
      },
   });

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const formData: MongoDetailFormData = {
            dbms: DBMS.MongoDB,
            language: ConnectionLanguage.SQL,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? '27017'),
            //authenticate: false,
            database: selectedConnectionQuery.data.dbName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
            authSource: selectedConnectionQuery.data.authSource ?? '',
            sslCaCertId: selectedConnectionQuery.data.sslCaCertId ?? undefined,
            sslClientCertId: selectedConnectionQuery.data.sslClientCertId ?? undefined,
            sslClientKeyId: selectedConnectionQuery.data.sslClientKeyId ?? undefined,
            useSSL: !!(
               selectedConnectionQuery.data.sslCaCertId ||
               selectedConnectionQuery.data.sslClientCertId ||
               selectedConnectionQuery.data.sslClientKeyId
            ),
         };

         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [dirtyFields, onSaveStateChange]);

   // Event handlers
   const onSubmitHandler = (data: MongoDetailFormData) => {
      onSubmit?.(data);
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof MongoDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof MongoDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof MongoDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof MongoDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   const useSSL = !!watch('useSSL', false);
   const sslCaCertId = watch('sslCaCertId', undefined);
   const sslClientCertId = watch('sslClientCertId', undefined);
   const sslClientKeyId = watch('sslClientKeyId', undefined);
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // Render
   if (selectedConnectionQuery.isLoading || authPersonQuery.isLoading) return <LoadingSpinner />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;

   return (
      <Form id={formId} onSubmit={handleSubmit(onSubmitHandler)}>
         <Stack gap={3}>
            {!onlyCreds && (
               <>
                  <Form.Group controlId="connectionName">
                     <Form.Label>
                        Connection Name <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('connectionName')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.connectionName && !!errors.connectionName}
                        isValid={touchedFields.connectionName && !errors.connectionName}
                        placeholder="Connection Name"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.connectionName?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="description">
                     <Form.Label>Description</Form.Label>
                     <Form.Control
                        {...register('description')}
                        as="textarea"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.description && !!errors.description}
                        isValid={touchedFields.description && !errors.description}
                        placeholder="Description"
                        rows={3}
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.description?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="language">
                     <Form.Label>Language</Form.Label>
                     <Form.Select {...register('language')} disabled>
                        <option value={ConnectionLanguage.SQL}>SQL</option>
                     </Form.Select>
                  </Form.Group>
                  <Form.Group controlId="host">
                     <Form.Label>
                        Host <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('host')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.host && !!errors.host}
                        isValid={touchedFields.host && !errors.host}
                        placeholder="Host"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.host?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="port">
                     <Form.Label>
                        Port <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Control
                        {...register('port', { valueAsNumber: true })}
                        defaultValue="5432"
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.port && !!errors.port}
                        isValid={touchedFields.port && !errors.port}
                        placeholder="Port"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.port?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="database">
                     <Form.Label>Database</Form.Label>
                     <span className="text-danger">*</span>
                     <Form.Control
                        {...register('database')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.database && !!errors.database}
                        isValid={touchedFields.database && !errors.database}
                        placeholder="Database"
                        required
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.database?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="authSource">
                     <Form.Label>
                        Authentication Database
                        <OverlayTrigger
                           overlay={<Tooltip>The database containing the user credentials</Tooltip>}
                           placement="auto"
                        >
                           <span>
                              <IconInformation size={16} />
                           </span>
                        </OverlayTrigger>
                     </Form.Label>
                     <Form.Control
                        {...register('authSource')}
                        disabled={editType !== 'connection'}
                        isInvalid={touchedFields.authSource && !!errors.authSource}
                        isValid={touchedFields.authSource && !errors.authSource}
                        placeholder="Auth Source Database"
                     />
                     <Form.Control.Feedback type="invalid">
                        {errors.authSource?.message}
                     </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                     <Form.Label>SSL Mode</Form.Label>
                     <Form.Select
                        disabled={editType !== 'connection'}
                        {...register('useSSL', {
                           setValueAs: (v) => v === true || v === 'true',
                        })}
                     >
                        <option value="false">Use SSL if available</option>
                        <option value="true">Require SSL and validate certificates</option>
                     </Form.Select>
                  </Form.Group>
                  <Collapse in={useSSL}>
                     <div>
                        <Form.Group controlId="sslCaCert">
                           <Form.Label>SSL CA Certificate</Form.Label>
                           {sslCaCertId ? (
                              <InputGroup>
                                 <Form.Control placeholder="Uploaded File" readOnly type="text" />
                                 <Button
                                    onClick={() =>
                                       setValue('sslCaCertId', undefined, { shouldDirty: true })
                                    }
                                    variant="secondary"
                                 >
                                    <IconX />
                                 </Button>
                              </InputGroup>
                           ) : (
                              <Form.Control {...register('sslCaCert')} type="file" />
                           )}
                        </Form.Group>
                        <Form.Group controlId="sslClientCert">
                           <Form.Label>SSL Client Certificate</Form.Label>
                           {sslClientCertId ? (
                              <InputGroup>
                                 <Form.Control placeholder="Uploaded File" readOnly type="text" />
                                 <Button
                                    onClick={() =>
                                       setValue('sslClientCertId', undefined, { shouldDirty: true })
                                    }
                                    variant="secondary"
                                 >
                                    <IconX />
                                 </Button>
                              </InputGroup>
                           ) : (
                              <Form.Control {...register('sslClientCert')} type="file" />
                           )}
                        </Form.Group>
                        <Form.Group controlId="sslClientKey">
                           <Form.Label>SSL Client Key</Form.Label>
                           {sslClientKeyId ? (
                              <InputGroup>
                                 <Form.Control placeholder="Uploaded File" readOnly type="text" />
                                 <Button
                                    onClick={() =>
                                       setValue('sslClientKeyId', undefined, { shouldDirty: true })
                                    }
                                    variant="secondary"
                                 >
                                    <IconX />
                                 </Button>
                              </InputGroup>
                           ) : (
                              <Form.Control {...register('sslClientKey')} type="file" />
                           )}
                        </Form.Group>
                     </div>
                  </Collapse>
                  <AccessTypeField
                     disabled={editType !== 'connection'}
                     {...register('connectionAccessType', {
                        setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                        onChange(event) {
                           if (parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL) {
                              setValue('hideDetails', false, { shouldDirty: true });
                           }

                           setValue('accountName', '', { shouldDirty: true });
                           setValue('password', '', { shouldDirty: true });
                        },
                     })}
                  />
                  {explorerIsAdmin && (
                     <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                        <div>
                           <HideDetailsField
                              {...register('hideDetails')}
                              disabled={editType !== 'connection'}
                           />
                        </div>
                     </Collapse>
                  )}
               </>
            )}
            <Form.Group controlId="accountName">
               <Form.Label>User</Form.Label>
               <Form.Control
                  {...register('accountName')}
                  disabled={editType !== 'connection'}
                  isInvalid={touchedFields.accountName && !!errors.accountName}
                  isValid={touchedFields.accountName && !errors.accountName}
                  placeholder="User"
               />
               <Form.Control.Feedback type="invalid">
                  {errors.accountName?.message}
               </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
               <Form.Label>Password</Form.Label>
               <Form.Control
                  {...register('password')}
                  disabled={editType !== 'connection'}
                  isInvalid={touchedFields.password && !!errors.password}
                  isValid={touchedFields.password && !errors.password}
                  onBlur={handleBlur('', 'CURRENT', 'password')}
                  onFocus={handleFocus('CURRENT', '')}
                  placeholder="Password"
                  type="password"
               />
               <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
               </Form.Control.Feedback>
            </Form.Group>
         </Stack>
      </Form>
   );
}

export default MongoDetailForm;
