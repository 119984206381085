import { Provider } from 'inversify-react';
import ReactDOM from 'react-dom/client';
import 'reflect-metadata';
import 'boxicons/css/boxicons.min.css';

import './assets/scss/theme.scss';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import { ThemeProvider } from './components/ThemeContext';
import container from './container';
import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.min.css';
import '@react-sigma/core/lib/react-sigma.min.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
   <>
      <ThemeProvider>
         <Provider container={container}>
            <QueryClientProvider client={queryClient}>
               <App />
               <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
         </Provider>
      </ThemeProvider>
   </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
