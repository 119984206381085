import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';
import { WorkspaceLogItem } from '../entities';

const path = '/v1/workspaceLog';

@injectable()
export class WorkspaceLogService implements ApiServiceInterface<WorkspaceLogItem> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   listOptions(filter: Object): Promise<WorkspaceLogItem[]> {
      throw new Error('Method not implemented.');
   }

   patch(
      id: string | number,
      body: WorkspaceLogItem,
      params?: Record<string, string> | undefined
   ): Promise<WorkspaceLogItem | undefined> {
      throw new Error('Method not implemented.');
   }

   post(
      body: WorkspaceLogItem,
      params?: Record<string, string> | undefined
   ): Promise<WorkspaceLogItem | undefined> {
      throw new Error('Method not implemented.');
   }

   async put(
      _body: WorkspaceLogItem,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceLogItem | undefined> {
      throw new Error('Method not implemented.');
   }

   delete(id: string | number): Promise<null> {
      throw new Error('Method not implemented.');
   }

   get(id: string | number): Promise<WorkspaceLogItem | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params?: Record<string, string>): Promise<WorkspaceLogItem[]> {
      const result = await this.apiService.get<WorkspaceLogItem[]>(path, params);
      if (!result) {
         return [];
      }

      const data = result as WorkspaceLogItem[];
      return data;
   }
}
