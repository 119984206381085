import React, { createContext, useContext, useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { FeatureService } from '../services';
import { TYPES } from '../types';

const FeatureContext = createContext({} as Record<string, boolean>);

function getLocalFeatures() {
   let localFeatures: Record<string, boolean> = {};
   try {
      const localVal = localStorage.getItem('flags');
      if (localVal) {
         const parsed = JSON.parse(localVal);
         if (parsed && typeof parsed === 'object') {
            localFeatures = parsed;
         }
      }
   } catch (e) {
      console.error('Error parsing flags from localStorage', e);
   }
   return localFeatures;
}

export const FeatureProvider = ({ children }: { children: React.ReactNode }) => {
   // cache the latest value in local storage to avoid a flash of missing
   // features
   const [features, setFeatures] = useState(getLocalFeatures());
   const featureService = useInjection<FeatureService>(TYPES.featureService);

   useEffect(() => {
      try {
         featureService.get().then((data) => {
            setFeatures(data);
            localStorage.setItem('flags', JSON.stringify(data));
         });
      } catch (e) {
         console.error('Error getting features');
         console.error(e);
      }
   }, [featureService]);

   return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
};

export const useFeature = (feature: string) => {
   const features = useContext(FeatureContext);
   return features[feature] || false;
};
