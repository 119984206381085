import { Person, SchemaCache } from '.';
import { ADMIN_ROLES, PersonRole } from '../enums';
import { DBMS } from '../enums/dbms';
import { notUndefined } from '../utilities';
import { Base } from './Base';
import { DataCredential } from './DataCredentials';

export enum SSLMode {
   OPTIONAL = 1,
   VALIDATED = 2,
}

export enum SSHAuthenticationMethod {
   PASSWORD = 1,
   KEY_FILE = 2,
}

export enum ConnectionAccessType {
   INDIVIDUAL = 0,
   SHARED = 1,
   DEMO = 2,
   SYSTEM = 3, // Connections with SYSTEM access (e.g. federation, python) don't need credentials
}

export enum ConnectionLanguage {
   DEFAULT = 0,
   SQL = 1,
}

export interface DataConnection extends Base {
   authSource?: string;
   connectionAccessType?: ConnectionAccessType;
   dataCredentials?: DataCredential[];
   dbHost?: string;
   dbName?: string;
   dbPort?: string;
   dbms?: DBMS;
   description?: string;
   hideDetails?: boolean;
   language?: ConnectionLanguage;
   name?: string;
   orgId?: number;
   schemaCaches?: SchemaCache[];
   /** read only */
   schemaGenError?: string;
   snowflakeAccount?: string;
   snowflakeWarehouse?: string;
   sshHost?: string;
   sshPort?: string;
   sslCaCert?: File;
   sslCaCertId?: number;
   sslClientCert?: File;
   sslClientCertId?: number;
   sslClientKey?: File;
   sslClientKeyId?: number;
   sslMode?: SSLMode;
   taskType?: string;
   useSSH?: boolean;
}

export function getConnectionAccountName(conn: DataConnection) {
   if (conn.dataCredentials !== undefined && conn.dataCredentials[0] !== undefined) {
      return notUndefined(conn.dataCredentials[0].accountName, '');
   } else {
      return '';
   }
}

export function getConnectionCredentials(conn: DataConnection) {
   if (conn.dataCredentials !== undefined && conn.dataCredentials[0] !== undefined) {
      return conn.dataCredentials[0];
   }
   return undefined;
}

export function getAllowableEditType(
   connection: DataConnection | undefined,
   explorer: Person | undefined
) {
   if (connection === undefined) {
      // This is a new connection
      return 'connection';
   }

   if (connection.connectionAccessType === ConnectionAccessType.DEMO) {
      // demo connection cannot be edited
      return 'read-only';
   }

   if (ADMIN_ROLES.includes(explorer?.role ?? PersonRole.ORG_EXPLORER)) {
      // admin can always edit
      return 'connection';
   }

   if (connection.createdByPersonId === explorer?.id) {
      // owner can always edit
      return 'connection';
   }

   if (connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
      // controlled connections can have additional credentials
      return 'credential';
   }

   return 'read-only';
}
