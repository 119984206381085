import { useInjection } from 'inversify-react';
import { CurrentExplorerResult, PersonService } from '../../services';
import { TYPES } from '../../types';
import { useQuery } from 'react-query';

const usePersonService = () => {
   return useInjection<PersonService>(TYPES.personService);
};

export const useExplorer = () => {
   const service = usePersonService();
   return useQuery<CurrentExplorerResult | undefined>(
      'current-explorer',
      () => service.getCurrentExplorer(),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   ).data;
};
