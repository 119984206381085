import { Section } from '../DocumentationPage';

const queryHistoryVsSavedQueries: string = `
#### What is the difference between Query History and Saved Queries?

Query History captures every query you have successfully run whereas Saved Queries captures and versions every query you save.
`;

const importExistingQueries: string = `
#### How can I import existing queries from git or .sql files?

Files containing sql queries can be imported into a workspace.  This can be files on your desktop or exported from git.

Note:
- The name of the \`.sql\` file will be used as the saved query title.
- Each query statement (separated by a semi-colon) will be imported as a separate saved query.
- If there is a comment in the file before the query, then it will get saved as the query description.
- To import a set of \`.sql\` files, place them in a \`.zip\` archive or use your git export.

To import your queries:
1. Open the workspace or create a new one.
2. Ensure you have attached at least one database to the workspace.
3. Open the three dot menu to the right of the workspace name.
4. Select **Import SQL files**.
5. Select the zip or .sql file(s).
6. Click **Import**.
`;
const visualizeDataQuestion: string = `
#### How Can I Visualize My Data?

After executing a query, you can view a quick chart by selecting fields in the result set, then right click and select a chart type.  For better visualizations that get saved with the query, click on the **Chart** tab.
`;
const savedQueriesQuestion: string = `
#### How Can I View Saved Queries, Logs, And Suggestions?

###### Organization Context:
To view queries across all you workspaces, navigate to the home page and select the **Saved Queries** or **Query History** menu item.

###### Workspace Context:
You can click on the saved queries icon icon in the left panel to view and search all your queries relevant to that workspace.
You can review query history by clicking the history icon in the left panel to search all query history.  

###### Query Window Context
You can review query logs or versions in the bottom tabs for a query.  These sections provide detailed records of your query activities, allowing you to track changes and find previously executed queries.
When composing a new query in a workspace, utilize the **Suggestions** tab to discover similar queries that you or your team have previously written.
`;

const certifyQueriesQuestion: string = `
#### How Can I Certify Queries for My Organization?

Once you've written and saved your query, navigate to the **Versions** tab and click the **Certify** button on the version you want to be certified. This action creates a notification icon in the left navigation menu for your team mates, letting them know that there is a query to be reviewed for certification.

A team member can then visit **Certifications** to review queries pending certification. Once certified a green checkmark will appear next to the query version. This visual indicator lets everyone that this is the validated, trusted query.
`;
export const generalQuestions: Section[] = [
   {
      question: 'What is the difference between Query History and Saved Queries?',
      answer: queryHistoryVsSavedQueries,
      group: 'General',
   },
   {
      question: 'How can I import existing queries from git or elsewhere?',
      answer: importExistingQueries,
      group: 'General',
   },
   {
      question: 'How can I visualize my data?',
      answer: visualizeDataQuestion,
      group: 'General',
   },
   {
      question: 'How Can I View Saved and Previous Queries?',
      answer: savedQueriesQuestion,
      group: 'General',
   },
   {
      question: 'How Can I Certify Queries for My Organization?',
      answer: certifyQueriesQuestion,
      group: 'General',
   },
];
