import { DBMS } from '../../enums';
import TreeViewNode from './TreeViewNode';

import type { DatabaseNode } from '../CreateTableModal';
import type { TableNode } from '../DeleteTableModal';
import { useMemo } from 'react';

export interface TreeNode {
   catalogName?: string;
   children: Array<TreeNode>;
   dataConnectionId?: number;
   databaseName?: string;
   dbms: DBMS;
   defaultFlag: number;
   definition?: string;
   description?: string;
   exampleData?: string;
   fieldDataType?: string;
   icon: string;
   id: number;
   isDemo?: boolean;
   isGenerating?: Date;
   isView?: boolean;
   key: string;
   label: string;
   tableName?: string;
   tooltip: string;
   type: string;
   visible: boolean;
}

function filterTree(node: TreeNode, searchText: string): boolean {
   if (node.type === 'columnNode') {
      // We only consider up until the table level.
      return false;
   }
   const lowerSearchText = searchText.toLowerCase();

   if (node.label.toLowerCase().includes(lowerSearchText)) {
      return true;
   }

   if (node.children) {
      return node.children.some((child) => filterTree(child, searchText));
   }

   return false;
}

function filterTreeData(treeData: TreeNode[], searchText: string): TreeNode[] {
   return treeData
      .map((node: TreeNode) => ({
         ...node,
         children: node.children ? filterTreeData(node.children, searchText) : [],
      }))
      .filter((node) => filterTree(node, searchText));
}

const TreeView = (props: {
   catalogNames?: boolean;
   expand?: boolean;
   expandLevels?: string[];
   parent?: TreeNode;
   searchText: string;
   showCreateTableModal: (databaseNode: DatabaseNode) => void;
   showDeleteTableModal: (tableNode: TableNode) => void;
   showQuickSelect?: boolean;
   treeData: TreeNode[] | undefined;
}): JSX.Element => {
   const treeData = useMemo(() => {
      return props.searchText === ''
         ? props.treeData || []
         : filterTreeData(props.treeData || [], props.searchText);
   }, [props.treeData, props.searchText]);

   if (!props.treeData) {
      return <></>;
   }

   return (
      <ul className="nav flex-column list-unstyled navigator">
         {treeData.map((node, index) => (
            <TreeViewNode
               catalogNames={props.catalogNames}
               expand={props.expand}
               expandLevels={props.expandLevels}
               key={`${node.key}_${index}`}
               node={node}
               parent={props.parent}
               searchText={props.searchText}
               showCreateTableModal={props.showCreateTableModal}
               showDeleteTableModal={props.showDeleteTableModal}
               showQuickSelect={props.showQuickSelect}
            />
         ))}
      </ul>
   );
};

export default TreeView;
