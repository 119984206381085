import { injectable } from 'inversify';

import { CredentialData, RQL_ENCRYPTION_VERSIONS } from '@runql/util';
import type { DataCredential } from '../entities';

export const SESSION_STORAGE_AUTHN_PERSON_ID_KEY = 'authNPersonId';

export interface LocalCredentialService {
   delete(dataConnectionId: number): Promise<void>;
   generateHeaders(dataConnectionIds: number | number[]): Promise<Record<string, string> | {}>;
   get(dataConnectionId: number): Promise<CredentialData | undefined>;
   getMap(
      dataConnectionIds: number | number[],
      options?: { defaultRqlEncryptionVersion?: number }
   ): Promise<Record<number, CredentialData>>;
   has(dataConnectionId: number): Promise<boolean>;
   set(dataConnectionId: number, credential: DataCredential): Promise<boolean>;
}

// Note: 'sshPassword' is used as the passphrase for the SSH key file when 'sshAuthMethod' is equal
// to SSHAuthenticationMethod.KEY_FILE.
function formatCredentialForStorage(dataCredential: DataCredential) {
   return (
      [
         'accountName',
         'accountPassword',
         'sshAuthMethod',
         'sshUsername',
         'sshPassword',
         'sshKeyFile',
         'rqlEncryptionVersion',
      ] as Array<keyof DataCredential>
   ).reduce((acc, key) => {
      return {
         ...acc,
         ...(dataCredential[key] ? { [key]: dataCredential[key] } : {}),
      };
   }, {} as CredentialData);
}

@injectable()
abstract class BaseLocalCredentialService implements LocalCredentialService {
   abstract delete(dataConnectionId: number): Promise<void>;
   abstract get(dataConnectionId: number): Promise<CredentialData | undefined>;
   abstract has(dataConnectionId: number): Promise<boolean>;
   abstract set(dataConnectionId: number, credential: DataCredential): Promise<boolean>;

   async getMap(
      dataConnectionIds: number | number[],
      { defaultRqlEncryptionVersion }: { defaultRqlEncryptionVersion?: number } = {}
   ) {
      if (!Array.isArray(dataConnectionIds)) {
         dataConnectionIds = [dataConnectionIds];
      }

      const entries = (
         await Promise.all(
            dataConnectionIds.map(async (dataConnectionId) => {
               const credentialData = await this.get(dataConnectionId);

               if (!credentialData) {
                  return [];
               }

               // On the web client, credentialData should have an rqlEncryptionVersion value that
               // overrides the default value, except for credentials that were stored before
               // versioning was introduced.
               return [
                  [
                     dataConnectionId,
                     { rqlEncryptionVersion: defaultRqlEncryptionVersion, ...credentialData },
                  ],
               ];
            })
         )
      ).flat();

      return Object.fromEntries(entries) as Record<number, CredentialData>;
   }

   async generateHeaders(dataConnectionIds: number | number[]) {
      const obj = await this.getMap(dataConnectionIds);

      if (Object.keys(obj).length === 0) {
         return {};
      }

      return { 'runql-credentials': encodeURIComponent(JSON.stringify(obj)) };
   }
}

@injectable()
export class WebLocalCredentialService extends BaseLocalCredentialService {
   private generateLocalStorageKey(dataConnectionId: number) {
      const personId = sessionStorage.getItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);
      return `runQL-credentials-${personId}-${dataConnectionId}`;
   }

   delete(dataConnectionId: number) {
      return Promise.resolve(
         localStorage.removeItem(this.generateLocalStorageKey(dataConnectionId))
      );
   }

   private getSync(dataConnectionId: number) {
      const value = localStorage.getItem(this.generateLocalStorageKey(dataConnectionId));

      if (value === null) {
         return undefined;
      }

      try {
         return JSON.parse(value) as CredentialData;
      } catch (err: unknown) {
         return undefined;
      }
   }

   get(dataConnectionId: number) {
      return Promise.resolve(this.getSync(dataConnectionId));
   }

   // Override options so defaultRqlEncryptionVersion is always 'v1'
   getMap(
      dataConnectionIds: number | number[],
      notUsed?: { defaultRqlEncryptionVersion?: number }
   ) {
      return super.getMap(dataConnectionIds, {
         defaultRqlEncryptionVersion: RQL_ENCRYPTION_VERSIONS.v1,
      });
   }

   has(dataConnectionId: number) {
      return Promise.resolve(
         localStorage.getItem(this.generateLocalStorageKey(dataConnectionId)) !== null
      );
   }

   private setSync(dataConnectionId: number, credential: DataCredential) {
      try {
         localStorage.setItem(
            this.generateLocalStorageKey(dataConnectionId),
            JSON.stringify(formatCredentialForStorage(credential))
         );
      } catch (err: unknown) {
         return false;
      }

      return true;
   }

   set(dataConnectionId: number, credential: DataCredential) {
      return Promise.resolve(this.setSync(dataConnectionId, credential));
   }
}

@injectable()
export class DesktopLocalCredentialService extends BaseLocalCredentialService {
   toKeyParams(dataConnectionId: number) {
      const personId = sessionStorage.getItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);

      if (personId === null) {
         throw new Error('Bad implementation');
      }

      return {
         dataConnectionId,
         personId: parseInt(personId, 10),
      };
   }

   delete(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      return globalThis.runql.credentialStore.delete(this.toKeyParams(dataConnectionId));
   }

   get(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      return globalThis.runql.credentialStore.get(this.toKeyParams(dataConnectionId));
   }

   // Override options so defaultRqlEncryptionVersion is always 'none'.
   getMap(
      dataConnectionIds: number | number[],
      notUsed?: { defaultRqlEncryptionVersion?: number }
   ) {
      return super.getMap(dataConnectionIds, {
         defaultRqlEncryptionVersion: RQL_ENCRYPTION_VERSIONS.none,
      });
   }

   has(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      return globalThis.runql.credentialStore.has(this.toKeyParams(dataConnectionId));
   }

   set(dataConnectionId: number, credential: DataCredential) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      return globalThis.runql.credentialStore.set(
         this.toKeyParams(dataConnectionId),
         formatCredentialForStorage(credential)
      );
   }
}
