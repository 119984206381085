import { Base } from './Base';
import { DataConnection } from './DataConnection';
import { Person } from './Person';
import { Workspace } from './Workspace';

export enum CollaboratorRole {
   ADMIN = 'admin',
   EDITOR = 'editor',
   READER = 'reader',
   REVIEWER = 'reviewer',
}

export const CollaboratorRoleOrder = {
   [CollaboratorRole.ADMIN]: 1,
   [CollaboratorRole.REVIEWER]: 2,
   [CollaboratorRole.EDITOR]: 3,
   [CollaboratorRole.READER]: 4,
};

export interface WorkspaceCollaborator extends Base {
   dataConnection?: DataConnection;
   dataConnectionId?: number;
   joinedDate?: Date;
   person?: Person;
   personId?: number;
   role?: CollaboratorRole;
   workspace?: Workspace;
   workspaceId?: number;
}
