import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { FaDatabase } from 'react-icons/fa';
import { z } from 'zod';
import LoadingError from '../../../components/UI/LoadingError';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { useGetDataConnectionSchemaTree, useUpdateSchemaVisibilityMutator } from '../../../hooks';
import { getErrorMessage, handleError } from '../../../utilities';

const schemaVisibleObj = z.object({ key: z.string(), schema: z.string(), visible: z.boolean() });

const catalogVisibleObj = z.object({
   key: z.string(),
   catalog: z.string().optional(),
   schemas: z.array(schemaVisibleObj),
});

const connectionVisibility = z.object({
   connectionVisibility: z.array(catalogVisibleObj),
});

export type ConnectionVisibility = z.infer<typeof connectionVisibility>;
export const schemaSelectFormId = 'schemaSelectForm';

function SchemaSelectForm({
   dataConnectionId,
   onSaveStateChange,
   onSubmit,
}: {
   dataConnectionId: number;
   onSaveStateChange?: (state: 'clean' | 'dirty' | 'saving') => void;
   onSubmit?: (data: ConnectionVisibility) => void;
}) {
   // register form
   const {
      handleSubmit,
      register,
      formState: { errors, dirtyFields },
      setValue,
      reset,
      watch,
      control,
   } = useForm<ConnectionVisibility>({
      resolver: zodResolver(connectionVisibility),
      defaultValues: {
         connectionVisibility: [{ catalog: '', schemas: [{ schema: '', visible: false }] }],
      },
   });

   // field array
   const { fields: catalogs } = useFieldArray({
      control,
      name: 'connectionVisibility',
   });

   // State variables
   const [currentState, setCurrentState] = useState<'clean' | 'dirty' | 'saving'>('clean');

   const setCurrentFormState = (state: 'clean' | 'dirty' | 'saving') => {
      if (state !== currentState) {
         setCurrentState(state);
         onSaveStateChange?.(state);
      }
   };

   // Queries
   const treeSchemaQuery = useGetDataConnectionSchemaTree({ dataConnectionId, viewAll: true });
   const updateVisibilityMutator = useUpdateSchemaVisibilityMutator();

   // Effects
   useEffect(() => {
      if (treeSchemaQuery.data) {
         const formData: ConnectionVisibility = {
            connectionVisibility: treeSchemaQuery.data.map((catalog) => ({
               key: catalog.key,
               catalog: catalog.type === 'catalogNode' ? catalog.label : undefined,
               schemas: catalog.children.map((schema) => ({
                  key: schema.key,
                  schema: schema.label,
                  visible: schema.visible,
               })),
            })),
         };
         reset(formData);
      }
   }, [treeSchemaQuery.data, reset]);

   useEffect(() => {
      if ((dirtyFields.connectionVisibility?.length ?? 0) > 0 && currentState !== 'dirty') {
         setCurrentState('dirty');
         onSaveStateChange?.('dirty');
      }
   }, [currentState, dirtyFields.connectionVisibility?.length, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = async (data: ConnectionVisibility) => {
      try {
         setCurrentFormState('saving');
         const dirtyConnections = data.connectionVisibility
            .map((catalog, catalogIndex) => {
               const dirtySchemas = catalog.schemas.filter((schema, schemaIndex) => {
                  return (
                     dirtyFields.connectionVisibility?.[catalogIndex]?.schemas?.[schemaIndex] !==
                     undefined
                  );
               });
               return {
                  catalog: catalog.catalog,
                  schemas: dirtySchemas,
               };
            })
            .flatMap((catalog) => {
               return catalog.schemas.map((schema) => ({
                  catalog: catalog.catalog,
                  schema: schema.schema,
                  visible: schema.visible,
               }));
            });
         await updateVisibilityMutator.mutateAsync({
            dataConnectionId,
            body: { schemas: dirtyConnections },
         });
         reset({}, { keepValues: true });
         setCurrentFormState('clean');
      } catch (error) {
         handleError(getErrorMessage(error));
      }
      onSubmit?.(data);
   };

   // Render
   if (treeSchemaQuery.isLoading) {
      return <LoadingSpinner />;
   }
   if (treeSchemaQuery.isError) {
      return <LoadingError message={getErrorMessage(treeSchemaQuery.error)} />;
   }

   return (
      <Form id={schemaSelectFormId} onSubmit={handleSubmit(handleOnSubmit)}>
         <div className="fs-14p fw-500">
            Select the schemas you want attached to this connection
         </div>
         {catalogs
            .filter((c) => c.key)
            .map((catalog, catalogIndex) => (
               <Card className="border-0 mt-1 mb-1" key={catalog.key}>
                  {catalog.catalog && (
                     <label>
                        <input
                           checked={watch(`connectionVisibility.${catalogIndex}.schemas`).some(
                              (schema) => {
                                 return schema.visible === true;
                              }
                           )}
                           className="form-check-input"
                           onChange={(e) => {
                              setValue(
                                 `connectionVisibility.${catalogIndex}.schemas`,
                                 catalog.schemas.map((schema) => ({
                                    ...schema,
                                    visible: e.target.checked,
                                 })),
                                 { shouldDirty: true }
                              );
                              setCurrentFormState('dirty');
                           }}
                           type="checkbox"
                           value={catalog.catalog}
                        />
                        &nbsp;
                        <span className="text-muted">
                           <FaDatabase />
                        </span>
                        &nbsp;
                        <span className="form-label fw-normal">{catalog.catalog}</span>
                     </label>
                  )}

                  <div>
                     {catalog.schemas.map((schema, schemaIndex) => (
                        <Form.Group className={catalog.catalog ? 'ms-4' : ''} key={schema.key}>
                           <label>
                              <input
                                 {...register(
                                    `connectionVisibility.${catalogIndex}.schemas.${schemaIndex}.visible`
                                 )}
                                 className="form-check-input"
                                 type="checkbox"
                              />
                              &nbsp;
                              <span className="text-muted">
                                 <FaDatabase />
                              </span>
                              &nbsp;{schema.schema}
                           </label>
                           <Form.Control.Feedback type="invalid">
                              {
                                 errors.connectionVisibility?.[catalogIndex]?.schemas?.[schemaIndex]
                                    ?.visible?.message
                              }
                           </Form.Control.Feedback>
                        </Form.Group>
                     ))}
                  </div>
               </Card>
            ))}
      </Form>
   );
}

export default SchemaSelectForm;
