import {
   SigmaContainer,
   useLoadGraph,
   useRegisterEvents,
   useSetSettings,
   useSigma,
} from '@react-sigma/core';
import { useLayoutCircular } from '@react-sigma/layout-circular';
import { useWorkerLayoutForceAtlas2 } from '@react-sigma/layout-forceatlas2';
import { MultiDirectedGraph } from 'graphology';
import { Attributes } from 'graphology-types';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SigmaGraph } from '../utilities';

interface ResultGraphProps {
   clickedNode: string | null;
   graphData: SigmaGraph;
   hoveredNode: string | null;
}

const ResultsGraph = ({ graphData, hoveredNode, clickedNode }: ResultGraphProps): JSX.Element => {
   const loadGraph = useLoadGraph();
   const { assign: assignCircular } = useLayoutCircular();
   const setSettings = useSetSettings();
   const sigma = useSigma();

   useEffect(() => {
      const graph = new MultiDirectedGraph();

      for (const node of graphData.nodes) {
         graph.addNode(node.key, node.attributes);
      }
      for (const edge of graphData.edges) {
         graph.addEdge(edge.source, edge.target, edge.attributes);
      }

      loadGraph(graph);
      assignCircular();
   }, [loadGraph, assignCircular, graphData]);

   useEffect(() => {
      setSettings({
         nodeReducer: (node, data) => {
            const graph = sigma.getGraph();
            const newData: Attributes = { ...data, highlighted: data.highlighted || false };

            if (hoveredNode) {
               if (node === hoveredNode || graph.neighbors(hoveredNode).includes(node)) {
                  newData.highlighted = true;
               } else {
                  newData.color = '#E2E2E2';
                  newData.highlighted = false;
               }
            }

            if (clickedNode === node) {
               newData.highlighted = true;
               newData.size = 12;
            }

            return newData;
         },
         edgeReducer: (edge, data) => {
            const graph = sigma.getGraph();
            const newData = { ...data, hidden: false };

            if (hoveredNode && !graph.extremities(edge).includes(hoveredNode)) {
               newData.hidden = true;
            }

            return newData;
         },
      });
   }, [hoveredNode, setSettings, clickedNode, sigma]);

   return <></>;
};

interface GraphEventsControllerProps {
   setClickedNode: (node: string | null) => void;
   setHoveredNode: (node: string | null) => void;
}

const GraphEventsController = ({
   setHoveredNode,
   setClickedNode,
}: GraphEventsControllerProps): JSX.Element => {
   const registerEvents = useRegisterEvents();

   useEffect(() => {
      // Register events
      registerEvents({
         enterNode: (event: any) => setHoveredNode(event.node),
         leaveNode: () => setHoveredNode(null),
         clickNode: (event: any) => setClickedNode(event.node),
      });
   }, [registerEvents, setHoveredNode, setClickedNode]);

   return <></>;
};

const LayoutForceAtlas2Controller = (): JSX.Element => {
   const { start, kill } = useWorkerLayoutForceAtlas2({});

   useEffect(() => {
      // start FA2
      start();
      return () => {
         // Kill FA2 on unmount
         kill();
      };
   }, [start, kill]);

   // only let the layout run for a few seconds, any longer doesn't help but leaving it running can lead to jitter
   setTimeout(() => {
      kill();
   }, 1000);

   return <></>;
};

interface GraphDetailsPanelProps {
   clickedNode: string | null;
}

const GraphDetailsPanel = ({ clickedNode }: GraphDetailsPanelProps): JSX.Element => {
   const sigma = useSigma();
   const graph = sigma.getGraph();
   const [nodeProperties, setNodeProperties] = useState<[string, any][]>();
   const [nodeName, setNodeName] = useState('');

   useEffect(() => {
      if (clickedNode) {
         setNodeName(graph.getNodeAttribute(clickedNode, 'label'));
         setNodeProperties(graph.getNodeAttribute(clickedNode, 'properties'));
      }
   }, [clickedNode, graph]);

   return (
      <>
         {clickedNode ? (
            <div className="card neo4jCard">
               <>
                  <Row>
                     <Col>Label</Col>
                     <Col>{nodeName}</Col>
                  </Row>
                  <Row>
                     <hr />
                     <Col>ID</Col>
                     <Col>{clickedNode}</Col>
                  </Row>
                  {nodeProperties?.map((prop) => (
                     <Row>
                        <hr />
                        <Col>{prop[0]}</Col>
                        <Col>
                           {typeof prop[1] === 'object'
                              ? JSON.stringify(prop[1])
                              : prop[1].toString()}
                        </Col>
                     </Row>
                  ))}
               </>
            </div>
         ) : (
            <></>
         )}
      </>
   );
};

interface QueryResultsGraphProps {
   graphData: SigmaGraph;
}

export const QueryResultsGraph = ({ graphData }: QueryResultsGraphProps): JSX.Element => {
   const [hoveredNode, setHoveredNode] = useState<string | null>(null);
   const [clickedNode, setClickedNode] = useState<string | null>(null);

   return (
      <div className="card">
         <SigmaContainer
            graph={MultiDirectedGraph}
            settings={{ zIndex: true }}
            style={{ height: '800px', backgroundColor: 'transparent' }}
         >
            <GraphEventsController
               setClickedNode={setClickedNode}
               setHoveredNode={setHoveredNode}
            />
            <ResultsGraph
               clickedNode={clickedNode}
               graphData={graphData}
               hoveredNode={hoveredNode}
            />
            <GraphDetailsPanel clickedNode={clickedNode} />
            <LayoutForceAtlas2Controller />
         </SigmaContainer>
      </div>
   );
};
