import { OrgRolePermission } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';
import { inject, injectable } from 'inversify';

const PATH = '/v1/orgRolePermission';

export interface ListOptionsOrgRolePermission {}
export interface GetOptionsOrgRolePermission {}

@injectable()
export class OrgRolePermissionService implements ApiServiceInterface<OrgRolePermission> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   delete(id: string | number): Promise<null> {
      throw new Error('Method not implemented.');
   }

   get(id: string | number): Promise<OrgRolePermission | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params: Record<string, string>): Promise<OrgRolePermission[]> {
      const result = await this.apiService.get<OrgRolePermission[]>(PATH, params);
      if (!result) {
         return [];
      }

      return result;
   }

   listOptions(options?: ListOptionsOrgRolePermission): Promise<OrgRolePermission[]> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v;
            }
         }
      }
      return this.list(params);
   }

   async patch(
      id: string | number,
      body: OrgRolePermission,
      params?: Record<string, string> | undefined
   ): Promise<OrgRolePermission | undefined> {
      const result = await this.apiService.patch<OrgRolePermission>(`${PATH}`, body, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   post(
      body: OrgRolePermission,
      params?: Record<string, string> | undefined
   ): Promise<OrgRolePermission | undefined> {
      throw new Error('Method not implemented.');
   }

   put(
      body: OrgRolePermission,
      params?: Record<string, string> | undefined
   ): Promise<OrgRolePermission | undefined> {
      throw new Error('Method not implemented.');
   }
}
