export const TYPES = {
   apiService: Symbol.for('ApiService'),
   dataConnectionService: Symbol.for('DataConnectionService'),
   dataCredentialService: Symbol.for('DatatConnectionInstanceService'),
   exploreTabService: Symbol.for('ExploreTabService'),
   featureService: Symbol.for('FeatureService'),
   inviteService: Symbol.for('inviteService'),
   orgRolePermissionService: Symbol.for('OrgRolePermissionService'),
   orgService: Symbol.for('OrgService'),
   personService: Symbol.for('PersonService'),
   queryCommentService: Symbol.for('QueryCommentService'),
   querylogService: Symbol.for('QueryLogService'),
   querySavedCustomTagService: Symbol.for('QuerySavedCustomTagService'),
   queryService: Symbol.for('QueryService'),
   schemaCacheService: Symbol.for('SchemaCacheService'),
   schemaCacheTrackingService: Symbol.for('SchemaCacheTrackingService'),
   searchService: Symbol.for('SearchService'),
   snippetService: Symbol.for('SnippetService'),
   askService: Symbol.for('AskService'),
   statsService: Symbol.for('StatsService'),
   subscriptionService: Symbol.for('SubscriptionService'),
   workspaceCollaboratorService: Symbol.for('WorkspaceCollaboratorService'),
   workspaceDataConnectionService: Symbol.for('WorkspaceDataConnectionService'),
   workspaceLogService: Symbol.for('WorkspaceLogService'),
   workspaceService: Symbol.for('WorkspaceService'),
   walkthroughService: Symbol.for('WalkthroughService'),
   localCredentialService: Symbol.for('LocalCredentialService'),
   desktopQueryService: Symbol.for('DesktopQueryService'),
};
