import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { Navigate, useParams, useLocation } from 'react-router-dom';

import { LoadingSpinner, Page } from '../../components';
import { Workspace, WorkspaceStatus } from '../../entities';
import { QueryKey } from '../../enums';
import { WorkspaceContextProvider, CurrentStep } from '../../hooks';
import { WorkspaceService } from '../../services';
import { TYPES } from '../../types';
import { handleError } from '../../utilities';
import Explore from './Explore/Explore';
import Error50x from '../Error50x';

export enum historyTabs {
   QueryLog = 'query-log',
   Stats = 'stats',
   WorkspaceLog = 'workspace-log',
}

export type ResultTableFilterType = Record<number, Record<number, Record<string, string>>>;

export const WorkspacePage = (): JSX.Element => {
   const location = useLocation();
   const workspaceService = useInjection<WorkspaceService>(TYPES.workspaceService);
   const params = useParams();
   const workspaceId = Number(params.workspaceId);
   const [currentStep, setCurrentStep] = useState<CurrentStep>();
   const [resultTableFilters, setResultTableFilters] = useState<ResultTableFilterType>({});

   useEffect(() => {
      if (location.state) {
         setResultTableFilters((prev) => {
            return location.state.filters ?? {};
         });
      }
   }, [location.state]);

   const workspaceFilters = {
      savedQueryCount: true,
      includeOrgPeople: true,
      connectionDetails: true,
      includeCollaborators: true,
   };
   const workspaceGetKey = [QueryKey.Workspace, 'get', workspaceId, workspaceFilters];
   const workspaceQuery = useQuery<Workspace | undefined>(
      workspaceGetKey,
      () =>
         Number.isNaN(workspaceId)
            ? undefined
            : workspaceService.getOptions(workspaceId, workspaceFilters),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );

   if (workspaceId === undefined || Number.isNaN(workspaceId)) {
      return <Navigate replace to="/" />;
   } else if (workspaceQuery.isLoading) {
      return <LoadingSpinner />;
   } else if (
      WorkspaceStatus.DELETED ||
      workspaceQuery.isError ||
      workspaceQuery.data === undefined
   ) {
      return <Error50x />;
   } else {
      return (
         <WorkspaceContextProvider
            value={{
               currentStep,
               setCurrentStep,
               resultTableFilters,
               setResultTableFilters,
               workspace: workspaceQuery.data,
            }}
         >
            <Page bodyPadding={false}>
               <Explore workspaceId={workspaceId} />
            </Page>
         </WorkspaceContextProvider>
      );
   }
};
export default WorkspacePage;
