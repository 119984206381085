import { Component, ReactNode, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
   children: ReactNode;
   message?: string;
   title?: string;
}

interface ErrorBoundaryState {
   hasError: boolean;
}

const ErrorFallback = ({ message, title }: { message: string; title: string }) => {
   return (
      <div className="error-boundary">
         <span style={{ fontSize: '18px', alignSelf: 'center' }}>{title}</span>
         <span style={{ fontSize: '14px', alignSelf: 'center' }}>{message}</span>
      </div>
   );
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
   constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError(_: Error): ErrorBoundaryState {
      // Update state to display the fallback UI
      return { hasError: true };
   }

   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // Log the error to an error reporting service or console
      console.error('Error caught by ErrorBoundary:', error, errorInfo);
   }

   render() {
      if (this.state.hasError) {
         // Render a fallback UI
         return (
            <ErrorFallback
               message={
                  this.props.message ||
                  'Please try reloading the page. If this issue persists, contact us.'
               }
               title={this.props.title || 'Something went wrong.'}
            />
         );
      }

      return this.props.children;
   }
}

export default ErrorBoundary;
