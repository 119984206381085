import { useState, useMemo } from 'react';
import { Button, Modal, Collapse, Spinner, Form } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useRunSystemQuery } from '../hooks';
import CodeViewer from '../components/UI/CodeViewer';
import { queryBuilderFactory } from '../utilities/QueryBuilder';
import { QueryKey } from '../enums';
import { handleError } from '../utilities/error';
import { DBMS } from '../enums/dbms';

import type { TreeNode } from '../components/UI/TreeView';

export type DatabaseNode = TreeNode & {
   catalogName?: string;
   dataConnectionId: number;
   databaseName: string;
   type: 'databaseNode';
};

export const isDatabaseNode = (node: TreeNode): node is DatabaseNode => {
   return (
      node.type === 'databaseNode' &&
      typeof node.dataConnectionId === 'number' &&
      typeof node.databaseName === 'string'
   );
};

export const canCreateTable = ({ dbms }: { dbms: DBMS }) => {
   switch (dbms) {
      case DBMS.Big_Query:
      case DBMS.MSSQL:
      case DBMS.MySQL:
      case DBMS.Postgres:
      case DBMS.Redshift:
      case DBMS.Snowflake:
      case DBMS.Databricks:
      case DBMS.Oracle:
      case DBMS.Trino:
         return true;
      default:
         return false;
   }
};

const CreateTableModalBody = ({
   databaseNode,
   onHide,
   workspaceId,
}: {
   databaseNode: DatabaseNode;
   onHide: (result?: { tableName: string }) => void;
   workspaceId: number;
}): JSX.Element => {
   const [showQuery, setShowQuery] = useState(false);
   const [tableName, setTableName] = useState('new_table');

   const { run, isRunning } = useRunSystemQuery();
   const queryClient = useQueryClient();

   const qb = useMemo(() => queryBuilderFactory(databaseNode.dbms), [databaseNode.dbms]);

   const query = useMemo(
      () =>
         qb.createTable({
            schema: databaseNode.databaseName,
            table: tableName,
            catalog: databaseNode.catalogName,
         }),
      [databaseNode.databaseName, databaseNode.catalogName, qb, tableName]
   );

   const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTableName(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
         const result = { tableName };

         await run({
            dataConnection: databaseNode.dataConnectionId,
            query,
            workspaceId,
            updateSchema: true,
            catalog: databaseNode.catalogName,
            schema: databaseNode.databaseName,
            table: tableName,
         });

         await queryClient.invalidateQueries([QueryKey.WorkspaceSchemaTree]);

         toast.success('Table created successfully');
         onHide(result);
      } catch (e) {
         handleError(e);
      }
   };

   return (
      <>
         <Modal.Header closeButton>
            <Modal.Title className="fs-14p">
               Create New Table in Database '{databaseNode.databaseName}'?
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form id="createTableForm" onSubmit={handleSubmit}>
               <div className="row">
                  <div className="col-12 fs-14p">
                     <Form.Group controlId="tableNameInput">
                        <Form.Label>Table Name</Form.Label>
                        <Form.Control
                           onChange={handleChangeName}
                           required
                           type="text"
                           value={tableName}
                        />
                     </Form.Group>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 d-flex justify-content-end mt-3">
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => {
                           setShowQuery(!showQuery);
                        }}
                     >
                        {showQuery ? `Hide` : `Show`} Query
                     </Button>
                     <Button
                        className="btn btn-sm btn-secondary me-1"
                        disabled={isRunning}
                        onClick={() => {
                           onHide();
                        }}
                     >
                        Cancel
                     </Button>
                     <Button
                        className="btn btn-sm btn-primary"
                        disabled={!tableName || isRunning}
                        type="submit"
                     >
                        {isRunning ? (
                           <>
                              <Spinner
                                 animation="border"
                                 aria-hidden="true"
                                 as="span"
                                 role="status"
                                 size="sm"
                              />
                              <span className="visually-hidden">Loading...</span>&nbsp;
                           </>
                        ) : null}
                        Confirm
                     </Button>
                  </div>
               </div>
            </Form>
            <div className="row">
               <div className="col-12">
                  <Collapse in={showQuery}>
                     <div className="card query-card border-1 mt-4 p-4 fs-14p">
                        <CodeViewer query={query} />
                     </div>
                  </Collapse>
               </div>
            </div>
         </Modal.Body>
      </>
   );
};

export const CreateTableModal = ({
   databaseNode,
   onHide,
   workspaceId,
}: {
   databaseNode: DatabaseNode | null;
   onHide: (result?: { tableName: string }) => void;
   workspaceId: number;
}) => {
   return (
      <Modal
         onHide={() => {
            onHide();
         }}
         show={databaseNode !== null}
      >
         {databaseNode !== null ? (
            <CreateTableModalBody
               databaseNode={databaseNode}
               onHide={onHide}
               workspaceId={workspaceId}
            />
         ) : null}
      </Modal>
   );
};

export default CreateTableModal;
