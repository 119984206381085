import { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import LoadingError from './UI/LoadingError';
import LoadingSpinner from './UI/LoadingSpinner';
import TreeView from './UI/TreeView';
import ManageWorkspaceConnections from './ManageWorkspaceConnections';
import { useGetSchemaTree, useCurrentStep } from '../hooks';
import { StepType, SchemaCache } from '../entities';
import CreateTableModal from './CreateTableModal';
import DeleteTableModal from './DeleteTableModal';
import { QueryKey } from '../enums';
import { TableEditTab } from '../pages/Workspace/Explore/Table';

import type { DatabaseNode } from './CreateTableModal';
import type { TableNode } from './DeleteTableModal';
import { SearchInput } from './UI';

export const Navigator = ({ workspaceId }: { workspaceId: number }): JSX.Element => {
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const [showManageWorkspaceConnectionsModal, setShowManageWorkspaceConnectionsModal] = useState<
      'new-connection' | undefined
   >(undefined);
   const [databaseNodeToCreateTableFor, setDatabaseNodeToCreateTableFor] =
      useState<DatabaseNode | null>(null);
   const [tableNodeToDelete, setTableNodeToDelete] = useState<TableNode | null>(null);
   const [searchText, setSearchText] = useState('');
   const workspaceConnectionQuery = useGetSchemaTree({ workspaceId });
   const currentStep = useCurrentStep();

   return (
      <>
         <div>
            <div className="">
               {workspaceConnectionQuery.isLoading ? (
                  <LoadingSpinner />
               ) : workspaceConnectionQuery.isError ? (
                  <LoadingError />
               ) : (
                  workspaceConnectionQuery.data && (
                     <Stack gap={2}>
                        <SearchInput
                           entityName="schemas"
                           onTextChanged={(newText: string) => setSearchText(newText.toLowerCase())}
                        />
                        <TreeView
                           catalogNames={currentStep?.queryStep.type === StepType.FEDERATED}
                           expandLevels={[
                              'connectionNode',
                              'databaseNode',
                              'tablesFolder',
                              'catalogNode',
                           ]}
                           searchText={searchText}
                           showCreateTableModal={(databaseNode: DatabaseNode) => {
                              setDatabaseNodeToCreateTableFor(databaseNode);
                           }}
                           showDeleteTableModal={(tableNode: TableNode) => {
                              setTableNodeToDelete(tableNode);
                           }}
                           showQuickSelect={true}
                           treeData={workspaceConnectionQuery.data}
                        />
                     </Stack>
                  )
               )}
            </div>
            {!workspaceConnectionQuery.data?.length && (
               <Button
                  className="fs-10p my-3 px-1 py-0 opacity-75"
                  onClick={() => setShowManageWorkspaceConnectionsModal('new-connection')}
                  size="sm"
                  variant="secondary"
               >
                  Attach Data Source
               </Button>
            )}
            <ManageWorkspaceConnections
               onClose={() => setShowManageWorkspaceConnectionsModal(undefined)}
               show={showManageWorkspaceConnectionsModal === 'new-connection'}
               workspaceId={workspaceId}
            />
         </div>
         <CreateTableModal
            databaseNode={databaseNodeToCreateTableFor}
            onHide={async (result) => {
               const { dataConnectionId, databaseName } = databaseNodeToCreateTableFor ?? {};

               setDatabaseNodeToCreateTableFor(null);

               if (result && dataConnectionId && databaseName) {
                  let data: SchemaCache[] | undefined;
                  try {
                     await queryClient.refetchQueries({
                        queryKey: [QueryKey.SchemaForDataSource, dataConnectionId],
                        exact: true,
                     });

                     data = queryClient.getQueryData<SchemaCache[]>([
                        QueryKey.SchemaForDataSource,
                        dataConnectionId,
                     ]);
                  } catch (err) {}

                  const match = data?.find(
                     (val) =>
                        val.type === 'table' &&
                        val.schemaName === databaseName &&
                        val.tableName === result.tableName
                  );

                  if (match) {
                     const path = `/workspaces/${workspaceId}/table/${match.id}?subTab=${TableEditTab.COLUMNS}`;
                     navigate(path);
                  }
               }
            }}
            workspaceId={workspaceId}
         />
         <DeleteTableModal
            onHide={() => {
               setTableNodeToDelete(null);
            }}
            tableNode={tableNodeToDelete}
            workspaceId={workspaceId}
         />
      </>
   );
};

export default Navigator;
