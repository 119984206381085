import React from 'react';
import { Container } from 'react-bootstrap';
import { IconHeart } from '../utilities';

const Footer = (): JSX.Element => {
   return (
      <footer className="footer bg-secondary">
         <Container className="p-3" fluid={true}>
            <p className="nav d-block fs-sm">
               &copy; All rights reserved{' '}
               <a
                  className="nav-link d-inline-block p-0"
                  href="https://runql.com"
                  rel="noopener noreferrer"
                  style={{ fontFamily: 'JetBrains Mono' }}
                  target="_blank"
               >
                  runQL
               </a>
               . Made with <IconHeart className={'text-danger'} type={'solid'} /> in Waterloo,
               Canada.
            </p>
         </Container>
      </footer>
   );
};

export default Footer;
