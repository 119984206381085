import { useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '../enums';
import { GetTableMetaResponse, SchemaCacheService } from '../services';
import { queryBuilderFactory } from '../utilities';
import { TYPES } from '../types';
import { useRunSystemQuery } from '.';

import type { QueryReturn } from '../interfaces';
import type { SchemaCache } from '../entities';

export const useFetchTableMetaQuery = ({
   callbacks,
   tableSchemaId,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: GetTableMetaResponse | undefined) => void;
   };
   tableSchemaId: number | undefined;
}) => {
   const schemaCacheService = useInjection<SchemaCacheService>(TYPES.schemaCacheService);
   const queryClient = useQueryClient();

   const refresh = () => {
      return queryClient.invalidateQueries([QueryKey.MetaForTableId, tableSchemaId]);
   };

   const tableMetaQuery = useQuery<GetTableMetaResponse | undefined>(
      [QueryKey.MetaForTableId, tableSchemaId],
      async () => {
         if (!tableSchemaId) {
            return undefined;
         }

         return schemaCacheService.getTableMeta(tableSchemaId);
      },
      {
         enabled: !!tableSchemaId,
         keepPreviousData: false,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess(data) {
            return callbacks?.onSuccess?.(data);
         },
         onError: (err) => {
            return callbacks?.onError?.(err);
         },
      }
   );

   return { tableMetaQuery, refresh };
};

export const useFetchTableContentQuery = ({
   tableSchema,
   workspaceId,
}: {
   tableSchema: SchemaCache;
   workspaceId: number;
}) => {
   if (!tableSchema.dataConnection.dbms) {
      throw new Error('Bad implementation');
   }
   const queryClient = useQueryClient();

   // TODO: If we update the behaviour of the DataEditTab to invalidate the ExploreTab, then we
   // could use the `exploreTab.tableSchemaId` as the 2nd part of the query key.
   const QUERY_KEY = [
      QueryKey.TableContentForTableSchemaId,
      `${tableSchema.dataConnection.id}-${tableSchema.catalogName ?? '__NO_CATALOG__'}-${
         tableSchema.schemaName
      }-${tableSchema.tableName}`,
   ];

   const invalidate = async () => {
      await queryClient.invalidateQueries(QUERY_KEY);
   };

   const qb = useMemo(
      () => queryBuilderFactory(tableSchema.dataConnection.dbms!),
      [tableSchema.dataConnection.dbms]
   );
   const query = useMemo(
      () =>
         qb.fetchTableContent({
            schema: tableSchema.schemaName,
            table: tableSchema.tableName,
            catalog: tableSchema.catalogName,
         }),
      [qb, tableSchema]
   );

   const { run } = useRunSystemQuery();

   const result = useQuery<QueryReturn>(
      QUERY_KEY,
      () =>
         run({
            dataConnection: tableSchema.dataConnection,
            query,
            workspaceId,
         }),
      {
         refetchOnWindowFocus: false,
         retry: false,
      }
   );

   return { result, invalidate };
};
