import { Parser, AST, Option } from 'node-sql-parser';
import { assertNever } from '.';
import { DBMS } from '../enums';

export const queryIsDangerous = (query: string, dbms?: DBMS) => {
   const parserOptions = getParserOptions(dbms);
   if (!parserOptions) {
      return false;
   }
   const parser = new Parser();
   try {
      const astList = parser.astify(query, parserOptions);

      if (Array.isArray(astList)) {
         for (const ast of astList) {
            if (astIsDangerous(ast)) {
               return true;
            }
         }
      } else {
         return astIsDangerous(astList);
      }
   } catch (err) {
      console.warn('unable to test query for danger');
      return false;
   }
   return false;
};

const astIsDangerous = (ast: AST) => {
   switch (ast.type) {
      case 'alter':
         return true;
      case 'create':
         return true;
      case 'delete':
         return true;
      case 'insert':
         return true;
      case 'replace':
         return true;
      case 'select':
         return false;
      case 'update':
         return true;
      case 'drop':
         return true;
      case 'use':
         return false;
      default:
         assertNever(ast);
   }
};

const getParserOptions = (dbms: DBMS | undefined): Option | undefined => {
   //node-sql-parser possible databases: postgresql, db2, snowflake, noql, transactsql, mysql, mariadb, bigquery
   switch (dbms) {
      case DBMS.MSSQL:
         return { database: 'transactsql' };
      case DBMS.CHAT_GPT:
      case DBMS.Hugging_Face:
      case DBMS.Neo4j:
      case DBMS.Python:
      case undefined:
         return undefined;
      case DBMS.MySQL:
      case DBMS.Databricks:
      case DBMS.Trino:
      case DBMS.Oracle:
      case DBMS.MongoDB:
      case DBMS.Federated:
         return { database: 'mysql' };
      case DBMS.Postgres:
      case DBMS.Redshift:
         return { database: 'postgresql' };
      case DBMS.Snowflake:
         return { database: 'snowflake' };
      case DBMS.Big_Query:
         return { database: 'bigquery' };
      default:
         assertNever(dbms);
   }
};
