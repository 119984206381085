import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { BiChevronRight } from 'react-icons/bi';
import { useQueryClient } from 'react-query';

import LoadingError from './/UI/LoadingError';
import LoadingSpinner from './/UI/LoadingSpinner';
import { WorkspaceDataConnection } from '../entities';
import {
   useDeleteWorkspaceConnectionMutation,
   useListDataConnectionsQuery,
   useListWorkspaceConnectionsQuery,
   useNewWorkspaceConnectionMutation,
} from '../hooks/entities';
import {
   createSelectOptionWorkspaceConnection,
   SelectOptionWorkspaceConnection,
} from '../interfaces';
import { getErrorMessage, handleError, notUndefined } from '../utilities';
import { QueryKey } from '../enums';

const ManageWorkspaceConnections = ({
   show,
   onClose,
   workspaceId,
}: {
   onClose: () => void;
   show: boolean;
   workspaceId: number;
}): JSX.Element => {
   // Database hooks
   const queryClient = useQueryClient();
   const workspaceConnections = useListWorkspaceConnectionsQuery({
      workspaceId: workspaceId,
      includeConnectionDetails: true,
   });
   const dataConnections = useListDataConnectionsQuery(
      {},
      {},
      workspaceConnections.isSuccess //This query relies on workspaceConnection
   );
   const deleteWorkspaceConnectionMutator = useDeleteWorkspaceConnectionMutation();
   const createWorkspaceConnectionMutator = useNewWorkspaceConnectionMutation();

   const selectOptions = useMemo(
      () =>
         dataConnections.data?.map?.((dataConnection) =>
            createSelectOptionWorkspaceConnection({
               dataConnectionId: notUndefined(dataConnection.id),
               workspaceId: workspaceId,
               dataConnection: dataConnection,
            })
         ) ?? [],
      [workspaceId, dataConnections.data]
   );

   const [newConnections, setNewConnections] = useState<
      MultiValue<SelectOptionWorkspaceConnection>
   >([]);
   useEffect(() => {
      const selectedOptions = selectOptions.filter((option) =>
         workspaceConnections?.data?.find(
            (connection) =>
               connection.dataConnectionId === option.workspaceConnection?.dataConnectionId
         )
      );
      setNewConnections(selectedOptions);
   }, [selectOptions, workspaceConnections?.data]);

   // Page functions
   const saveConnections = async () => {
      try {
         const connectionsToAdd = newConnections
            .filter(
               (connection) =>
                  connection.workspaceConnection &&
                  !workspaceConnections.data?.find(
                     (workspaceConnection) =>
                        workspaceConnection.dataConnectionId ===
                        connection.workspaceConnection?.dataConnectionId
                  )
            )
            .map((connection) => connection.workspaceConnection as WorkspaceDataConnection);
         const connectionsToRemove =
            workspaceConnections.data?.filter(
               (workspaceConnection) =>
                  !newConnections.find(
                     (connection) =>
                        connection.workspaceConnection?.dataConnectionId ===
                        workspaceConnection.dataConnectionId
                  )
            ) ?? [];
         await Promise.all([
            ...connectionsToAdd.map((connection) =>
               createWorkspaceConnectionMutator.mutateAsync(connection)
            ),
            ...connectionsToRemove.map((connection) =>
               deleteWorkspaceConnectionMutator.mutateAsync(connection)
            ),
         ]);
         await queryClient.invalidateQueries([QueryKey.WorkspaceSchemaTree]);
         onClose();
      } catch (err) {
         handleError(err);
      }
   };

   // Render Page
   if (workspaceConnections.isLoading || dataConnections.isLoading) {
      return show ? <LoadingSpinner /> : <></>;
   }

   if (workspaceConnections.isError) {
      return <LoadingError message={getErrorMessage(workspaceConnections.error)} />;
   }

   if (dataConnections.isError) {
      return <LoadingError message={getErrorMessage(dataConnections.error)} />;
   }

   return (
      <Modal centered={true} onHide={onClose} show={show}>
         <Modal.Header className="border-0 mb-0 pb-0" closeButton={true}>
            Workspace Data Sources
         </Modal.Header>
         <Modal.Body>
            <Select
               className="runql-select"
               classNamePrefix="runql-select"
               isMulti
               noOptionsMessage={() => 'All organization databases added'}
               onChange={(newValue) => {
                  setNewConnections(newValue);
               }}
               options={selectOptions}
               placeholder="Workspace databases"
               value={newConnections}
            />
         </Modal.Body>
         <Modal.Footer className="border-0 pt-0 justify-content-between">
            <div className="fs-12p ms-0">
               Add new data source in{' '}
               <Link to={'/sources'}>
                  Admin
                  <BiChevronRight size={12} />
                  Data Sources
               </Link>
            </div>
            <div className="me-0">
               <button className="btn btn-sm btn-primary" onClick={() => saveConnections()}>
                  Save
               </button>
            </div>
         </Modal.Footer>
      </Modal>
   );
};
export default ManageWorkspaceConnections;
