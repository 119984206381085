import _ from 'lodash';
import neo4j, { Integer } from 'neo4j-driver';
import { QueryReturn } from '../interfaces';

export interface SigmaNode {
   attributes: {
      color: string;
      label: string;
      properties: [string, any][];
      size: number;
      x: number;
      y: number;
   };
   key: string;
}

export interface SigmaEdge {
   attributes: {
      label: string;
      size: number;
   };
   key: string;
   source: string;
   target: string;
}

export interface SigmaGraph {
   edges: SigmaEdge[];
   nodes: SigmaNode[];
}

function getRandomColor() {
   const digits = '0123456789abcdef';
   let code = '#';
   for (let i = 0; i < 6; i++) {
      code += digits.charAt(Math.floor(Math.random() * 16));
   }
   return code;
}

function getLabelColor(
   node: { identity: Integer; labels: string[]; properties: Object },
   colourLibrary: { [key: string]: string }
) {
   let label = 'None';
   if (node.labels && node.labels[0]) {
      label = node.labels[0];
   }
   let colour = colourLibrary[label];
   if (!colour) {
      colour = getRandomColor();
      colourLibrary[label] = colour;
   }

   return colour;
}

export function convertToSigmaNode(
   node: { identity: Integer; properties: Object },
   color?: string
): SigmaNode {
   const key = neo4j.integer.toString(node.identity);
   let label = '';

   const properties = Object.entries(node.properties);
   const nodeProps: [string, any][] = [];
   for (const prop of properties) {
      if (prop[1].hasOwnProperty('high') && prop[1].hasOwnProperty('low')) {
         //Is integer

         if (neo4j.integer.inSafeRange(prop[1])) {
            nodeProps.push([prop[0], neo4j.integer.toNumber(prop[1])]);
         } else {
            nodeProps.push([prop[0], neo4j.integer.toString(prop[1])]);
         }
      } else if (typeof prop[1] === 'object') {
         nodeProps.push([prop[0], JSON.stringify(prop[1])]);
      } else {
         nodeProps.push([prop[0], prop[1]]);
      }

      if (prop[0] === 'name' && typeof prop[1] === 'string') {
         label = prop[1];
         break;
      }

      if (typeof prop[1] === 'string' && (label === '' || label.length > prop[1].length)) {
         label = prop[1];
      }
   }

   if (label === '') {
      label = key;
   }

   const newNode: SigmaNode = {
      key,
      attributes: {
         color: color ?? '',
         label,
         x: 0,
         y: 0,
         size: 8,
         properties: nodeProps,
      },
   };

   return newNode;
}

export function convertToSigmaEdge(edge: {
   end: Integer;
   identity: Integer;
   properties: Object;
   start: Integer;
   type: string;
}): SigmaEdge {
   const newEdge: SigmaEdge = {
      key: neo4j.integer.toString(edge.identity),
      source: neo4j.integer.toString(edge.start),
      target: neo4j.integer.toString(edge.end),
      attributes: {
         size: 2,
         label: edge.type,
      },
   };

   return newEdge;
}

export function convertQueryReturnToSigmaGraph(queryReturn: QueryReturn): SigmaGraph {
   let graphData: SigmaGraph = { edges: [], nodes: [] };
   const colourLibrary: { [key: string]: string } = {};

   if (Array.isArray(queryReturn?.rows)) {
      // for each row
      queryReturn?.rows.forEach((row) => {
         // for each column
         Object.entries(row).forEach(([key, value]) => {
            //if field is a node
            if (value && (value as Object).hasOwnProperty('labels')) {
               const color = getLabelColor(value as any, colourLibrary);
               graphData.nodes.push(convertToSigmaNode(value as any, color));
            }

            //if field is an edge
            if (value && (value as Object).hasOwnProperty('type')) {
               graphData.edges.push(convertToSigmaEdge(value as any));
            }

            //if field is a path
            if (value && (value as Object).hasOwnProperty('segments')) {
               ((value as any).segments as any[]).forEach(
                  (seg: {
                     end: { identity: Integer; labels: string[]; properties: Object };
                     relationship: {
                        end: Integer;
                        identity: Integer;
                        properties: Object;
                        start: Integer;
                        type: string;
                     };
                     start: { identity: Integer; labels: string[]; properties: Object };
                  }) => {
                     const startColor = getLabelColor(seg.start, colourLibrary);
                     graphData.nodes.push(convertToSigmaNode(seg.start, startColor));

                     const endColor = getLabelColor(seg.end, colourLibrary);
                     graphData.nodes.push(convertToSigmaNode(seg.end, endColor));

                     graphData.edges.push(convertToSigmaEdge(seg.relationship));
                  }
               );
            }
         });
      });
   }

   graphData.nodes = _.uniqBy(graphData.nodes, (node) => node.key);
   graphData.edges = _.uniqBy(graphData.edges, (edge) => edge.key);

   return graphData;
}
