import React from 'react';
import Page from '../components/Page';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { FaHome } from 'react-icons/fa';

export const Error50x = (): JSX.Element => {
   const navigate = useNavigate();
   return (
      <Page>
         <div
            style={{
               width: '100%',
               height: '100%',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               marginTop: '10vw',
            }}
         >
            <h2 className="display-5">An error occurred.</h2>
            <span style={{ fontSize: '18px' }}>
               If this error persists, contact us at{' '}
               <a href="mailto:support@runql.com">support@runql.com</a>.
            </span>
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                  gap: '1rem',
               }}
            >
               <Button colorScheme="primary" onClick={() => window.history.back()} size="md">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                     <IoMdArrowRoundBack size="20px" />
                     <span style={{ fontSize: '18px' }}>Go Back</span>
                  </div>
               </Button>
               <Button colorScheme="primary" onClick={() => navigate('/home')} size="md">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                     <FaHome size="20px" />
                     <span style={{ fontSize: '18px' }}>Go Home</span>
                  </div>
               </Button>
            </div>
         </div>
      </Page>
   );
};

export default Error50x;
