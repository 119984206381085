import React, {
   forwardRef,
   useContext,
   useEffect,
   useImperativeHandle,
   useRef,
   useState,
   useMemo,
} from 'react';

import { autocompletion } from '@codemirror/autocomplete';
import { EditorState } from '@codemirror/state';
import { Tooltip, showTooltip } from '@codemirror/view';
import { StateField } from '@codemirror/state';
import { json } from '@codemirror/lang-json';
import { cypher } from '@codemirror/legacy-modes/mode/cypher';
import { MySQL, PostgreSQL, MSSQL, StandardSQL, sql } from '@codemirror/lang-sql';
import { python } from '@codemirror/lang-python';
import {
   bracketMatching,
   defaultHighlightStyle,
   StreamLanguage,
   syntaxHighlighting,
} from '@codemirror/language';
import { EditorView } from '@codemirror/view';

import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';
import CodeMirror, { basicSetup, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { keymap, KeyBinding } from '@codemirror/view';

import { BiCheck, BiCopy } from 'react-icons/bi';
import { DBMS } from '../../enums/dbms';
import { ThemeContext } from '../ThemeContext';
import { copyToClipboard } from '../../utilities/clipboard';

/**
 * This is not where you edit the display settings for code mirror.
 * We set some custom theme items here as the default so we don't get codemirror defaults.
 * The styles array here forces the classes to be attributed to the different words - keywords, bool, number, etc.  Without this those special words do not get a class name.
 * To customize the look and feel of the text in code mirror use the css in theme-custom.scss
 */
const lightTheme = createTheme({
   theme: 'light',
   settings: {
      background: 'rgba(36, 50, 72, 0.005)',
      foreground: '#0E1927',
      lineHighlight: '#0b0f19',
      gutterBackground: 'rgba(36, 50, 72, 0.005)',
      gutterForeground: '#9397ad',
      fontFamily: '"JetBrains Mono", monospace',
   },
   styles: [
      { tag: t.bracket, color: '#569cd6' },
      { tag: t.comment, color: '#9397ad' },
      { tag: t.variableName, color: '#4c82f7' },
      { tag: t.quote, color: '#0b0f19' },
      { tag: t.moduleKeyword, color: '#fd7e14' },
      { tag: [t.string, t.special(t.brace)], color: '#6a9955' },
      { tag: t.number, color: '#4c82f7' },
      { tag: t.bool, color: '#4c82f7' },
      { tag: t.null, color: '#4c82f7' },
      { tag: t.keyword, color: '#569cd6' },
      { tag: t.operator, color: '#fd7e14' },
      { tag: t.className, color: '#569cd6' },
      { tag: t.definition(t.typeName), color: '#569cd6' },
      { tag: t.typeName, color: '#fd7e14' },
      { tag: t.angleBracket, color: '#0b0f19' },
      { tag: t.tagName, color: '#569cd6' },
      { tag: t.attributeName, color: '#4c82f7' },
      { tag: t.propertyName, color: '#198754' },
      { tag: t.annotation, color: '#fd7e14' },
   ],
});

const darkTheme = createTheme({
   theme: 'dark',
   settings: {
      background: '#0b0f19',
      foreground: 'transparent',
      lineHighlight: '#0b0f19',
      gutterBackground: '#0b0f19',
      gutterForeground: '#565973',
      fontFamily: '"JetBrains Mono", monospace',
   },
   styles: [
      { tag: t.bracket, color: '#569cd6' },
      { tag: t.comment, color: '#565973' },
      { tag: t.variableName, color: '#4c82f7' },
      { tag: t.quote, color: '#0b0f19' },
      { tag: t.moduleKeyword, color: '#fd7e14' },
      { tag: [t.string, t.special(t.brace)], color: '#198754' },
      { tag: t.number, color: '#4c82f7' },
      { tag: t.bool, color: '#4c82f7' },
      { tag: t.null, color: '#4c82f7' },
      { tag: t.keyword, color: '#569cd6' },
      { tag: t.operator, color: '#fd7e14' },
      { tag: t.className, color: '#569cd6' },
      { tag: t.definition(t.typeName), color: '#569cd6' },
      { tag: t.typeName, color: '#fd7e14' },
      { tag: t.angleBracket, color: '#0b0f19' },
      { tag: t.tagName, color: '#569cd6' },
      { tag: t.attributeName, color: '#4c82f7' },
      { tag: t.propertyName, color: '#198754' },
      { tag: t.annotation, color: '#fd7e14' },
   ],
});

function getExtensionList(
   dialect?: DBMS,
   schema?: Record<string, string[]>,
   {
      saveSnippet,
      snippetSaved,
   }: {
      saveSnippet?: (snippet: string) => void;
      snippetSaved?: string;
   } = {}
) {
   const customMarkdownKeymap: readonly KeyBinding[] = [
      {
         key: 'Enter',
         shift: (view) => {
            view.dom.dispatchEvent(
               new KeyboardEvent('keydown', {
                  key: 'Enter',
                  keyCode: 13,
                  bubbles: true,
                  cancelable: true,
                  shiftKey: true,
               })
            );
            return true;
         },
      },
      ...(saveSnippet
         ? [
              {
                 key: 'Meta-S',
                 run: (view) => {
                    const snippet = view.state.sliceDoc(
                       view.state.selection.main.from,
                       view.state.selection.main.to
                    );
                    if (!snippet) return false;
                    saveSnippet(snippet);
                    return true;
                 },
              } as KeyBinding,
           ]
         : []),
      {
         any: (view, event) => {
            if (event.altKey) {
               view.dom.dispatchEvent(new KeyboardEvent(event.type, event));
               return true;
            }
            return false;
         },
      },
   ];

   const cursorTooltipField = StateField.define<readonly Tooltip[]>({
      create: getCursorTooltips,

      update(tooltips, tr) {
         if (!tr.docChanged && !tr.selection) return tooltips;
         return getCursorTooltips(tr.state);
      },

      provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
   });

   function getCursorTooltips(state: EditorState): readonly Tooltip[] {
      if (!saveSnippet) return [];
      return state.selection.ranges
         .filter((range) => !range.empty)
         .map((range) => {
            return {
               pos: range.from,
               above: true,
               strictSide: true,
               arrow: true,
               create: () => {
                  const snippet = state.sliceDoc(
                     state.selection.main.from,
                     state.selection.main.to
                  );
                  let dom = document.createElement('div');
                  dom.className = 'cm-tooltip-cursor save-snippet-cursor';
                  if (snippet === snippetSaved) {
                     dom.textContent = `Snippet saved!`;
                  } else {
                     dom.textContent = `Click here to save snippet`;
                     dom.onclick = () => {
                        saveSnippet?.(snippet);
                     };
                  }
                  return { dom };
               },
            };
         });
   }

   const cmExtensions = [
      basicSetup({
         foldGutter: false,
         dropCursor: false,
         allowMultipleSelections: false,
         indentOnInput: false,
         closeBrackets: true,
         tabSize: 4,
         highlightSelectionMatches: true,
      }),
      bracketMatching(),
      autocompletion({}),
      EditorView.lineWrapping,
      keymap.of(customMarkdownKeymap),
      cursorTooltipField,
   ];

   switch (dialect) {
      case DBMS.MySQL:
         cmExtensions.push(
            sql({
               dialect: MySQL,
               upperCaseKeywords: true,
               schema: schema,
               // Without a default schema, the plugin crashes with unknown
               // prefixes.
               defaultSchema: 'foo',
            })
         );
         break;
      case DBMS.MSSQL:
         cmExtensions.push(
            sql({
               dialect: MSSQL,
               upperCaseKeywords: true,
               schema: schema,
               // Without a default schema, the plugin crashes with unknown
               // prefixes.
               defaultSchema: 'foo',
            })
         );
         break;
      case DBMS.Postgres:
      case DBMS.Redshift:
         cmExtensions.push(
            sql({
               dialect: PostgreSQL,
               upperCaseKeywords: true,
               schema: schema,
               // Without a default schema, the plugin crashes with unknown
               // prefixes.
               defaultSchema: 'foo',
            })
         );
         break;
      case DBMS.Hugging_Face:
         cmExtensions.push(json());
         break;
      case DBMS.Neo4j:
         cmExtensions.push([
            StreamLanguage.define(cypher),
            syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
         ]);
         break;
      case DBMS.Python:
         cmExtensions.push(python());
         break;
      case undefined:
      default:
         cmExtensions.push(
            sql({
               dialect: StandardSQL,
               upperCaseKeywords: true,
               schema: schema,
               // Without a default schema, the plugin crashes with unknown
               // prefixes.
               defaultSchema: 'foo',
            })
         );
         break;
   }
   return cmExtensions;
}

export interface CodeEditorMethods {
   insert: (s: string) => void;
}

/**
 * A pre-configured Code Mirror window
 */
export const CodeEditor = forwardRef(
   (
      {
         dialect,
         onChange,
         query,
         readOnly,
         schema,
         saveSnippet,
         onFocus,
         onSelection,
      }: {
         dialect?: DBMS;
         onChange?: (value: string) => void;
         onFocus?: () => void;
         onSelection?: (selection?: string) => void;
         query?: string;
         readOnly?: boolean;
         saveSnippet?: (snippet: string) => void;
         schema?: Record<string, string[]>;
      },
      ref
   ): JSX.Element => {
      const themeContext = useContext(ThemeContext);
      const lastSelection = useRef<string>();

      const refs = React.useRef<ReactCodeMirrorRef>({});
      useImperativeHandle(ref, () => {
         return {
            insert(s: string) {
               editorView?.dispatch(editorView.state.replaceSelection(s));
            },
         };
      });
      const [showCopySuccess, setShowCopySuccess] = useState(false);
      const [editorView, setEditorView] = useState<EditorView>();
      let formattedQuery = query ?? '';

      // Move the cursor to the end of the query when first added
      const cursorMoved = useRef<boolean>();
      useEffect(() => {
         if (cursorMoved.current || !editorView) return;
         cursorMoved.current = true;
         if (!query) return;
         editorView.dispatch({
            selection: {
               anchor: editorView.state.doc.length,
               head: editorView.state.doc.length,
            },
         });
      }, [query, editorView]);

      const onEditorChange = React.useCallback(
         (value: string) => {
            onChange?.(value);
         },
         [onChange]
      );

      const copy = () => {
         if (query) {
            showAlert();
            copyToClipboard(query);
         }
      };

      const showAlert = () => {
         setShowCopySuccess(true);

         setTimeout(() => {
            setShowCopySuccess(false);
         }, 2000);
      };

      const [snippetSaved, setSnippetSaved] = useState<string>();
      const extensions = useMemo(
         () =>
            getExtensionList(dialect, schema, {
               saveSnippet: saveSnippet
                  ? (s: string) => {
                       setSnippetSaved(s);
                       saveSnippet(s);
                       editorView?.focus();
                    }
                  : undefined,
               snippetSaved,
            }),
         [dialect, schema, saveSnippet, snippetSaved, editorView]
      );

      return (
         <div style={{ position: 'relative' }}>
            <div className="">
               <CodeMirror
                  autoFocus={!readOnly && !cursorMoved.current}
                  className={`queryRun ${readOnly ? 'readOnly' : 'editable'}`}
                  extensions={extensions}
                  indentWithTab={true}
                  onChange={onEditorChange}
                  onCreateEditor={(editorView) => {
                     setEditorView(editorView);
                  }}
                  onFocus={() => onFocus?.()}
                  onUpdate={(editor) => {
                     if (editor.state.selection.main.empty) {
                        if (lastSelection.current) {
                           lastSelection.current = undefined;
                           onSelection?.();
                        }
                     } else {
                        const snippet = editor.state.sliceDoc(
                           editor.state.selection.main.from,
                           editor.state.selection.main.to
                        );
                        if (snippet !== lastSelection.current) {
                           lastSelection.current = snippet;
                           onSelection?.(snippet);
                        }
                     }
                  }}
                  placeholder="Write a query…"
                  readOnly={readOnly}
                  ref={refs}
                  theme={themeContext?.mode === 'dark' ? darkTheme : lightTheme}
                  value={formattedQuery}
               />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
               <button
                  className="btn btn-xs btn-link btn-secondary bg-transparent copy-icon p-2 border-0"
                  onClick={copy}
                  title="Copy"
               >
                  {showCopySuccess ? <BiCheck size={16} /> : <BiCopy size={16} />}
               </button>
            </div>
         </div>
      );
   }
);
export default CodeEditor;
