import { FormEvent, useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { get, post } from '../utilities';

import PublicPage from '../components/PublicPage';
import LoadingError from '../components/UI/LoadingError';

export enum MagicLinkType {
   FORGOT_PASSWORD = 1,
   NEW_ACCOUNT = 2,
}

type LinkCheckResult = {
   linkType: MagicLinkType;
   ok: boolean;
};

export const ResetPasswordPage = (): JSX.Element => {
   const [errorAlert, setErrorAlert] = useState('');
   const [formDisabled, setFormDisabled] = useState(false);
   const { link } = useParams();
   const linkCheckQuery = useQuery<LinkCheckResult | undefined>(
      [undefined],
      async () => {
         try {
            const result = await get<LinkCheckResult>(`auth/linkCheck/${link}`);
            if (!result.ok) {
               setErrorAlert('Link has expired');
            }
            return result;
         } catch (error) {
            console.error(error);
            setErrorAlert('Link has expired');
         }
      },
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
      }
   );
   const linkCheck = linkCheckQuery.data;

   const passwordMutation = useMutation<void, void, FormData>({
      mutationFn: async (form: FormData) => {
         const body: Record<string, string> = {};
         form.forEach((v, k) => (body[k] = v as string));
         await post(`auth/pwdreset/${link}`, { body });
      },
   });

   const passwordSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      try {
         event.preventDefault();
         setFormDisabled(true);
         await passwordMutation.mutateAsync(new FormData(event.currentTarget));
         setErrorAlert('');
         window.location.href = '/';
      } catch (e) {
         //console.debug('Error submitting password', e);
         setErrorAlert('Sorry, something went wrong. Password not changed.');
      }
   };

   if (linkCheckQuery.isLoading) return <Spinner />;
   if (linkCheckQuery.isError)
      return (
         <LoadingError message="Sorry, something is wrong. Cannot reset password at this time." />
      );
   if (errorAlert)
      return (
         <PublicPage>
            <Alert variant="danger">{errorAlert}</Alert>
         </PublicPage>
      );
   return (
      <PublicPage>
         <div className="card p-4 border-1 shadow-sm" style={{ maxWidth: '600px' }}>
            <h3 className="mb-2">
               {linkCheck?.linkType === MagicLinkType.NEW_ACCOUNT
                  ? 'Welcome to runQL!'
                  : 'Reset Your Password'}
            </h3>
            <Form onSubmit={passwordSubmit}>
               <Form.Group>
                  <Form.Label>Create a new password</Form.Label>
                  <Form.Control
                     autoFocus
                     disabled={formDisabled}
                     name="password"
                     placeholder="Password"
                     type="password"
                  />
               </Form.Group>
               <Form.Group className="mt-2">
                  <Button className="w-100" disabled={formDisabled} type="submit" variant="primary">
                     {linkCheck?.linkType === MagicLinkType.NEW_ACCOUNT
                        ? 'Create Account'
                        : 'Reset Password'}
                  </Button>
               </Form.Group>
            </Form>
         </div>
      </PublicPage>
   );
};

export default ResetPasswordPage;
