import { useInjection } from 'inversify-react';
import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useExplorerWorkspaceRole } from '.';
import { useExplorer, useExploreTab, useOrg } from '..';
import {
   CollaboratorRole,
   PaginatedResult,
   QueryVersion,
   QueryVersionPatch,
   QueryVersionPost,
} from '../../entities';
import { PersonRole, QueryKey, QueryKeyType } from '../../enums';
import {
   GetOptionsQuery,
   ListOptionsQuery,
   OrgService,
   QueryService,
   WorkflowCountResult,
} from '../../services';
import { TYPES } from '../../types';
import { handleError } from '../../utilities';

const useQueryService = () => {
   return useInjection<QueryService>(TYPES.queryService);
};

const useOrgService = () => {
   return useInjection<OrgService>(TYPES.orgService);
};

export const useNewQueryMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: QueryVersionPost, context: unknown) => void;
   onSuccessCallback?: (
      newSavedQuery: QueryVersion | undefined,
      variables: QueryVersionPost,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const queryService = useQueryService();
   return useMutation({
      mutationFn: async (newVersion: QueryVersionPost) => {
         return queryService.post(newVersion);
      },
      async onSuccess(newSavedQuery, variables, context) {
         if (callbacks?.onSuccessCallback) {
            callbacks.onSuccessCallback(newSavedQuery, variables, context);
         }

         await queryClient.invalidateQueries(getQueryQueryKey({ type: QueryKeyType.LIST }));
      },
      onError: callbacks?.onErrorCallback || handleError,
   });
};

export const useUpdateQueryMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: QueryVersionPatch, context: unknown) => void;
   onSuccessCallback?: (
      newSavedQuery: QueryVersion | undefined,
      variables: QueryVersionPatch,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const queryService = useQueryService();

   return useMutation({
      mutationFn: async (update: QueryVersionPatch) => {
         if (!update.queryVersion.id) throw Error('No ID provided');
         return await queryService.patch(update.queryVersion.id, update);
      },
      async onSuccess(data, variables, context) {
         if (callbacks?.onSuccessCallback) {
            callbacks.onSuccessCallback(data, variables, context);
         }
         if (data) {
            const promises = [
               queryClient.invalidateQueries(
                  getQueryQueryKey({ type: QueryKeyType.GET, id: data.id })
               ),
               // Invalidate lists if a version was published
               data.version
                  ? queryClient.invalidateQueries(getQueryQueryKey({ type: QueryKeyType.LIST }))
                  : null,
            ];
            await Promise.all(promises);
         }
      },
      onError: callbacks?.onErrorCallback || handleError,
   });
};

export const useListQueryQuery = ({
   callbacks,
   listOptions,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: PaginatedResult<QueryVersion>) => void;
   };
   listOptions?: ListOptionsQuery;
}) => {
   const queryService = useQueryService();
   return useQuery<PaginatedResult<QueryVersion>>(
      getQueryQueryKey({ type: QueryKeyType.LIST, options: listOptions }),
      async () => await queryService.listOptions(listOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useQueryToken = ({ token }: { token?: string }) => {
   const queryService = useQueryService();
   const query = useQuery([token], () => (token ? queryService.getToken(token) : undefined), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
   });
   if (query.isError) return { error: "You don't have access to this query." };
   return { data: query.data };
};

export const useGetQueryQuery = ({
   callbacks,
   getOptions,
   id,
}: {
   callbacks?: {
      onError?: (error: unknown) => void;
      onSuccess?: (data: QueryVersion | undefined) => void;
   };
   getOptions?: GetOptionsQuery;
   id: number | undefined;
}) => {
   const queryService = useQueryService();
   return useQuery<QueryVersion | undefined>(
      getQueryQueryKey({ type: QueryKeyType.GET, id: id, options: getOptions }),
      () => (id === undefined ? undefined : queryService.getOptions(id, getOptions)),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useLatestQuery = (queryId?: number): QueryVersion | undefined => {
   const queryService = useQueryService();
   return useQuery<QueryVersion | undefined>(
      ['queryId', queryId],
      () => queryService.getLatest(queryId!),
      {
         keepPreviousData: true,
         enabled: !!queryId,
         refetchOnWindowFocus: false,
         refetchOnMount: false,
         retry: false,
      }
   ).data;
};

export const useDeleteQueryMutator = (callbacks?: {
   onSuccess?: (data: null, id: number, context: unknown) => void;
}) => {
   const queryClient = useQueryClient();
   const service = useQueryService();

   return useMutation({
      mutationFn: async (id: number) => {
         return service.delete(id);
      },
      async onSuccess(data, id, context) {
         if (callbacks?.onSuccess) callbacks.onSuccess(data, id, context);
         const promises = [
            queryClient.invalidateQueries(getQueryQueryKey({ type: QueryKeyType.GET, id })),
            queryClient.invalidateQueries(getQueryQueryKey({ type: QueryKeyType.LIST })),
         ];
         await Promise.all(promises);
      },
   });
};

export function getQueryQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsQuery | GetOptionsQuery;
   type?: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.Queries];
   if (keyParams.type) queryKey.push(keyParams.type);
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}

export const useQueryVersion = (id?: number) => {
   const service = useQueryService();

   return useQuery<QueryVersion | undefined>(
      getQueryQueryKey({ type: QueryKeyType.GET, id }),
      () => service.get(id!),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         enabled: !!id,
      }
   )?.data;
};
export const useListQueryVersions = ({
   callbacks,
   options,
   queryOptions,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: PaginatedResult<QueryVersion>) => void;
   };
   options?: ListOptionsQuery;
   queryOptions?: { enabled?: boolean };
} = {}) => {
   const service = useQueryService();

   return useQuery<PaginatedResult<QueryVersion>>(
      getQueryQueryKey({ type: QueryKeyType.LIST, options }),
      () => service.listOptions(options),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         enabled: queryOptions?.enabled ?? true,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useGetWorkflowCount = (callbacks?: {
   onError?: (err: unknown) => void;
   onSuccess?: (data: WorkflowCountResult) => void;
}) => {
   const service = useOrgService();

   return useQuery<WorkflowCountResult>(
      [QueryKey.WorkflowCount],
      () => service.getWorkflowCount(),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useQueryApproval = (queryVersion: QueryVersion) => {
   const exploreTab = useExploreTab();
   const queryClient = useQueryClient();
   const [saving, setSaving] = useState(false);
   const org = useOrg();
   const explorer = useExplorer();
   const role = useExplorerWorkspaceRole(exploreTab?.workspaceId);
   const approved = queryVersion?.id && queryVersion?.query?.approvedVersionId === queryVersion?.id;
   const reviewRequested =
      queryVersion?.id && queryVersion?.query?.reviewVersionId === queryVersion?.id;
   const isReviewer =
      (org?.requiredReviewers === 0 || explorer?.id !== queryVersion?.createdByPersonId) &&
      (explorer?.role === PersonRole.ORG_ADMIN ||
         (role && [CollaboratorRole.ADMIN, CollaboratorRole.REVIEWER].includes(role)));

   const updateQueryMutation = useUpdateQueryMutator({
      async onSuccessCallback(data, _newQuerySave, _context) {
         setSaving(false);
         if (!data?.id || !exploreTab) return;
         await Promise.all([
            queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', exploreTab.workspaceId]),
            queryClient.invalidateQueries([QueryKey.Queries, 'list', exploreTab.workspaceId]),
         ]);
      },
      onErrorCallback(error) {
         setSaving(false);
         handleError(error);
      },
   });

   const approve = useCallback(async () => {
      if (!queryVersion?.id) return;
      setSaving(true);
      return (
         (await updateQueryMutation.mutateAsync({
            approve: true,
            queryVersion: { id: queryVersion.id },
         })) ?? queryVersion
      );
   }, [queryVersion, updateQueryMutation]);

   const requestReview = useCallback(
      async (changeDesciption?: string) => {
         if (!queryVersion?.id) return;
         setSaving(true);
         return (
            (await updateQueryMutation.mutateAsync({
               review: true,
               queryVersion: { id: queryVersion.id },
               newComment: changeDesciption,
            })) ?? queryVersion
         );
      },
      [queryVersion, updateQueryMutation]
   );

   return { approved, approve, isReviewer, requestReview, reviewRequested, saving };
};
