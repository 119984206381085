// Keys and values need to match PersonRole in API
// The order here determine the order displayed in the UI
export enum PersonRole {
   ORG_ADMIN = 'orgAdmin',
   ORG_EXPLORER = 'orgExplorer',
}

export const ROLE_NAMES = {
   [PersonRole.ORG_ADMIN]: 'Org Admin',
   [PersonRole.ORG_EXPLORER]: 'Explorer',
};

export const ADMIN_ROLES: PersonRole[] = [PersonRole.ORG_ADMIN];
