import { useInjection } from 'inversify-react';
import { useQueryClient, useMutation } from 'react-query';
import { Alert, Button, Col, Spinner, Stack, Row } from 'react-bootstrap';

import { findParams, QueryVersion, ConnectionAccessType } from '../entities';
import { QueryService } from '../services';
import { TYPES } from '../types';
import { QueryKey } from '../enums';
import { useOrg } from '../hooks';
import { useMemo, useState } from 'react';
import { UpgradeModal } from '../pages';
import { SERVICE_PLANS } from '@runql/util';

export const RunSource = ({
   queryVersion,
   readOnly,
}: {
   queryVersion: QueryVersion;
   readOnly?: boolean;
}) => {
   const queryService = useInjection<QueryService>(TYPES.queryService);
   const [showUpgradeModal, setShowUpgradeModal] = useState(false);
   const token = queryVersion.query?.token;

   const queryClient = useQueryClient();
   const org = useOrg();
   const invalidate = () => {
      queryClient.invalidateQueries([QueryKey.Queries, 'list', queryVersion.query?.workspaceId]);
      queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', queryVersion.query?.workspaceId]);
   };
   const { isLoading, mutateAsync } = useMutation({
      mutationFn: async (query: QueryVersion) => {
         return await queryService.postToken(query);
      },
      onSuccess: async () => {
         invalidate();
      },
   });
   const { isLoading: isDeleting, mutateAsync: deleteToken } = useMutation({
      mutationFn: async (query: QueryVersion) => {
         return await queryService.deleteToken(query);
      },
      onSuccess: async () => {
         invalidate();
      },
   });

   const params = findParams(queryVersion?.steps ?? []);
   const host = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
   }`;
   const unsaved = !queryVersion?.query?.latestVersion?.version;
   const individual = queryVersion?.steps?.some(
      (s) => s.dataConnection?.connectionAccessType === ConnectionAccessType.INDIVIDUAL
   );

   const runSourceAllowed = useMemo(() => {
      return org?.plan !== undefined && SERVICE_PLANS[org.plan].runSource;
   }, [org]);

   return (
      <Stack className="fs-12p" gap={2}>
         <UpgradeModal onClose={() => setShowUpgradeModal(false)} show={showUpgradeModal} />
         <div>
            runSource is a virtual query service that acts as a bridge between your database(s) and
            services that need data (BI Dashboards, etc).{' '}
            <a
               className="text-decoration-none"
               href="/documentation#runSource-17-What_is_runQL's_Virtual_Query_Service_(AKA_runSource)?"
               target="_blank"
            >
               Read more
            </a>
            .
         </div>
         <div>
            {!runSourceAllowed && (
               <div style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}>
                  Want to leverage runSource for your organization? It's available in{' '}
                  <strong>team</strong> and <strong>enterprise</strong> plans.{' '}
                  <Button
                     onClick={() => setShowUpgradeModal(true)}
                     style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        padding: 0,
                        margin: 0,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '12px',
                     }}
                  >
                     Click here
                  </Button>{' '}
                  to upgrade.
               </div>
            )}
            {unsaved && runSourceAllowed && <i>Save this query to enable runSource.</i>}
            {readOnly && !unsaved && !token && (
               <i>Ask someone with edit access to generate a runSource link.</i>
            )}
            {!readOnly && !unsaved && !token && runSourceAllowed && (
               <>
                  {!individual && (
                     <Button
                        disabled={isLoading}
                        onClick={() => mutateAsync(queryVersion)}
                        size="sm"
                     >
                        {isLoading ? (
                           <Spinner
                              animation="border"
                              aria-hidden="true"
                              as="span"
                              role="status"
                              size="sm"
                           />
                        ) : (
                           <span>Generate runSource</span>
                        )}
                     </Button>
                  )}
                  {individual && (
                     <Alert variant="warning">
                        Shared credentials are required for all data sources in your query. You can
                        enable runSource by adding data sources with shared credentials to this
                        workspace and updating the data sources used in this query. (You can
                        continue to use individual credentials for non-runSource queries in this
                        workspace.)
                     </Alert>
                  )}
               </>
            )}
            {token && (
               <>
                  <code>
                     SELECT * FROM runQL WHERE runSource='{host}/source/{token.token}'
                     {params.map((p) => ` AND ${p.name}=?`)};
                  </code>
                  <Row>
                     <Col className="col-3">Host:</Col>
                     <Col>runsource.runql.com</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Type:</Col>
                     <Col>MySQL</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Database:</Col>
                     <Col>runQL</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Port:</Col>
                     <Col>3306</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">User:</Col>
                     <Col>runql</Col>
                  </Row>
                  <Row>
                     <Col className="col-3">Password:</Col>
                     <Col>runql</Col>
                  </Row>
                  {false && (
                     <div className="mt-3">
                        {/* TODO: add a modal prompt and fix duplicate key error re-generating after deletion */}
                        <Button
                           disabled={isDeleting}
                           onClick={() => deleteToken(queryVersion)}
                           size="sm"
                           variant="secondary"
                        >
                           {isDeleting ? (
                              <Spinner
                                 animation="border"
                                 aria-hidden="true"
                                 as="span"
                                 role="status"
                                 size="sm"
                              />
                           ) : (
                              <>Disable runSource</>
                           )}
                        </Button>
                     </div>
                  )}
               </>
            )}
         </div>
      </Stack>
   );
};

export default RunSource;
