import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Card, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import redshiftLogo from '../../../assets/img/amazon-redshift.svg';
import bigQueryLogo from '../../../assets/img/bigquery.png';
import databricksLogo from '../../../assets/img/databricks.png';
import mssqlLogo from '../../../assets/img/mssql2.png';
import mysqlLogo from '../../../assets/img/mysql2.svg';
import neo4jLogo from '../../../assets/img/neo4j.png';
import oracleLogo from '../../../assets/img/oracle.png';
import postgreSqlLogo from '../../../assets/img/postgres.svg';
import snowflakeLogo from '../../../assets/img/snowflake.png';
import trinoLogo from '../../../assets/img/trino.png';
import mongoDBLogo from '../../../assets/img/mongodb.png';
import { DBMS } from '../../../enums';

interface DBMSEntry {
   dbms: DBMS;
   image: string;
   name: string;
}

const DBEntries: DBMSEntry[] = [
   { dbms: DBMS.MySQL, name: 'MySQL', image: mysqlLogo },
   { dbms: DBMS.Postgres, name: 'PostgreSQL', image: postgreSqlLogo },
   { dbms: DBMS.MSSQL, name: 'Microsoft SQL', image: mssqlLogo },
   { dbms: DBMS.Big_Query, name: 'Google BigQuery', image: bigQueryLogo },
   { dbms: DBMS.Neo4j, name: 'Neo4j®', image: neo4jLogo },
   { dbms: DBMS.Redshift, name: 'Amazon Redshift', image: redshiftLogo },
   { dbms: DBMS.Databricks, name: 'Databricks', image: databricksLogo },
   { dbms: DBMS.Snowflake, name: 'Snowflake', image: snowflakeLogo },
   { dbms: DBMS.Oracle, name: 'Oracle', image: oracleLogo },
   { dbms: DBMS.Trino, name: 'Trino', image: trinoLogo },
   { dbms: DBMS.MongoDB, name: 'MongoDB', image: mongoDBLogo },
];

function DBMSCard({
   dbms,
   onClick,
   selected,
}: {
   dbms: DBMSEntry;
   onClick: () => void;
   selected: boolean;
}) {
   return (
      <Card
         className={
            selected
               ? 'db-selector db-selector-on btn btn-light btn-logo btn-lift'
               : 'db-selector db-selector-off btn btn-light btn-logo btn-lift'
         }
         onClick={onClick}
      >
         <span>
            <img alt={dbms.name} className="icon-xs db-selector-icon" src={dbms.image} />
         </span>
         <span className="ms-1 fw-500 db-selector">{dbms.name}</span>
      </Card>
   );
}

export const baseConnectionSchema = z.object({
   selectedConnection: z.number().min(0).optional(),
   selectedDBMS: z
      .nativeEnum(DBMS, {
         errorMap: (issue, _ctx) => {
            switch (issue.code) {
               case 'invalid_enum_value':
                  return {
                     message: 'Please select an existing connection or create a new connection',
                  };
               default:
                  return { message: 'Unknown error' };
            }
         },
      })
      .optional(),
});

export type BaseConnectionFormData = z.infer<typeof baseConnectionSchema>;
export const baseConnectionFormId = 'baseConnectionForm';

function BaseConnectionForm({
   defaultValues,
   onSubmit,
}: {
   defaultValues?: BaseConnectionFormData;
   onSubmit?: (data: BaseConnectionFormData) => void;
}) {
   // register form
   const {
      register,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
      reset,
   } = useForm<BaseConnectionFormData>({
      resolver: zodResolver(baseConnectionSchema),
      mode: 'onTouched',
      defaultValues,
   });

   // Queries
   const selectedConnection = watch('selectedConnection');
   const selectedDBMS = watch('selectedDBMS');

   // Page functions
   const handleOnSubmit = (data: BaseConnectionFormData) => {
      reset();
      if (onSubmit) {
         onSubmit(data);
      }
   };

   const setSelectedDBMS = (dbms: DBMS) => {
      setValue('selectedConnection', undefined);
      setValue('selectedDBMS', dbms);
   };

   //Render
   return (
      <Form id={baseConnectionFormId} onSubmit={handleSubmit(handleOnSubmit)}>
         <input
            {...register('selectedConnection', {
               setValueAs: (v) => (v === '' ? undefined : parseInt(v, 10)),
            })}
            type="hidden"
         />
         <input {...register('selectedDBMS')} type="hidden" />
         {!!errors.selectedDBMS && <Alert variant="danger">{errors.selectedDBMS.message}</Alert>}
         <div className="fs-14p fw-600 text-primary">Add a new data source</div>
         <div
            className="mt-3"
            style={{
               display: 'grid',
               gridTemplateColumns: 'repeat(4, 1fr)',
               gridAutoRows: 'min-content',
               gap: '1em',
            }}
         >
            {DBEntries.map((database) => (
               <DBMSCard
                  dbms={database}
                  key={database.dbms}
                  onClick={() => {
                     setSelectedDBMS(database.dbms);
                  }}
                  selected={database.dbms === selectedDBMS && !selectedConnection}
               />
            ))}
         </div>
      </Form>
   );
}

export default BaseConnectionForm;
