import ReactPaginate from 'react-paginate';

const Paginator = ({
   onChange,
   page,
   pageSize,
   totalItems,
}: {
   onChange?: (page: number) => void;
   page: number;
   pageSize: number;
   totalItems: number;
}): JSX.Element => {
   if (pageSize <= 0) {
      //console.warn(`Paginator page size is 0 or less`);
      return <></>;
   }

   if (totalItems <= pageSize) {
      // too few items for pagination
      return <></>;
   }

   return (
      <ReactPaginate
         activeClassName="active"
         breakClassName="page-item"
         breakLabel="…"
         breakLinkClassName="page-link"
         className="pagination pagination-sm"
         forcePage={page}
         marginPagesDisplayed={2}
         nextClassName="page-item"
         nextLabel="›"
         nextLinkClassName="page-link"
         onPageChange={(item) => {
            onChange?.(item.selected);
         }}
         pageClassName="page-item"
         pageCount={Math.ceil(totalItems / pageSize)}
         pageLinkClassName="page-link"
         pageRangeDisplayed={3}
         previousClassName="page-item"
         previousLabel="‹"
         previousLinkClassName="page-link"
         renderOnZeroPageCount={null}
      />
   );
};

export default Paginator;
