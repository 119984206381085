import React, { useEffect, useState } from 'react';
import { Alert, Form, Spinner } from 'react-bootstrap';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useGetAuthorizedExplorerQuery, useAttemptLoginMutator } from '../hooks';
import { signInWithMS, signInWithGoogle } from '../utilities/socialLogin';
import { GoogleLoginButton, MicrosoftLoginButton } from 'react-social-login-buttons';
import PublicPage from '../components/PublicPage';
import { useExtension } from '../hooks';

export const SignInPage = (): JSX.Element => {
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const mutation = useAttemptLoginMutator();
   const [searchParams] = useSearchParams();
   const location = useLocation();
   const auth = useGetAuthorizedExplorerQuery();
   const loggedIn = mutation.data?.authenticated || auth.data?.authenticated;
   const firstLogin = mutation.data?.authenticated && !auth.data?.lastLogin;
   const redirectTo = location.state?.redirectTo ?? '/';
   const extension = useExtension();

   useEffect(() => {
      if (!loggedIn) return;
      // Sign in currently requires a full reload
      window.location.replace(firstLogin ? './change-password?first' : redirectTo);
   }, [loggedIn, firstLogin, redirectTo]);

   useEffect(() => {
      const handleMessage = (event: MessageEvent) => {
         if (event.data === 'authed') {
            window.location.replace(redirectTo);
         }
      };
      window.addEventListener('message', handleMessage);
      return () => {
         window.removeEventListener('message', handleMessage);
      };
   }, [redirectTo]);

   const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      try {
         await mutation.mutateAsync({
            email: email,
            password: password,
            provider: 'password',
         });
      } catch (err) {
         setErrorMessage('The email or password you entered is not correct.');
      }
   };

   if (auth.isFetching || loggedIn) {
      return <Spinner />;
   }

   return (
      <PublicPage>
         <div className="card pt-4 pb-1 ps-4 pe-4">
            <div className="mb-3">
               <h4 className="text-center mb-1">Welcome to runQL</h4>
               <div className="fs-12p text-center">Welcome back, please sign in</div>
            </div>
            <div className="row mb-3">
               <div className="col-12">
                  <GoogleLoginButton
                     align="center"
                     className="button-social-signin"
                     onClick={() => signInWithGoogle(!!extension)}
                  >
                     <div className="text-center fw-normal">Sign in with Google</div>
                  </GoogleLoginButton>
               </div>
               <div className="col-12">
                  <MicrosoftLoginButton
                     align="center"
                     className="button-social-signin"
                     onClick={() => signInWithMS(!!extension)}
                  >
                     <div className="text-center fw-normal">Sign in with Microsoft</div>
                  </MicrosoftLoginButton>
               </div>
            </div>
            <div className="py-3 d-flex align-items-center">
               <hr className="flex-grow-1" />
               <div className="badge bg-secondary">OR</div>
               <hr className="flex-grow-1" />
            </div>
            <form onSubmit={onFormSubmit}>
               <div className="position-relative mb-3">
                  <Form.Group>
                     <Form.Label className="form-label fs-12p fw-normal" htmlFor="inputEmail">
                        Email
                     </Form.Label>
                     <Form.Control
                        className="form-control-sm"
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        value={email}
                     />
                  </Form.Group>
               </div>
               <div className="mb-1">
                  <Form.Group>
                     <Form.Label className="form-label fs-12p fw-normal" htmlFor="inputPassword">
                        Password
                     </Form.Label>
                     <Form.Control
                        className="form-control-sm"
                        onChange={(event) => setPassword(event.target.value)}
                        type="password"
                        value={password}
                     />
                  </Form.Group>
               </div>
               <Form.Group>
                  <button className="btn btn-secondary w-100 mt-4" type="submit">
                     Sign In
                  </button>
               </Form.Group>
            </form>
            <Link className="btn btn-link fs-11p" to="/reset">
               Forgot your password?
            </Link>
            <div className="row">
               <div className="col fs-11p">
                  {errorMessage && <Alert variant={'danger'}>{errorMessage}</Alert>}
               </div>
            </div>
            <div className="row">
               <div className="col fs-11p">
                  {searchParams.has('error') && (
                     <Alert variant="danger">{searchParams.get('error')}</Alert>
                  )}
               </div>
            </div>
         </div>
         <div className="fs-12p mt-3 text-center">
            Don't have an account yet?&nbsp;&nbsp;
            <Link className="text-decoration-none" to="/signup">
               Sign up here.
            </Link>
         </div>
         <div className="mt-3 mb-1 text-center">
            <span className="fs-11p">
               By signing in you agree to the&nbsp;
               <a
                  className="text-decoration-none"
                  href="https://runql.com/terms-of-service.html"
                  rel="noreferrer"
                  target="_Blank"
               >
                  Terms of Service
               </a>
               &nbsp;and&nbsp;
               <a
                  className="text-decoration-none"
                  href="https://runql.com/acceptable-use.html"
                  rel="noreferrer"
                  target="_Blank"
               >
                  Acceptable Use
               </a>
            </span>
         </div>
      </PublicPage>
   );
};

export default SignInPage;
