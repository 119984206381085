import React from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { QueryParameter } from '../entities';

interface Props {
   hideExclude?: boolean;
   onDefaultValueChange(name: string, newValue: string): void;
   onExcludeChanged(name: string, newValue: boolean): void;
   queryParam: QueryParameter;
}

const excludeToolTip = (
   <Tooltip id="excludeParamTooltip">
      Select this to leave this parameter as it is, curly brackets and all, as it may be used by an
      external system.
   </Tooltip>
);

export const QueryParameterCard = (props: Props): JSX.Element => {
   const onDefaultValueChange = React.useCallback(
      (name: string, newValue: string) => {
         if (props.onDefaultValueChange !== undefined) {
            props.onDefaultValueChange(name, newValue);
         }
      },
      [props]
   );
   const onExcludeChanged = React.useCallback(
      (name: string, newValue: boolean) => {
         if (props.onExcludeChanged !== undefined) {
            props.onExcludeChanged(name, newValue);
         }
      },
      [props]
   );
   return (
      <Form className="">
         <Row className="align-items-center">
            <Col xs={2}>
               <span className="fs-12p">
                  <Form.Control
                     className="fw-600"
                     plaintext
                     readOnly
                     value={props.queryParam.name}
                  ></Form.Control>
               </span>
            </Col>
            <Col xs={2}>
               <Form.Control
                  className="fw-500 text-box-bottom-border-only"
                  disabled={props.queryParam.exclude}
                  onChange={(event) =>
                     onDefaultValueChange(props.queryParam.name, event.target.value)
                  }
                  value={props.queryParam.defaultValue}
               ></Form.Control>
            </Col>
            {!props.hideExclude && (
               <Col xs={1}>
                  <OverlayTrigger overlay={excludeToolTip} placement="right">
                     <Form.Check
                        checked={props.queryParam.exclude}
                        className="div-form-control-switch"
                        onChange={(event) =>
                           onExcludeChanged(props.queryParam.name, event.target.checked)
                        }
                        type="checkbox"
                     />
                  </OverlayTrigger>
               </Col>
            )}
         </Row>
      </Form>
   );
};
