import { Spinner } from 'react-bootstrap';
import { QueryReviewPage } from '../components';
import LoadingError from '../components/UI/LoadingError';
import { MdCheck } from 'react-icons/md';
import { QueryVersion } from '../entities';
import { getErrorMessage, useTitle } from '../utilities';
import { useListQueryQuery, useQueryApproval } from '../hooks';
import ErrorBoundary from '../components/ErrorBoundary';

const CertifyButton = ({ queryVersion }: { queryVersion: QueryVersion }) => {
   const { approve, saving } = useQueryApproval(queryVersion);
   return (
      <button className="btn btn-sm btn-success" onClick={approve}>
         {saving ? (
            <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
         ) : (
            <>
               <MdCheck />
               &nbsp;Certify
            </>
         )}
      </button>
   );
};

export function QueryReview() {
   useTitle('Certification Requests');

   const queries = useListQueryQuery({
      listOptions: {
         expandedPersonData: true,
         includeDataConnectionDetails: true,
         version: 'review',
         includeWorkspace: true,
         includeParent: true,
      },
   });

   if (queries.isError) return <LoadingError message={getErrorMessage(queries.error)} />;

   const queryVersions = queries.data?.items ?? [];

   return (
      <ErrorBoundary title={'An error occurred when loading certification requests.'}>
         <QueryReviewPage
            diffVersion={(queryVersion) => queryVersion.query?.approvedVersion}
            expandDetails={true}
            extraActions={(queryVersion) => [
               <CertifyButton key="approve" queryVersion={queryVersion} />,
            ]}
            header="Certification Requests"
            hideTabs={{ logs: true }}
            queryVersions={queryVersions}
         >
            {queries.data?.items.length === 0 && <p>No certification requests.</p>}
         </QueryReviewPage>
      </ErrorBoundary>
   );
}

export default QueryReview;
