import { ForwardedRef, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import '../assets/scss/components/input.scss';

export const InlineInput = forwardRef(
   (
      {
         onChange,
         value,
         multiLine,
         readOnly,
         placeholder,
         className,
      }: {
         className?: string;
         multiLine?: boolean;
         onChange: (value: string) => void;
         placeholder?: string;
         readOnly?: boolean;
         value: string;
      },
      targetRef: ForwardedRef<HTMLDivElement>
   ) => {
      const [localValue, setLocalValue] = useState<string>(value);
      const ref = useRef<HTMLTextAreaElement>(null);

      useEffect(() => {
         setLocalValue(value);
      }, [value]);

      useLayoutEffect(() => {
         if (ref.current) {
            ref.current.style.height = '0px';
            const scrollHeight = Math.max(ref.current.scrollHeight, multiLine ? 26 : 22);
            ref.current.style.height = scrollHeight + 'px';
         }
      }, [ref.current?.value, multiLine]);

      return (
         <div ref={targetRef}>
            <Form.Control
               as={multiLine ? 'textarea' : 'input'}
               className={`${className} small-form-control-input`}
               onChange={(e) => {
                  setLocalValue(e.target.value);
                  onChange(e.target.value);
               }}
               onKeyDown={(e) => {
                  if (multiLine) return;
                  if (e.key === 'Enter') e.currentTarget.blur();
               }}
               placeholder={placeholder}
               readOnly={readOnly}
               ref={ref}
               type="text"
               value={localValue}
            />
         </div>
      );
   }
);
export default InlineInput;
