import ReactHtmlParser from 'html-react-parser';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'rctx-contextmenu';
import { useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { BiRefresh, BiTrash, BiPlus } from 'react-icons/bi';
import { FaDatabase } from 'react-icons/fa';
import {
   MdCable,
   MdKeyboardArrowDown,
   MdKeyboardArrowRight,
   MdOpenInNew,
   MdTableChart,
   MdTableView,
} from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { StepType, useOpenQuery } from '../../entities';
import { DBMS, FEDERATED_DBMS, getDemoQuery, QueryKey } from '../../enums';
import { useUpdateSchema, useWorkspace } from '../../hooks';
import { schemaEditSupported, TableEditTab } from '../../pages/Workspace/Explore/Table';
import {
   getErrorMessage,
   handleError,
   IconAlert,
   IconIndex,
   IconKey,
   IconStoredProc,
   IconTrigger,
} from '../../utilities';
import LoadingSpinner from './LoadingSpinner';
import TreeView, { TreeNode } from './TreeView';
import { canCreateTable, isDatabaseNode } from '../CreateTableModal';
import { canDeleteTable, isTableNode } from '../DeleteTableModal';

import type { DatabaseNode } from '../CreateTableModal';
import type { TableNode } from '../DeleteTableModal';

const TreeViewNode = (props: {
   catalogNames?: boolean;
   expand?: boolean;
   expandLevels?: string[];
   node: TreeNode;
   parent?: TreeNode;
   searchText: string;
   showCreateTableModal: (databaseNode: DatabaseNode) => void;
   showDeleteTableModal: (tableNode: TableNode) => void;
   showQuickSelect?: boolean;
}): JSX.Element => {
   // State Variables
   const [showChildren, setShowChildren] = useState(
      (!!props.expand || props.expandLevels?.includes(props.node.type)) ?? false
   );

   // Mutators
   const queryClient = useQueryClient();

   const workspace = useWorkspace();
   const { updateSchema, state: updateSchemaState } = useUpdateSchema({
      async onSuccessCallback() {
         if (workspace?.id) {
            await queryClient.invalidateQueries([QueryKey.WorkspaceSchemaTree, workspace.id]);
         }
      },
   });

   const navigate = useNavigate();

   let iconType = props.node.icon;
   let iconTypeHtml;

   let fieldLabel = props.node.label;

   let nodeFieldDataType = props.node.fieldDataType;

   const nodeType = props.node.type;

   let nodeTypeHtml = '';
   let nodePopoverHtml = props.node.tooltip;

   let defaultFlag = props.node.defaultFlag;

   const handleShowChildren = () => setShowChildren(!showChildren);

   const publishQuery = useOpenQuery();
   const setQuery = (queryText: string, dataConnectionId: number) => {
      publishQuery({
         source: 'schema',
         newTab: true,
         queryVersion: {
            steps: [
               {
                  dataConnectionId,
                  order: 0,
                  queryText,
                  type: StepType.DATA_CONNECTION,
               },
            ],
            generatedTitle: true,
         },
      });
   };

   const toolTip = (
      <Popover id={props.node.key + '-popover'}>
         <Popover.Header className="fs-12p">{ReactHtmlParser(fieldLabel)}</Popover.Header>
         <Popover.Body className="text-muted fs-11p">
            {ReactHtmlParser(nodePopoverHtml)}
         </Popover.Body>
      </Popover>
   );

   if (nodeType === 'databaseNode') {
      nodeTypeHtml = 'fw-normal ps-1 pb-1 pt-0 fs-12p';
      if (defaultFlag === 1) {
         nodeTypeHtml = 'fw-600 ps-1 pb-1 pt-0 fs-12p';
      }
   }

   if (
      ['tableNode', 'storedProceduresFolder', 'triggersFolder', 'indexesFolder'].includes(nodeType)
   ) {
      nodeTypeHtml = 'fw-normal ps-1 pe-0 pb-1 pt-0 fs-12p';
   }

   if (['columnNode', 'storedProcedureNode', 'triggerNode', 'indexNode'].includes(nodeType)) {
      nodeTypeHtml = 'fw-normal ps-36p pb-1 pt-0 pe-0 fs-11p';
      iconTypeHtml = <IconKey style={{ color: 'transparent' }} />;
      if (iconType === 'PRI') {
         iconTypeHtml = <IconKey style={{ color: '#FFBA08' }} />;
      }
      if (iconType === 'MUL') {
         iconTypeHtml = <IconKey style={{ color: '#e2e5f1' }} />;
      }
   }

   if (nodeType === 'connectionNode' || nodeType === 'catalogNode') {
      nodeTypeHtml = 'fw-normal ps-0 pe-1 pb-1 pt-0 fs-12p';
      if (defaultFlag === 1) {
         nodeTypeHtml = 'fw-600 ps-0 pe-1 pb-1 pt-0 fs-12p';
      }
   }
   const updateSchemaClick = async () => {
      if (props.node.dataConnectionId) {
         try {
            await updateSchema(props.node.dataConnectionId, {
               catalogName: nodeType === 'catalogNode' ? props.node.label : undefined,
            });
         } catch (error) {
            handleError(getErrorMessage(error));
         }
      }
   };

   const contextMenuId = props.node.key + '-context-menu';
   const disableContextMenu =
      !props.showQuickSelect ||
      props.node.dbms === DBMS.Neo4j ||
      !['tableNode', 'storedProcedureNode', 'triggerNode', 'databaseNode'].includes(nodeType);

   if (
      (nodeType === 'storedProceduresFolder' ||
         nodeType === 'triggersFolder' ||
         nodeType === 'indexesFolder') &&
      !props.node.children.length
   )
      return <></>;

   return (
      <li className="nav-item w-100 lh-20" key={props.node.key + '-li'}>
         <div className="d-flex w-100 align-items-center flex-nowrap">
            <a
               className={`nav-link text-nowrap text-truncate ${nodeTypeHtml}`}
               href="#showChildren"
               onClick={handleShowChildren}
               style={{ display: 'inline-block' }}
            >
               {/* Order of these two triggers is important, The overlay stops working if the tooltip is placed before the ContextMenuTrigger */}
               <ContextMenuTrigger disable={disableContextMenu} id={contextMenuId}>
                  <div className="">
                     {/* Icons */}
                     {nodeType === 'catalogNode' ? (
                        <>
                           <span className="text-muted">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                        </>
                     ) : nodeType === 'databaseNode' ? (
                        <>
                           <span className="text-muted ps-8p">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <FaDatabase />
                           </span>
                        </>
                     ) : nodeType === 'tableNode' ? (
                        <>
                           <span className="text-muted ps-18p ">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           {props.node.isView ? (
                              <MdTableView />
                           ) : (
                              <span className="text-muted">
                                 <MdTableChart />
                              </span>
                           )}
                        </>
                     ) : nodeType === 'columnNode' ? (
                        <span className="text-muted">{iconTypeHtml}</span>
                     ) : nodeType === 'connectionNode' ? (
                        <>
                           <span className="text-muted">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <MdCable />
                           </span>
                        </>
                     ) : nodeType === 'tablesFolder' ? (
                        <>
                           <span className="text-muted ps-18p ">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                        </>
                     ) : nodeType === 'storedProceduresFolder' ? (
                        <>
                           <span className="text-muted ps-18p ">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <IconStoredProc />
                           </span>
                        </>
                     ) : nodeType === 'triggersFolder' ? (
                        <>
                           <span className="text-muted ps-18p ">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <IconTrigger />
                           </span>
                        </>
                     ) : nodeType === 'indexesFolder' ? (
                        <>
                           <span className="text-muted ps-18p ">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <IconIndex />
                           </span>
                        </>
                     ) : (
                        <></>
                     )}
                     {/* Only show the overlay tooltip for column nodes around the label.  */}
                     <span>
                        &nbsp;
                        {nodeType === 'connectionNode' && props.catalogNames ? (
                           <>{props.node.catalogName}</>
                        ) : nodeType === 'columnNode' ? (
                           <OverlayTrigger
                              delay={{ show: 500, hide: 0 }}
                              overlay={toolTip}
                              placement="right"
                           >
                              <span>
                                 {props.node.label}
                                 <span className="text-muted fs-9p">
                                    &nbsp;&#183; {nodeFieldDataType ? nodeFieldDataType : ''}
                                 </span>
                              </span>
                           </OverlayTrigger>
                        ) : (
                           props.node.label
                        )}
                     </span>
                  </div>
               </ContextMenuTrigger>
            </a>
            {/* This is the context menu that shows up on a right click of the table node. */}
            <ContextMenu id={contextMenuId}>
               {nodeType === 'tableNode' ? (
                  <ContextMenuItem>
                     <a
                        className="text-decoration-none align-items-center lh-1 flex-shrink-0"
                        href="#setQuery"
                        onClick={(e) => {
                           e.preventDefault();
                           const demoQuery = getDemoQuery(
                              props.node.dbms,
                              props.node.tableName!,
                              props.node.databaseName
                           );
                           setQuery(demoQuery, props.node.dataConnectionId!);
                        }}
                     >
                        <MdOpenInNew size={12} />{' '}
                        <span className="fs-10p">
                           SELECT * FROM {props.node.tableName} LIMIT 10;
                        </span>
                     </a>
                  </ContextMenuItem>
               ) : nodeType === 'storedProcedureNode' || nodeType === 'triggerNode' ? (
                  <ContextMenuItem>
                     <a
                        className="text-decoration-none align-items-center lh-1 flex-shrink-0"
                        href="#setQuery"
                        onClick={(e) => {
                           e.preventDefault();
                           setQuery(props.node.definition!, props.node.dataConnectionId!);
                        }}
                     >
                        <MdOpenInNew size={12} /> <span className="fs-10p">Load</span>
                     </a>
                  </ContextMenuItem>
               ) : (
                  <></>
               )}
               {nodeType === 'tableNode' &&
               ![DBMS.Trino, DBMS.MongoDB].includes(props.node.dbms) ? (
                  <>
                     <ContextMenuItem
                        onClick={() => {
                           if (workspace?.id) {
                              const path = `/workspaces/${workspace.id}/table/${props.node.id}`;
                              navigate(path);
                           }
                        }}
                     >
                        <MdOpenInNew className="opacity-50" size={12} />
                        <span className="fs-10p"> View Data</span>
                     </ContextMenuItem>
                     {schemaEditSupported(props.node.dbms) && !props.node.isDemo && (
                        <ContextMenuItem
                           onClick={() => {
                              if (workspace?.id) {
                                 const path = `/workspaces/${workspace.id}/table/${props.node.id}?subTab=${TableEditTab.COLUMNS}`;
                                 navigate(path);
                              }
                           }}
                        >
                           <MdOpenInNew className="opacity-50" size={12} />
                           <span className="fs-10p"> View Structure</span>
                        </ContextMenuItem>
                     )}
                  </>
               ) : (
                  <></>
               )}
               {isDatabaseNode(props.node) && canCreateTable(props.node) ? (
                  <ContextMenuItem
                     onClick={() => {
                        props.showCreateTableModal(props.node as DatabaseNode);
                     }}
                  >
                     <BiPlus className="opacity-50" size={12} />{' '}
                     <span className="fs-10p">Create Table</span>
                  </ContextMenuItem>
               ) : (
                  <></>
               )}
               {isTableNode(props.node) && canDeleteTable(props.node) ? (
                  <ContextMenuItem
                     onClick={() => {
                        props.showDeleteTableModal(props.node as TableNode);
                     }}
                  >
                     <BiTrash className="opacity-50" size={12} />{' '}
                     <span className="fs-10p">Delete Table</span>
                  </ContextMenuItem>
               ) : (
                  <></>
               )}
            </ContextMenu>
            {/* This is the refresh icon that shows up after a connection or catalog name */}
            {(nodeType === 'connectionNode' || nodeType === 'catalogNode') && !props.node.isDemo ? (
               <span className="text-muted">
                  <button
                     className="btn btn-link custom-button-link-no-spacing-middle p-0 mb-1"
                     onClick={() => updateSchemaClick()}
                  >
                     <BiRefresh size={16} />
                  </button>
               </span>
            ) : (
               <span></span>
            )}
            {!FEDERATED_DBMS.includes(props.node.dbms) && props.catalogNames ? (
               <OverlayTrigger
                  delay={{ show: 500, hide: 0 }}
                  overlay={
                     <Tooltip id="federation_unsupported">
                        Not supported for federated queries
                     </Tooltip>
                  }
                  placement="top"
                  trigger={['hover', 'focus']}
               >
                  <span>
                     <IconAlert size={14} />
                  </span>
               </OverlayTrigger>
            ) : (
               <></>
            )}
         </div>
         {updateSchemaState.isLoading ? (
            <LoadingSpinner />
         ) : (
            showChildren && (
               <TreeView
                  expandLevels={props.expandLevels}
                  parent={props.node}
                  searchText={props.searchText}
                  showCreateTableModal={props.showCreateTableModal}
                  showDeleteTableModal={props.showDeleteTableModal}
                  showQuickSelect={props.showQuickSelect}
                  treeData={props.node.children}
               />
            )
         )}
      </li>
   );
};

export default TreeViewNode;
