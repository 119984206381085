"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorMessage = void 0;
const getErrorMessage = (error) => {
    let message = "";
    if (error instanceof Error) {
        message = error.message;
    }
    else if (error !== null &&
        typeof error === "object" &&
        "message" in error) {
        message = error.message;
    }
    else {
        message = String(error);
    }
    if (!message || message.length === 0) {
        message = "Unknown Error";
    }
    return message;
};
exports.getErrorMessage = getErrorMessage;
