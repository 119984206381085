import { ExploreTab } from '../../../entities';
import QueryPage from './QueryPage';
import { ExploreTabContext, useWorkspace } from '../../../hooks';
import { LoadingSpinner } from '../../../components';
import { useTitle } from '../../../utilities';
import { QueryPanelProvider } from '../../../hooks/QueryPanelContext';
import { AskRunaProvider } from '../../../hooks/askHooks';

const ExploreTabContent = ({
   active,
   exploreTab,
}: {
   active: boolean;
   exploreTab: ExploreTab;
}): JSX.Element => {
   const queryVersion = exploreTab.queryVersion;
   const workspace = useWorkspace();
   let title = queryVersion?.title;
   if (title) title += ' - ';
   if (workspace?.name) title = (title ?? '') + workspace.name;
   useTitle(title, active);

   if (!queryVersion) return <LoadingSpinner />;
   return (
      <ExploreTabContext.Provider value={{ exploreTab }}>
         <QueryPanelProvider>
            <AskRunaProvider>
               <QueryPage active={active} exploreTab={exploreTab} />
            </AskRunaProvider>
         </QueryPanelProvider>
      </ExploreTabContext.Provider>
   );
};

export default ExploreTabContent;
