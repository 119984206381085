import { inject, injectable } from 'inversify';
import { ExploreTab, ExploreTabPatch, ExploreTabPost } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/exploreTab';

export interface ListOptionsExploreTab {
   includeTopLogs?: number;
   workspaceId?: number;
}

export interface GetOptionsExploreTab {}

const filterPayload = (tab: ExploreTab) => {
   if (!tab.queryVersion) return tab;
   const { query, parent, ...queryVersion } = tab.queryVersion;
   return {
      ...tab,
      queryVersion,
   };
};

@injectable()
export class ExploreTabService
   implements ApiServiceInterface<ExploreTab, ExploreTabPost, ExploreTabPatch>
{
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async patch(
      id: string | number,
      body: ExploreTabPatch,
      params?: Record<string, string> | undefined
   ): Promise<ExploreTab | undefined> {
      const result = await this.apiService.patch(`${path}/${id}`, filterPayload(body), params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async post(
      body: ExploreTabPost,
      params?: Record<string, string>
   ): Promise<ExploreTab | undefined> {
      const result = await this.apiService.post<ExploreTab>(
         `${path}/${body.workspaceId}`,
         filterPayload(body),
         params
      );
      if (!result) {
         return undefined;
      }
      return result;
   }

   async put(
      _body: ExploreTab,
      _params?: Record<string, string> | undefined
   ): Promise<ExploreTab | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params?: Record<string, string>): Promise<ExploreTab[]> {
      let result = await this.apiService.get<ExploreTab[]>(path, params);
      if (!result) {
         return [];
      }

      return result.map((item) => item);
   }

   listOptions(options: ListOptionsExploreTab): Promise<ExploreTab[]> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async get(id: number, params?: Record<string, string>): Promise<ExploreTab | undefined> {
      const result = await this.apiService.get<ExploreTab>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   async delete(id: number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }
}
