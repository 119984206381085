import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Button, Form, Spinner, Stack } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { z } from 'zod';

import { AdminNav, Page } from '../components';
import { PersonRole, QueryKey, ROLE_NAMES } from '../enums';
import { useOrg, useOrgService } from '../hooks';
import { handleError, useTitle } from '../utilities';

const formSchema = z.object({
   displayName: z.string(),
   requiredReviewers: z.coerce.number().int().min(0).max(1),
   orgRolePermissions: z.array(
      z.object({
         role: z.nativeEnum(PersonRole),
         csvDownload: z.coerce.boolean(),
      })
   ),
});
type FormData = z.infer<typeof formSchema>;

export const OrgSettingsPage = (): JSX.Element => {
   useTitle('Settings');

   const org = useOrg();
   const {
      handleSubmit,
      register,
      control,
      formState: { errors, touchedFields, dirtyFields },
      reset,
   } = useForm<FormData>({
      resolver: zodResolver(formSchema),
      mode: 'onTouched',
      defaultValues: {
         displayName: '',
         requiredReviewers: 0,
         orgRolePermissions: [
            { role: PersonRole.ORG_ADMIN, csvDownload: true },
            { role: PersonRole.ORG_EXPLORER, csvDownload: true },
         ],
      },
   });

   const { fields: roleOptions } = useFieldArray({
      control,
      name: 'orgRolePermissions',
   });

   useEffect(() => {
      if (org) {
         const formData: FormData = {
            displayName: org.displayName ?? 'Unnamed',
            requiredReviewers: org.requiredReviewers,
            // OrgRolePermissions are not guaranteed to exist, default to true
            orgRolePermissions: Object.keys(PersonRole).map((role) => {
               const rl = PersonRole[role as keyof typeof PersonRole];
               return {
                  role: rl,
                  csvDownload: org.orgRolePermissions
                     ? org.orgRolePermissions.find((permission) => permission.role === rl)
                          ?.csvDownload ?? true
                     : true,
               };
            }),
         };
         reset(formData);
      }
   }, [org, reset]);

   const orgService = useOrgService();
   const queryClient = useQueryClient();
   const updateOrgMutation = useMutation({
      mutationFn: async (data: FormData) => {
         if (!org?.id) return;
         return orgService.patch(org.id, data);
      },
      async onSuccess() {
         reset({}, { keepValues: true });
         queryClient.invalidateQueries([QueryKey.Org, 'list']);
      },
      onError: handleError,
   });

   const onSubmit = (data: FormData) => {
      updateOrgMutation.mutate(data);
   };

   return (
      <Page
         header="Admin"
         nav={<AdminNav />}
         tabs={[
            {
               title: 'Org Settings',
               content: (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                     <div style={{ maxWidth: '400px' }}>
                        <Stack gap={3}>
                           <Form.Group>
                              <Form.Label>Organization Name</Form.Label>
                              <Form.Control
                                 {...register('displayName')}
                                 isInvalid={touchedFields.displayName && !!errors.displayName}
                                 isValid={touchedFields.displayName && !errors.displayName}
                                 max={1}
                                 min={0}
                                 required
                              />
                              <Form.Control.Feedback type="invalid">
                                 {errors.displayName?.message}
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Form.Group>
                              <Form.Label>Required Reviewers</Form.Label>
                              <Form.Text className="d-block mb-2">
                                 The number of reviewers, other than the author, required to certify
                                 a query
                              </Form.Text>
                              <Form.Control
                                 {...register('requiredReviewers')}
                                 isInvalid={
                                    touchedFields.requiredReviewers && !!errors.requiredReviewers
                                 }
                                 isValid={
                                    touchedFields.requiredReviewers && !errors.requiredReviewers
                                 }
                                 max={1}
                                 min={0}
                                 required
                                 type="number"
                              />
                              <Form.Control.Feedback type="invalid">
                                 {errors.requiredReviewers?.message}
                              </Form.Control.Feedback>
                           </Form.Group>
                           <Stack className="justify-content-end" direction="horizontal" gap={3}>
                              <Button
                                 disabled={
                                    Object.keys(dirtyFields).length === 0 ||
                                    updateOrgMutation.isLoading
                                 }
                                 size="sm"
                                 type="submit"
                              >
                                 &nbsp;
                                 {updateOrgMutation.isLoading ? <Spinner size="sm" /> : 'Save'}
                                 &nbsp;
                              </Button>
                           </Stack>
                        </Stack>
                     </div>
                  </Form>
               ),
            },

            {
               title: 'Security Settings',
               content: (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                     <div style={{ maxWidth: '400px' }}>
                        <Stack gap={3}>
                           {roleOptions.map((roleOption, index) => (
                              <Form.Group
                                 controlId={`orgRolePermissions.${index}.csvDownload`}
                                 key={index}
                              >
                                 <Form.Label>{`Allow ${
                                    ROLE_NAMES[roleOption.role]
                                 }  CSV Downloads`}</Form.Label>
                                 <Form.Check
                                    {...register(`orgRolePermissions.${index}.csvDownload`)}
                                    type="checkbox"
                                 />
                                 <Form.Control.Feedback type="invalid">
                                    {errors.orgRolePermissions?.[index]?.csvDownload?.message}
                                 </Form.Control.Feedback>
                              </Form.Group>
                           ))}
                           <Stack className="justify-content-end" direction="horizontal" gap={3}>
                              <Button
                                 disabled={
                                    Object.keys(dirtyFields).length === 0 ||
                                    updateOrgMutation.isLoading
                                 }
                                 size="sm"
                                 type="submit"
                              >
                                 &nbsp;
                                 {updateOrgMutation.isLoading ? <Spinner size="sm" /> : 'Save'}
                                 &nbsp;
                              </Button>
                           </Stack>
                        </Stack>
                     </div>
                  </Form>
               ),
            },
         ]}
      ></Page>
   );
};

export default OrgSettingsPage;
