import { HomeNav, Page, StartingBlockSavedQueries } from '../components';
import { useTitle } from '../utilities';

export const QueryListPage = () => {
   useTitle('Saved Queries');
   return (
      <Page
         bodyPadding={false}
         header="Home"
         nav={<HomeNav />}
         tabs={[
            {
               title: 'My Saved',
               content: <StartingBlockSavedQueries versions="mine" />,
            },
            {
               title: 'All Shared',
               content: <StartingBlockSavedQueries versions="shared" />,
            },
         ]}
      />
   );
};

export default QueryListPage;
