import { memo, useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useListQueryLogs } from '../hooks/entities/queryLogHooks';
import SearchInput from './UI/SearchInput';
import { useDebounce } from '../hooks/debounce';
import { groupLogs } from './../entities';
import { QueryLogWidget } from './QueryWidget';
import Paginator from './UI/Paginator';
import LoadingSpinner from './UI/LoadingSpinner';

const PAGE_SIZE = 10;

export const HistoryLog = memo(({ workspaceId }: { workspaceId?: number }): JSX.Element => {
   const [searchText, setSearchText] = useState('');
   const debouncedSearchText = useDebounce(searchText, 750);
   const [skip, setSkip] = useState(0);

   const cleanAndSetSearchText = (newValue: string) => {
      newValue = newValue.toLowerCase().trim();
      setSearchText(newValue);
      setSkip(0);
   };

   const logQuery = useListQueryLogs({
      listOptions: {
         includeCreatedPerson: true,
         workspaceId,
         newestFirst: true,
         includeDataSource: true,
         take: PAGE_SIZE,
         skip,
         query: debouncedSearchText || undefined,
      },
   });

   const logs =
      useMemo(() => {
         return logQuery.data?.items && groupLogs(logQuery.data?.items);
      }, [logQuery.data?.items]) ?? [];

   if (logQuery.isError) {
      return <>Error</>;
   }

   const loading = searchText !== debouncedSearchText || logQuery.isLoading || logQuery.isFetching;
   return (
      <Stack gap={3}>
         <SearchInput entityName="query log" onTextChanged={cleanAndSetSearchText} />
         {loading && <LoadingSpinner />}
         {!loading && searchText && logs.length === 0 && <div className="">No matches</div>}
         {!loading && !searchText && logs.length === 0 && (
            <div className="fs-11p">
               <span className="">
                  It looks like you haven't run any queries in this workspace yet.
               </span>
               <br />
               Unleash your inner Indiana Jones to uncover the treasure in the Data Temple. Grab
               your hat, crack that whip, and click on Explore to dive into a thrilling adventure!
            </div>
         )}
         {!loading && logs.length > 0 && (
            <>
               {logs.map((logItems) => (
                  <QueryLogWidget key={`${logItems[0].id}`} queryLogs={logItems} />
               ))}
               <div className="mt-2 mb-4 d-flex justify-content-center">
                  <Paginator
                     onChange={(page) => setSkip(page * PAGE_SIZE)}
                     page={Math.floor(skip / PAGE_SIZE)}
                     pageSize={PAGE_SIZE}
                     totalItems={logQuery.data?.totalItems ?? 0}
                  />
               </div>
            </>
         )}
      </Stack>
   );
});

export default HistoryLog;
