import { useState } from 'react';
import { FaDatabase } from 'react-icons/fa';
import {
   MdCable,
   MdKeyboardArrowDown,
   MdKeyboardArrowRight,
   MdTableChart,
   MdTableView,
} from 'react-icons/md';
import { TreeNode } from '../../components/UI/TreeView';
import { BulkUpdateSchemaCache } from '../../services';
import { IconKey } from '../../utilities';

export enum SchemaLevel {
   columnNode = 'columnNode',
   connectionNode = 'connectionNode',
   databaseNode = 'databaseNode',
   tableNode = 'tableNode',
}

export function MetaSchemaTree({
   expandLevels,
   nodeClicked,
   parent,
   pendingUpdates,
   treeData,
   showLevels,
}: {
   expandLevels?: string[];
   nodeClicked?: (node: TreeNode) => void;
   parent?: TreeNode;
   pendingUpdates?: BulkUpdateSchemaCache[];
   showLevels?: string[];
   treeData: TreeNode[] | undefined;
}) {
   if (!treeData) return <></>;

   let treeDataFiltered = treeData;
   if (showLevels) {
      treeDataFiltered = treeData.filter((node) => showLevels.includes(node.type));
   }

   return (
      <ul className="nav flex-column list-unstyled navigator">
         {treeDataFiltered.map((node, i) => (
            <SchemaTreeNode
               expandLevels={expandLevels}
               key={node.key}
               node={node}
               nodeClicked={nodeClicked}
               parent={parent}
               pendingUpdates={pendingUpdates}
               showLevels={showLevels}
            />
         ))}
      </ul>
   );
}

function SchemaTreeNode({
   expandLevels,
   node,
   nodeClicked,
   parent,
   pendingUpdates,
   showLevels,
}: {
   expandLevels?: string[];
   node: TreeNode;
   nodeClicked?: (node: TreeNode) => void;
   parent?: TreeNode;
   pendingUpdates?: BulkUpdateSchemaCache[];
   showLevels?: string[];
}) {
   const [showChildren, setShowChildren] = useState(expandLevels?.includes(node.type) ?? false);
   // Page Functions
   const handleClick = () => {
      setShowChildren(!showChildren);
      nodeClicked?.(node);
   };

   // Render
   let nodeTypeClass;
   let iconTypeHtml;
   if (node.type === 'databaseNode') {
      nodeTypeClass = 'fw-normal ps-0 pb-0 pt-1 fs-12p';
      if (node.defaultFlag === 1) {
         nodeTypeClass = 'fw-600 ps-1 pb-0 pt-1 fs-12p';
      }
   }

   let edited = false;
   if (node.type === 'tableNode') {
      nodeTypeClass = 'fw-normal ps-4 pe-0 pb-0 pt-0 fs-12p';
      pendingUpdates?.forEach((update) => {
         if (update.id === node.id || node.children?.find((child) => child.id === update.id)) {
            nodeTypeClass = 'fw-600 ps-1 pe-0 pb-0 pt-0 fs-12p';
            edited = true;
         }
      });
   }

   if (node.type === 'columnNode') {
      nodeTypeClass = 'fw-normal ps-24p pb-0 pt-0 fs-11p';
      iconTypeHtml = <IconKey style={{ color: 'transparent' }} />;
      if (node.icon === 'PRI') {
         iconTypeHtml = <IconKey style={{ color: '#FFBA08' }} />;
      }
      if (node.icon === 'MUL') {
         iconTypeHtml = <IconKey style={{ color: '#e2e5f1' }} />;
      }
   }

   if (node.type === 'connectionNode') {
      nodeTypeClass = 'fw-normal ps-0 pe-1 pb-0 pt-1 fs-13p';
      if (node.defaultFlag === 1) {
         nodeTypeClass = 'fw-600 ps-0 pe-1 pb-0 pt-1 fs-13p';
      }
   }

   return (
      <li className="nav-item w-100 lh-20" key={node.key + '-li'}>
         <div className="d-flex align-items-center">
            <div className="text-nowrap d-flex align-items-center justify-content-between">
               <a
                  className={`nav-link text-truncate ${nodeTypeClass}`}
                  href="#showChildren"
                  onClick={handleClick}
               >
                  <div className="">
                     {node.type === 'databaseNode' ? (
                        <>
                           <span className="text-muted ps-8p">
                              {showChildren ? (
                                 <MdKeyboardArrowDown size={14} />
                              ) : (
                                 <MdKeyboardArrowRight size={14} />
                              )}
                           </span>
                           <span className="text-muted">
                              <FaDatabase className="me-1" />
                           </span>
                        </>
                     ) : node.type === 'tableNode' ? (
                        <span className="text-muted">
                           {node.isView ? <MdTableView /> : <MdTableChart />}&nbsp;
                        </span>
                     ) : node.type === 'columnNode' ? (
                        <span className="text-muted">{iconTypeHtml}</span>
                     ) : node.type === 'connectionNode' ? (
                        <>
                           <span className="text-muted">
                              <MdKeyboardArrowDown size={14} />
                           </span>
                           <span className="text-muted">
                              <MdCable />
                           </span>
                        </>
                     ) : (
                        <span className="text-muted"></span>
                     )}
                  </div>
                  {node.label}
                  {edited && <span className="badge bg-warning ms-1">Edited</span>}
               </a>
            </div>
         </div>
         {showChildren && (
            <MetaSchemaTree
               expandLevels={expandLevels}
               nodeClicked={nodeClicked}
               parent={node}
               pendingUpdates={pendingUpdates}
               showLevels={showLevels}
               treeData={node.children}
            />
         )}
      </li>
   );
}
