import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { WorkspaceCollaborator } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   GetOptionsWorkspaceCollab,
   ListOptionsWorkspaceCollab,
   WorkspaceCollabBulkPayload,
   WorkspaceCollaboratorService,
} from '../../services';
import { TYPES } from '../../types';

const useWorkspaceCollabService = () => {
   return useInjection<WorkspaceCollaboratorService>(TYPES.workspaceCollaboratorService);
};

export const useListWorkspaceCollabQuery = ({
   callbacks,
   options,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: WorkspaceCollaborator[]) => void;
   };
   options?: ListOptionsWorkspaceCollab;
} = {}) => {
   const service = useWorkspaceCollabService();
   return useQuery<WorkspaceCollaborator[]>(
      getWorkspaceCollaboratorQueryKey({ type: QueryKeyType.LIST, options }),
      () => service.listOptions(options),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onError: callbacks?.onError,
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const useGetExplorerCollabForWorkspace = ({
   callbacks,
   queryOptions,
   workspaceId,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: WorkspaceCollaborator | undefined) => void;
   };
   queryOptions?: { enabled?: boolean };
   workspaceId?: number;
}) => {
   const service = useWorkspaceCollabService();
   return useQuery<WorkspaceCollaborator | undefined>(
      ['explorerCollabForWorkspace', workspaceId],
      () => service.getByWorkspace(workspaceId!),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         enabled: workspaceId !== undefined && (queryOptions?.enabled ?? true),
         onError: callbacks?.onError,
         onSuccess: callbacks?.onSuccess,
      }
   );
};

export const useExplorerWorkspaceRole = (workspaceId?: number) =>
   useGetExplorerCollabForWorkspace({ workspaceId }).data?.role;

export const useCreateWorkspaceCollabMutator = (options?: ListOptionsWorkspaceCollab) => {
   const queryClient = useQueryClient();
   const service = useWorkspaceCollabService();
   return useMutation({
      mutationFn: async (newCollab: WorkspaceCollaborator) => {
         return await service.post(newCollab);
      },
      async onMutate(newCollab) {
         const listQueryKey = getWorkspaceCollaboratorQueryKey({
            type: QueryKeyType.LIST,
            options,
         });
         await queryClient.cancelQueries(listQueryKey);

         const previousData = queryClient.getQueryData<WorkspaceCollaborator[]>(listQueryKey);
         if (previousData) {
            queryClient.setQueryData<WorkspaceCollaborator[]>(listQueryKey, [
               ...previousData,
               newCollab,
            ]);
         }
         return { listQueryKey, previousData };
      },
      onError(error, newCollab, context) {
         if (context) queryClient.setQueryData(context.listQueryKey, context.previousData);
      },
      onSettled(data, error, newCollab, context) {
         if (context) queryClient.invalidateQueries(context.listQueryKey);
         queryClient.invalidateQueries([QueryKey.EligibleCollaborators, newCollab.workspaceId]);
      },
   });
};

export const useBulkUpdateWorkspaceCollabMutator = (callbacks?: {
   onError?: (
      error: unknown,
      variables: WorkspaceCollabBulkPayload,
      context: unknown
   ) => void | Promise<unknown>;
   onSuccess?: (
      data: {} | undefined,
      variables: WorkspaceCollabBulkPayload,
      context: unknown
   ) => void | Promise<unknown>;
}) => {
   const service = useWorkspaceCollabService();
   return useMutation({
      mutationFn: async (payload: WorkspaceCollabBulkPayload) => {
         return await service.bulkUpdate(payload);
      },
      onSuccess: callbacks?.onSuccess,
      onError: callbacks?.onError,
   });
};

export const useDeleteWorkspaceCollabMutator = () => {
   const queryClient = useQueryClient();
   const service = useWorkspaceCollabService();
   return useMutation({
      mutationFn: async (id: number) => {
         return service.delete(id);
      },
      async onSuccess(data, id, context) {
         await Promise.all([
            queryClient.invalidateQueries(
               getWorkspaceCollaboratorQueryKey({ type: QueryKeyType.GET, id })
            ),
            queryClient.invalidateQueries(
               getWorkspaceCollaboratorQueryKey({ type: QueryKeyType.LIST })
            ),
            queryClient.invalidateQueries([QueryKey.EligibleCollaborators]),
         ]);
      },
   });
};

export function getWorkspaceCollaboratorQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsWorkspaceCollab | GetOptionsWorkspaceCollab;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.WorkspaceCollaborator, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
