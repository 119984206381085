import { Form } from 'react-bootstrap';

const SearchInput = ({
   onTextChanged,
   entityName,
}: {
   entityName?: string;
   onTextChanged?(newText: string): void;
}): JSX.Element => {
   return (
      <Form.Control
         className="form-control ps-2 pe-2 pt-2 pb-2"
         onChange={(event) => onTextChanged && onTextChanged(event.currentTarget.value)}
         placeholder={`Search or Ask a question`}
         type="text"
      />
   );
};

export default SearchInput;
