import { Alert, Button, Card, Col, Modal, Row, Form } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { WorkspaceLogItem } from '../entities/WorkspaceLog';
import { TYPES } from '../types';
import { useInjection } from 'inversify-react';
import { WorkspaceLogService } from '../services';
import { QueryKey } from '../enums';
import { getShortDateTimeString } from '../utilities';
import SearchInput from './UI/SearchInput';

export const WorkspaceLog = (props: { workspaceId?: number }) => {
   const [logs, setLogs] = useState<WorkspaceLogItem[] | undefined>([]);
   const [filtered, setFiltered] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [action, setAction] = useState('');
   const [type, setType] = useState('');
   const [startDate, setStartDate] = useState<string | undefined>();
   const [endDate, setEndDate] = useState<string | undefined>();
   const [searchText, setSearchText] = useState('');
   const cleanAndSetSearchText = (newValue: string) => {
      newValue = newValue.toLowerCase().trim();
      setSearchText(newValue);
   };
   const api = useInjection<WorkspaceLogService>(TYPES.workspaceLogService);
   const query = useQuery<WorkspaceLogItem[]>(
      [QueryKey.WorkspaceLog, props.workspaceId],
      async () => {
         if (props.workspaceId) return api.list({ workspaceId: props.workspaceId.toString() });
         else return api.list();
      },
      { onError: (e) => setError(`${e}`), retry: false }
   );
   const [error, setError] = useState('');

   useEffect(() => {
      if (query.data) {
         setLogs(query.data);
      }
   }, [query.data]);

   useEffect(() => {
      const filterByText = (value: WorkspaceLogItem, index: number, array: WorkspaceLogItem[]) => {
         if (value.workspace?.toLowerCase().includes(searchText)) {
            return true;
         }
         if (value.what?.toLowerCase().includes(searchText)) {
            return true;
         }
         if (value.firstName?.toLowerCase().includes(searchText)) {
            return true;
         }
         if (value.lastName?.toLowerCase().includes(searchText)) {
            return true;
         }
      };

      if (searchText.length >= 1) {
         setLogs(query.data?.filter(filterByText));
      } else if (searchText.length < 1) {
         setLogs(query.data);
      }
   }, [searchText, query.data]);

   const filterByAction = (value: WorkspaceLogItem) => {
      if (action === '') {
         return true;
      }
      if (action === 'Created') {
         if (value?.action === 'Created') {
            setFiltered(true);
            return true;
         }
      }
      if (action === 'Modified') {
         if (value?.action === 'Modified') {
            setFiltered(true);
            return true;
         }
      }
      if (action === 'Deleted') {
         if (value?.action === 'Deleted') {
            setFiltered(true);
            return true;
         }
      }
   };

   const filterByType = (value: WorkspaceLogItem) => {
      if (type === '') {
         return true;
      }
      if (type === 'Saved Query') {
         if (value?.type === 'Saved Query') {
            setFiltered(true);
            return true;
         }
      }
      if (type === 'Workspace') {
         if (value?.type === 'Workspace') {
            setFiltered(true);
            return true;
         }
      }
   };

   const filterByDate = (value: WorkspaceLogItem) => {
      if (startDate && endDate) {
         if (
            new Date(startDate) <= new Date(value?.date) &&
            new Date(value?.date) <= new Date(endDate)
         ) {
            setFiltered(true);
            return true;
         }
      }
   };

   const disableSubmit = () => {
      if (startDate !== undefined) {
         if (endDate === undefined) {
            return true;
         }
      }
      if (endDate !== undefined) {
         if (startDate === undefined) {
            return true;
         }
      }
   };

   return (
      <>
         <Modal
            onHide={() => {
               setAction('');
               setType('');
               setStartDate(undefined);
               setEndDate(undefined);
               setShowModal(false);
            }}
            show={showModal}
         >
            <Modal.Header closeButton>
               <Modal.Title className="fs-14p">Advanced Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form.Label className="fw-normal">Action</Form.Label>
               <Form.Select
                  aria-label="Select action"
                  onChange={(event) => {
                     setAction(event.currentTarget.value);
                  }}
                  value={action}
               >
                  <option>Select action</option>
                  <option value="Created">Created</option>
                  <option value="Modified">Modified</option>
                  <option value="Deleted">Deleted</option>
               </Form.Select>
               <Form.Label className="fw-normal mt-3">Type</Form.Label>
               <Form.Select
                  aria-label="Select type"
                  onChange={(event) => {
                     setType(event.currentTarget.value);
                  }}
                  value={type}
               >
                  <option>Select action</option>
                  <option value="Saved Query">Saved</option>
                  <option value="Workspace">Workspace</option>
               </Form.Select>
               <Row>
                  <Col xs={6}>
                     <Form.Label className="fw-normal mt-3">Start Date</Form.Label>
                     <Form.Control
                        id="startDate"
                        onChange={(event) => setStartDate(event.currentTarget.value)}
                        required={endDate !== undefined}
                        type="date"
                        value={startDate}
                     />
                     {startDate === undefined && endDate !== undefined && (
                        <Form.Text className="text-muted">Start date required</Form.Text>
                     )}
                  </Col>
                  <Col xs={6}>
                     <Form.Label className="fw-normal mt-3">End Date</Form.Label>
                     <Form.Control
                        id="endDate"
                        onChange={(event) => setEndDate(event.currentTarget.value)}
                        required={startDate !== undefined}
                        type="date"
                        value={endDate}
                     />
                     {endDate === undefined && startDate !== undefined && (
                        <Form.Text className="text-muted">End date required</Form.Text>
                     )}
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer className="border-0">
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                     setShowModal(false);
                     setAction('');
                     setType('');
                     setStartDate(undefined);
                     setEndDate(undefined);
                  }}
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={disableSubmit()}
                  onClick={() => {
                     setLogs(
                        query.data?.filter(filterByAction).filter(filterByType).filter(filterByDate)
                     );
                     setShowModal(false);
                     setAction('');
                     setType('');
                     setStartDate(undefined);
                     setEndDate(undefined);
                  }}
                  type="submit"
                  variant="primary"
               >
                  Search
               </Button>
            </Modal.Footer>
         </Modal>
         <Row>
            <Col xs={12}>
               <Row>
                  <Col xs={2}>
                     <div className="position-relative">
                        <SearchInput onTextChanged={cleanAndSetSearchText} />
                     </div>
                  </Col>
                  <Col xs={2}>
                     <Button
                        className="fs-12p ps-0"
                        onClick={() => setShowModal(true)}
                        variant="link"
                     >
                        Advanced Search
                     </Button>
                  </Col>
               </Row>
            </Col>
            <Col className="d-flex justify-content-end" xs={10}></Col>
            <Col className="mb-2" xs={12} />
         </Row>
         <Row>
            <Col className="mt-2" xs={12}></Col>
            {/* Table headers */}
            <Col xs={12}>
               <Row>
                  <Col xs={12}>
                     <Card className="custom-card-transparent border-0 ps-4 pe-4 pb-1 pt-1">
                        <Row className="justify-content-center">
                           <Col xs={2}>
                              <span className="fs-10p">Workspace</span>
                           </Col>
                           <Col xs={3}>
                              <span className="fs-10p">Query Name</span>
                           </Col>
                           <Col xs={1}>
                              <span className="fs-10p">Action</span>
                           </Col>
                           <Col xs={2}>
                              <span className="fs-10p">Type</span>
                           </Col>
                           <Col xs={2}>
                              <span className="fs-10p">Date</span>
                           </Col>
                           <Col xs={2}>
                              <span className="fs-10p">Modified By</span>
                           </Col>
                        </Row>
                     </Card>
                  </Col>
               </Row>
            </Col>
            <Col xs={12}>
               {query.isSuccess &&
                  logs?.map((item, i) => (
                     <Card
                        className="other-cards border-0 mt-2 mb-2 p-4 shadow-sm"
                        key={`${i}-workspace-log`}
                     >
                        <Row className="fs-12p align-items-center">
                           <Col xs={2}>{item.workspace}</Col>
                           <Col xs={3}>{item.type === 'Saved Query' ? item.what : '-'}</Col>
                           <Col xs={1}>
                              <span className={'badge custom-badge-' + item.action.toLowerCase()}>
                                 {item.action}
                              </span>
                           </Col>
                           <Col xs={2}>
                              <span className={'badge custom-badge-' + item.type.toLowerCase()}>
                                 {item.type}
                              </span>
                           </Col>
                           <Col className="fs-10p" xs={2}>
                              {getShortDateTimeString(new Date(item.date))}
                           </Col>
                           <Col xs={2}>
                              {item.firstName} {item.lastName}
                           </Col>
                        </Row>
                     </Card>
                  ))}
               {error && <Alert variant="danger">{error}</Alert>}
            </Col>
         </Row>
         {filtered && (
            <Row className="d-flex justify-content-center">
               <Button
                  className="fs-14p fw-normal"
                  onClick={() => {
                     setFiltered(false);
                     setLogs(query.data);
                  }}
                  style={{ width: '100px', textAlign: 'center' }}
                  variant="link"
               >
                  View all
               </Button>
            </Row>
         )}
      </>
   );
};

export default WorkspaceLog;
