import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';

const path = '/v1/subscription';

// Sync with api/src/utilities/stripe.ts
export type Plan = {
   id: number;
   name: string;
   price: number; // USD cents
};

export type Plans = {
   plans: Plan[];
   stripeKey: string;
};

export type Pricing = {
   dueMonthly: number; // USD cents
   dueNow: number; // USD cents
};

@injectable()
export class SubscriptionService {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async listPlans(): Promise<Plans> {
      const result = await this.apiService.get<Plans>(`${path}/plan/`);
      if (!result) throw new Error('Failed to get plans');
      return result;
   }

   async getPricing(plan: number, seats: number): Promise<Pricing | null> {
      try {
         return await this.apiService.get(`${path}/pricing`, {
            plan: plan.toString(),
            seats: seats.toString(),
         });
      } catch (e) {
         console.error(e);
         return null;
      }
   }

   async changePlan(plan: number) {
      return await this.apiService.post(path, { plan });
   }

   async getSeats(): Promise<number> {
      const result = await this.apiService.get<{ seatCount: number }>(`${path}/getSeats`);
      if (!result) {
         return 0;
      }
      return result.seatCount;
   }
}
