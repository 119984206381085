import BusinessPageQueries from './BusinessPageQueries';
import { Page } from '../../components';

export const BusinessPage = (): JSX.Element => {
   return (
      <Page>
         <BusinessPageQueries />
      </Page>
   );
};

export default BusinessPage;
