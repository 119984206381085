import { ColumnNull, DBMS } from '@runql/util';
import { z } from 'zod';

const mysqlColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.MySQL),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnExtra: z.string().optional(),
         columnComment: z.string().optional(),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const redshiftColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.Redshift),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnComment: z.string().optional(),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const mssqlColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.MSSQL),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnDescription: z.string().optional(),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const snowflakeColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.Snowflake),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnDescription: z.string().optional().default(''),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});
const databricksColumnsSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.Databricks),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnComment: z.string().optional().default(''),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

const oracleColumnSchema = z.object({
   id: z.number(),
   dbms: z.literal(DBMS.Oracle),
   table: z.string(),
   columns: z.array(
      z.object({
         runqlId: z.number().optional(),
         columnName: z.string().min(1),
         columnType: z.string().min(1),
         columnNullable: z.nativeEnum(ColumnNull),
         columnDefault: z.string().optional().default(''),
         columnDescription: z.string().optional().default(''),
         columnIsPrimaryKey: z.boolean().default(false),
         markDeleted: z.boolean().default(false),
         isNew: z.boolean().default(false),
      })
   ),
});

export const columnSchema = z.discriminatedUnion('dbms', [
   mysqlColumnSchema,
   mssqlColumnSchema,
   snowflakeColumnSchema,
   redshiftColumnSchema,
   oracleColumnSchema,
   databricksColumnsSchema,
]);

export type ColumnFormData = z.infer<typeof columnSchema>;
