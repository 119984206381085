import { Button, Spinner } from 'react-bootstrap';
import { BiChevronRight } from 'react-icons/bi';

export const RunButton = ({
   children,
   disabled,
   onClick,
   running,
}: {
   children?: React.ReactNode;
   disabled?: boolean;
   onClick?: () => void;
   running?: boolean;
}): JSX.Element => {
   return (
      <Button
         disabled={disabled}
         onClick={onClick}
         size="sm"
         style={{ minWidth: '70px' }}
         variant="dark"
      >
         {running ? (
            <>
               &nbsp;
               <Spinner animation="border" aria-hidden="true" as="span" role="status" size="sm" />
               &nbsp;
            </>
         ) : (
            <>
               <BiChevronRight size={16} />
               {children ?? 'Run'}
            </>
         )}
      </Button>
   );
};
