import { Section } from '../DocumentationPage';
// Connection Instructions for Each DBMS

const bigQueryConnection: string = `
#### BigQuery

To connect to BigQuery, follow these steps:
1. **Create a Service Account**: Ensure you have a Google Cloud service account with the necessary permissions.
2. **Obtain Credentials**: Download the JSON key file for the service account.
3. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **BigQuery** from the list of DBMS options.
   - Enter the connection name, project ID, and other required details.
   - Upload the JSON key file when prompted.
4. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your BigQuery instance.
`;

const databricksConnection: string = `
#### Databricks

To connect to Databricks, follow these steps:
1. **Generate a Personal Access Token**: In your Databricks account, generate an Auth Token with the necessary permissions.
2. **Find/Create a cluster to connect**: Find or create a new cluster, and navigate to Advanced Options > JDBC/ODBC to obtain the necessary host and path
3. **Configure runQL**:
   - Navigate to **Add Connection** in your runQL organization.
   - Select **Databricks** from the list of DBMS options.
   - Enter the connection name, Databricks workspace URL, and other required details.
   - Input the generated access username, token, hostname, and path. 
4. **Test Connection**: Click **Test Connection** to verify the connection.
`;

const mssqlConnection: string = `
#### MSSQL

To connect to MSSQL, follow these steps:
1. **Gather Connection Details**: Obtain the server address, port, username, and password.
2. **Database Name (Optional)**: You may optionally add the database name you would like to be set as the default when writing queries.
3. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **MSSQL** from the list of DBMS options.
   - Enter the connection name, server address, port, and database name.
   - Provide the username and password.
5. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your MSSQL database.
`;

const mongodbConnection: string = `
#### MongoDB

To connect to MongoDB, follow these steps:
1. **Obtain Connection URI**: Ensure you have the MongoDB connection URI, which includes the username, password, and host details.
2. **Configure runQL**:
   - Navigate to **Add Connection** in your runQL organization.
   - Select **MongoDB** from the list of DBMS options.
   - Enter the connection name and paste the MongoDB URI.
3. **Specify Database (Optional)**: If you want to connect to a specific database within MongoDB, specify it in the configuration.
4. **Test Connection**: Click **Test Connection** to verify the connection.
`;

const mysqlConnection: string = `
#### MySQL

To connect to MySQL, follow these steps:
1. **Gather Connection Details**: Obtain the server address, port, database name, username, and password.
2. **Database Name (Optional)**: You may optionally add the database name you would like to be set as the default when writing queries.
3. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **MySQL** from the list of DBMS options.
   - Enter the connection name, server address, port, and database name.
   - Provide the username and password.
4. **Enable SSL (Optional)**: If your MySQL server requires SSL, ensure that the SSL option is enabled and configure the necessary certificates.
5. **Enable SSH (Optional)**: If your MySQL server requires SSH, you may submit the SSH Host, Port, Username, and Password instead.
6. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your MySQL database.
`;

const neo4jConnection: string = `
#### Neo4j

To connect to Neo4j, follow these steps:
1. **Gather Connection Details**: Obtain the host, port, database name, username, and password.
2. **Database Name (Optional)**: You may optionally add the database name you would like to be set as the default when writing queries.
3. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **MySQL** from the list of DBMS options.
   - Enter the connection name, server address, port, and database name.
   - Provide the username and password.
6. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your MySQL database.
`;

const oracleConnection: string = `
#### Oracle

To connect to Oracle, follow these steps:
1. **Gather Connection Details**: Obtain the host address, port, service name or SID, username, and password.
2. **Install Oracle Client (If Required)**: Ensure that the Oracle client libraries are installed on the machine where runQL is hosted.
3. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **Oracle** from the list of DBMS options.
   - Enter the connection name, host address, port, and service name/SID.
   - Provide the username and password.
4. **Enable SSL (Optional)**: If your Oracle server requires SSL, configure the SSL settings accordingly.
5. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your Oracle database.
`;

const postgresConnection: string = `
#### Postgres

To connect to Postgres, follow these steps:
1. **Gather Connection Details**: Obtain the host address, port, database name, username, and password.
2. **Configure runQL**:
   - Navigate to **Add Connection** in your runQL organization.
   - Select **Postgres** from the list of DBMS options.
   - Enter the connection name, host address, port, and database name.
   - Provide the username and password.
3. **Enable SSL (Optional)**: If your Postgres server requires SSL, ensure that the SSL option is enabled and configure the necessary certificates.
4. **Test Connection**: Click **Test Connection** to verify the connection.
`;

const redshiftConnection: string = `
#### Redshift

To connect to Amazon Redshift, follow these steps:
1. **Gather Connection Details**: Obtain the Redshift cluster endpoint, port, database name, username, and password.
2. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **Redshift** from the list of DBMS options.
   - Enter the connection name, cluster endpoint, port, and database name.
   - Provide the username and password.
3. **Enable SSL (Optional)**: If your Redshift cluster requires SSL, ensure that the SSL option is enabled and configure the necessary certificates.
4. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your Redshift cluster.
`;

const snowflakeConnection: string = `
#### Snowflake

To connect to Snowflake, follow these steps:
1. **Gather Connection Details**: Obtain your Snowflake account identifier, username, password, role, and warehouse.
2. **Database Name (Optional)**: You may optionally add the database name you would like to be set as the deault when writing queries.
3. **Configure runQL**:
   - Navigate to **Add Connection** in your runQL organization.
   - Select **Snowflake** from the list of DBMS options.
   - Enter the connection name and account identifier.
   - Provide the username, password, role, warehouse, database, and schema.
4. **Enable SSL (Optional)**: Snowflake connections are encrypted by default, but ensure that any additional SSL settings are configured if required.
5. **Test Connection**: Click **Test Connection** to verify the connection.
`;

const trinoConnection: string = `
#### Trino

To connect to Trino, follow these steps:
1. **Gather Connection Details**: Obtain the Trino coordinator host address, port, catalog, and schema.
2. **Configure runQL**:
   - Go to **Add Connection** in your runQL organization.
   - Select **Trino** from the list of DBMS options.
   - Enter the connection name, coordinator host, port, catalog, and schema.
   - Provide any required authentication details.
3. **Enable SSL (Optional)**: If your Trino coordinator requires SSL, ensure that the SSL option is enabled and configure the necessary certificates.
4. **Test Connection**: Click **Test Connection** to ensure that runQL can successfully connect to your Trino instance.
`;

const sharedDbConnection: string = `
#### What is a Shared Connection?

A Shared Connection is for your databases that use one set of credentials. This connection cannot be edited by others and the credentials for this connection cannot be viewed by others unless they are an organizational admin.

Additionally, an admin can set up a Shared Connection with the "hidden details" option enabled, which also hides the server hostname/IP and Port from being viewed by others in your organization.
`;

export const dataConnectionQuestions: Section[] = [
   {
      question: 'BigQuery',
      answer: bigQueryConnection,
      group: 'Data Connections',
   },
   {
      question: 'Databricks',
      answer: databricksConnection,
      group: 'Data Connections',
   },
   {
      question: 'MSSQL',
      answer: mssqlConnection,
      group: 'Data Connections',
   },
   {
      question: 'MongoDB',
      answer: mongodbConnection,
      group: 'Data Connections',
   },
   {
      question: 'MySQL',
      answer: mysqlConnection,
      group: 'Data Connections',
   },
   {
      question: 'Neo4j',
      answer: neo4jConnection,
      group: 'Data Connections',
   },
   {
      question: 'Oracle',
      answer: oracleConnection,
      group: 'Data Connections',
   },
   {
      question: 'Postgres',
      answer: postgresConnection,
      group: 'Data Connections',
   },
   {
      question: 'Redshift',
      answer: redshiftConnection,
      group: 'Data Connections',
   },
   {
      question: 'Snowflake',
      answer: snowflakeConnection,
      group: 'Data Connections',
   },
   {
      question: 'Trino',
      answer: trinoConnection,
      group: 'Data Connections',
   },
   {
      question: 'What is a Shared Database or LLM Connection?',
      answer: sharedDbConnection,
      group: 'Data Connections',
   },
];
