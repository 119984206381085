import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { NewInvite, Org } from '../entities';
import { PersonRole, ROLE_NAMES } from '../enums';
import { useCreateInviteMutator, useGetAuthorizedExplorerQuery } from '../hooks';
import { handleError } from '../utilities';
import { SERVICE_PLANS } from '@runql/util';

const AddAccountModal = (props: {
   admin: boolean;
   onClose(): void;
   onSubmitSuccess(): void;
   org: Org | undefined;
   plan: number | undefined;
   setShowUpgradeModal(): void;
   show: boolean;
   showUpgradeModal: boolean;
}): JSX.Element => {
   //State variables
   const [firstName, setFirstName] = useState<string>('');
   const [lastName, setLastName] = useState<string>('');
   const [email, setEmail] = useState<string>('');
   const [role, setRole] = useState('ORG_EXPLORER');

   const planUsers = SERVICE_PLANS[props.plan ?? 0]?.users;
   const orgPeopleCount =
      props.org?.people?.filter((person) => person.role !== 'orgExplorer').length ?? 0;
   const isUserLimitReached = planUsers ? orgPeopleCount >= planUsers : false;

   //Queries
   const authedExplorerQuery = useGetAuthorizedExplorerQuery();
   const authedExplorer = authedExplorerQuery.data;

   //Mutators
   const newInviteMutator = useCreateInviteMutator({
      onSuccessCallback(newInvite, variables, context) {
         resetForm();
         props.onSubmitSuccess();
      },
   });

   //Page Functions
   const resetForm = () => {
      setEmail('');
      setRole('ORG_EXPLORER');
   };

   const formSubmit = async () => {
      //TODO: form validation
      const newInvite: NewInvite = {
         email: email,
         firstName: firstName.length > 0 ? firstName : undefined,
         lastName: lastName.length > 0 ? lastName : undefined,
         role: PersonRole[role as keyof typeof PersonRole],
      };

      try {
         const result = await newInviteMutator.mutateAsync(newInvite);
         if (result !== undefined) {
            toast.success(`An invite has been sent to ${email}`);
         } else {
            toast.error(
               <span>
                  There was an error adding this user. Please contact support at{' '}
                  <a href="mailto:support@runql.com">support@runql.com</a>.
               </span>
            );
         }
      } catch (err) {
         handleError(err);
      }
   };
   return (
      <Form>
         <Modal centered={true} onHide={() => props.onClose()} show={props.show}>
            <Modal.Header className="border-0" closeButton={true}>
               Add Account
            </Modal.Header>
            <Modal.Body as="span" className="fs-s workspaceDescription">
               <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                     onChange={(event) => setFirstName(event.currentTarget.value)}
                     placeholder="First Name"
                     required={false}
                     value={firstName}
                  />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                     onChange={(event) => setLastName(event.currentTarget.value)}
                     placeholder="Last Name"
                     required={false}
                     value={lastName}
                  />
               </Form.Group>
               <Form.Group className="mb-3">
                  <Form.Label>
                     Email <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                     onChange={(event) => setEmail(event.currentTarget.value)}
                     placeholder="Email"
                     required={true}
                     value={email}
                  />
               </Form.Group>
               {authedExplorer?.person.role === PersonRole.ORG_ADMIN ? (
                  <Form.Group className="mb-3">
                     <Form.Label>
                        Role <span className="text-danger">*</span>
                     </Form.Label>
                     <Form.Select
                        aria-label="Role Select"
                        onChange={(event) => {
                           setRole(event.target.value);
                        }}
                        value={role}
                     >
                        {Object.entries(PersonRole)
                           .filter((role) => props.admin)
                           .filter((role) =>
                              props.org?.people && isUserLimitReached
                                 ? role[1] === PersonRole.ORG_EXPLORER
                                 : true
                           )
                           .map((role) => (
                              <option key={role[0]} value={role[0]}>
                                 {ROLE_NAMES[role[1]]}
                              </option>
                           ))}
                     </Form.Select>
                  </Form.Group>
               ) : (
                  <></>
               )}
               {props.org?.people && isUserLimitReached && (
                  <div
                     className="pt-2"
                     style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}
                  >
                     You’ve reached the team member limit for this workspace. To add more,{' '}
                     <Button
                        onClick={() => {
                           props.onClose();
                           props.setShowUpgradeModal();
                        }}
                        style={{
                           marginBottom: 2,
                           backgroundColor: 'transparent',
                           border: 'none',
                           color: 'inherit',
                           padding: 0,
                           margin: 0,
                           textDecoration: 'underline',
                           cursor: 'pointer',
                           fontSize: '12px',
                        }}
                     >
                        Click here
                     </Button>{' '}
                     to upgrade.
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                     props.onClose();
                     resetForm();
                  }}
                  variant="secondary"
               >
                  Cancel
               </Button>
               <Button
                  className="btn btn-sm btn-primary"
                  disabled={email.length === 0}
                  onClick={formSubmit}
                  variant="primary"
               >
                  Create
               </Button>
            </Modal.Footer>
         </Modal>
      </Form>
   );
};

export default AddAccountModal;
