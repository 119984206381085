import { injectable } from 'inversify';

export class SeeOther extends Error {
   constructor(details: object) {
      super('See Other');
      this.details = details;
   }

   public details: any;
}

export class ApiError extends Error {
   constructor(public status: number, message: string) {
      super(message);
   }
}

@injectable()
export class ApiService {
   public readonly endpoint: string;

   constructor() {
      if (!process.env.REACT_APP_API_URI) {
         throw Error('API endpoint is undefined');
      }
      this.endpoint = process.env.REACT_APP_API_URI;

      console.debug(`The API endpoint is '${this.endpoint}'.`);
   }

   get<T>(
      route: string,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch(route, 'GET', params, headers);
   }

   post<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'POST', params, headers, body);
   }

   put<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'PUT', params, headers, body);
   }

   patch<T>(
      route: string,
      body: any,
      params?: Record<string, string>,
      headers?: Record<string, string>
   ): Promise<T | null> {
      return this.fetch<T>(route, 'PATCH', params, headers, body);
   }

   delete<T>(
      route: string,
      params?: Record<string, string>,
      headers?: Record<string, string>,
      body?: any
   ): Promise<T | null> {
      return this.fetch<T>(route, 'DELETE', params, headers, body);
   }

   private async fetch<T>(
      route: string,
      method: string,
      params?: Record<string, string>,
      headers?: Record<string, string>,
      body?: any
   ): Promise<T | null> {
      const url = new URL(route, this.endpoint);

      url.search = new URLSearchParams(params).toString();

      if (!headers) {
         headers = {};
      }

      let requestBody = body;
      if (body) {
         if (body instanceof FormData) {
            requestBody = body;
         } else {
            requestBody = JSON.stringify(body);

            const contentType = headers['Content-Type'];
            if (!contentType) {
               headers['Content-Type'] = 'application/json';
            }
         }
      }

      const response = await fetch(url, {
         method,
         body: requestBody,
         headers,
         credentials: 'include',
      });

      if (response.status === 401) {
         const redirectTo = window.location.pathname;
         window.history.replaceState({ redirectTo }, '', `/signin`);
         window.location.reload();
         return null;
      } else if (response.status === 303) {
         throw new SeeOther(await response.json());
      } else if (!response.ok) {
         let message = undefined;
         try {
            const responseJson = await response.json();
            if (responseJson.validationResult && responseJson.validationResult.length > 0) {
               // include validation messages in error message
               message = `${responseJson.message}: `;
               message += responseJson.validationResult.map((result: any) => result.msg).join('\n');
            } else {
               message = responseJson.message;
            }
         } catch (e) {} // Wasn't JSON.
         console.error(
            `API request failed with status '${response.status}' and message '${
               message ?? response.statusText
            }'.`
         );
         throw new ApiError(response.status, message ?? response.statusText);
      }

      if (response.status === 204 || response.headers.get('Content-Length') === '0') {
         return null;
      }

      const contentType = response.headers.get('Content-Type');
      if (!contentType || contentType.startsWith('application/json')) {
         const data = await response.json();
         return data as T;
      }
      return (await response.text()) as T;
   }

   toFormData(obj: Record<string, any>, formData = new FormData(), parentKey = '') {
      for (const key in obj) {
         if (obj.hasOwnProperty(key)) {
            const fullKey = parentKey ? `${parentKey}[${key}]` : key;
            const value = obj[key];
            if (value === undefined) continue;

            if (typeof value === 'boolean') {
               formData.append(fullKey, value ? 'true' : 'false');
            } else if (value instanceof Date) {
               formData.append(fullKey, value.toISOString());
            } else if (value instanceof Array) {
               value.forEach((item, index) => {
                  const arrayKey = `${fullKey}[${index}]`;
                  if (typeof item === 'object' && item !== null) {
                     this.toFormData(item, formData, arrayKey);
                  } else {
                     formData.append(arrayKey, item);
                  }
               });
            } else if (value instanceof Object && !(value instanceof File)) {
               this.toFormData(value, formData, fullKey);
            } else {
               formData.append(fullKey, value);
            }
         }
      }
      return formData;
   }
}
