import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';

const PATH = '/v1/feature';

@injectable()
export class FeatureService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   async get(): Promise<Record<string, boolean>> {
      try {
         return (await this.apiService.get(`${PATH}/`)) ?? {};
      } catch (error) {
         console.error(error);
         return {};
      }
   }
}
