import { Section } from '../DocumentationPage';

const whatIsRunSource: string = `
#### What is runQL's Virtual Query Service (AKA runSource)?

runSource is a Virtual Query Service that acts as a bridge between your database(s) and your external services that need data. This can be used for external services like Metabase, PowerBi, etc. With our Virtual Query Service you can:

1. Have one source of truth for your queries that can be used in external services.
2. Shield your external services from changes to your underlying query.
3. Provide secured, logged access to your data while hiding your real database credentials from your external services.

###### Example:

Sara is a Data Analyst at ACME Inc. and they have one hundred+ dashboards in their BI tool. Twenty of the dashboards have one area that displays the top ten \`invoiceAmounts\` for the region that is selected on the BI dashboard. The executives check it every Monday at 3pm.

Sara's query for the top ten \`invoiceAmounts\` is:

\`\`\`
SELECT invoiceId, invoiceAmount, customerName FROM customers WHERE region = {{location}} ORDER BY invoiceAmount DESC LIMIT 10;
\`\`\`

The Virtual Query generated by runQL for the above query is:

\`\`\`
SELECT * FROM runQL WHERE runSource='[generatedToken]' AND location=?
\`\`\`

Sara pastes this virtual query into ACME's BI tool for the twenty dashboards.

It's Monday at 2:30pm, and Sara was just informed by ACME's Data Engineering team that they made the following schema changes:

- The \`customers\` table has been changed to \`customerInvoices\`.
- The \`region\` column was changed to \`customerRegion\`.

At this point, Sara goes into runQL and changes the source query to:

\`\`\`
SELECT invoiceId, invoiceAmount, customerName FROM customerInvoices WHERE customerRegion = {{location}} ORDER BY invoiceAmount DESC LIMIT 10;
\`\`\`

Sara is done after making the change to the one source of truth in runQL, whereas in the past, she would have had to find the twenty dashboards and make changes in each one of them!
`;

const powerBIrunSource: string = `
#### Setup runSource with Power BI

1. **Download**: [Power BI](https://www.microsoft.com/en-us/power-platform/products/power-bi/downloads)
2. **IP**: Get the host IP with the following(inet) \`ifconfig en0\`
3: **SQL Statement**: Find the required SQL statement by expanding the 'Advanced Options' in Power BI
4. **Connect**: Link Power BI to runsource using:

\`\`\`
Server: <HOST_IP_ADDRESS>:3001
Database: runql
SQL Statement: <Runsource Query>
user name: <anything>
password: <anything>
\`\`\`
`;

export const runSourceQuestions: Section[] = [
   {
      question: "What is runQL's Virtual Query Service (AKA runSource)?",
      answer: whatIsRunSource,
      group: 'runSource',
   },
   {
      question: 'Setup runSource with Power BI',
      answer: powerBIrunSource,
      group: 'runSource',
   },
];
