import { useInjection } from 'inversify-react';
import { useQuery } from 'react-query';
import { ExploreTab } from '../../../entities';
import { QueryKey } from '../../../enums';
import { ExploreTabService, ListOptionsExploreTab } from '../../../services';
import { TYPES } from '../../../types';
import { handleError } from '../../../utilities';
import ExploreTabNav from './ExploreTabNav';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import ErrorBoundary from '../../../components/ErrorBoundary';

interface Props {
   workspaceId: number;
}

const Explore = (props: Props): JSX.Element => {
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);

   const queryFilters: ListOptionsExploreTab = {
      workspaceId: props.workspaceId,
      includeTopLogs: 1,
   };

   const exploreTabQuery = useQuery<ExploreTab[]>(
      [QueryKey.ExploreTab, 'list', props.workspaceId, queryFilters],
      () =>
         exploreTabService.listOptions(queryFilters).catch((err) => {
            handleError(err);
            return [];
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: false,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );

   if (exploreTabQuery.isLoading) {
      return <LoadingSpinner />;
   }

   if (exploreTabQuery.isError) {
      return <div>Error</div>;
   }

   return (
      <ErrorBoundary>
         <ExploreTabNav
            exploreTabList={exploreTabQuery.data !== undefined ? exploreTabQuery.data : []}
            workspaceId={props.workspaceId}
         />
      </ErrorBoundary>
   );
};

export default Explore;
