import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import {
   Button,
   Card,
   Col,
   Dropdown,
   Form,
   OverlayTrigger,
   Row,
   Stack,
   Tooltip,
} from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { BiChevronDown, BiRefresh } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { LoadingSpinner, Page, SourceNav, TreeNode } from '../../components';
import LoadingError from '../../components/UI/LoadingError';
import { ConnectionAccessType } from '../../entities';
import {
   useBulkUpdateSchemaCacheMutation,
   useGetDataConnectionSchemaTree,
   useUpdateSchema,
} from '../../hooks';
import { useListDataConnectionsQuery } from '../../hooks/entities/dataConnectionHooks';
import { BulkUpdateSchemaCache } from '../../services';
import { getErrorMessage, useTitle } from '../../utilities';
import { MetaSchemaTree } from './MetaSchemaTree';

const tableMetaSchema = z.object({
   id: z.number(),
   description: z.string().optional(),
   children: z.array(
      z.object({
         id: z.number(),
         label: z.string().optional(),
         fieldDataType: z.string().optional(),
         description: z.string().optional(),
         exampleData: z.string().optional(),
         isGenerating: z.date().optional(),
      })
   ),
});

export type TableMetaFormData = z.infer<typeof tableMetaSchema>;

const checkIsGeneratingRecursive = (nodes: TreeNode[]): boolean => {
   for (const node of nodes) {
      if (node.isGenerating) return true;
      if (node.children && node.children.length > 0 && checkIsGeneratingRecursive(node.children))
         return true;
   }
   return false;
};

const findNodeRecursive = (nodes: TreeNode[], id: number): TreeNode | undefined => {
   for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
         const found = findNodeRecursive(node.children, id);
         if (found) return found;
      }
   }
   return undefined;
};

const IS_GENERATING_TIMEOUT_MS = 2 * 60 * 1000;

export default function DataConnectionMetaPage() {
   useTitle('Metadata');

   const dataConnections = useListDataConnectionsQuery({});
   const [dataConnectionId, setDataConnectionId] = useState<number | undefined>();

   const [schemaTree, setSchemaTree] = useState<TreeNode[]>([]);
   const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
   const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);
   const [pendingUpdates, setPendingUpdates] = useState<BulkUpdateSchemaCache[]>([]);

   const {
      handleSubmit,
      register,
      control,
      formState: { isDirty, isValid },
      reset,
      getValues,
   } = useForm<TableMetaFormData>({
      resolver: zodResolver(tableMetaSchema),
      defaultValues: selectedNode ?? {},
      mode: 'onChange',
   });

   const { fields } = useFieldArray({
      control,
      name: 'children',
   });

   // Queries
   const dataConnectionQuery = useGetDataConnectionSchemaTree({
      dataConnectionId: dataConnectionId,
      refetchInterval: shouldRefresh ? 1000 * 20 : undefined,
      callbacks: {
         onSuccess(data) {
            if (dataConnectionId && selectedNode?.dataConnectionId === dataConnectionId) {
               return;
            }

            const findFirstTableNode = (nodes: TreeNode[]): TreeNode | null => {
               for (const node of nodes) {
                  if (node.type === 'tableNode') {
                     return node;
                  } else if (node.children) {
                     const result = findFirstTableNode(node.children);
                     if (result) {
                        return result;
                     }
                  }
               }
               return null;
            };

            const firstTableNode = findFirstTableNode(data);

            if (firstTableNode) {
               setSelectedNode(firstTableNode);
               reset(firstTableNode, { keepDirtyValues: true });
            }
         },
      },
   });
   const bulkUpdateSchemaCacheMutator = useBulkUpdateSchemaCacheMutation();

   // Effects
   useEffect(() => {
      if (dataConnectionQuery.data) {
         setSchemaTree(dataConnectionQuery.data);

         setShouldRefresh(checkIsGeneratingRecursive(dataConnectionQuery.data));

         if (selectedNode) {
            const selected = findNodeRecursive(dataConnectionQuery.data, selectedNode.id);
            if (selected) {
               setSelectedNode(selected);
               reset(selected, { keepDirtyValues: true });
            }
         }
      }
   }, [dataConnectionQuery.data, selectedNode, reset]);

   // Page functions
   const handleNodeClick = (node: TreeNode) => {
      if (node.type !== 'tableNode') return;
      updateCacheList();
      setSelectedNode(node);
      reset(node);
   };

   const onSubmitHandler = async (data: TableMetaFormData) => {
      updateCacheList();
      if (dataConnectionId && pendingUpdates.length > 0) {
         await bulkUpdateSchemaCacheMutator.mutateAsync({
            dataConnectionId,
            schemaCache: pendingUpdates,
         });

         setPendingUpdates([]);
         reset({}, { keepValues: true });
      }
   };

   const updateCacheList = () => {
      if (isDirty && isValid) {
         // Cache any changes for eventual save
         const values = getValues();
         const updates = pendingUpdates;
         const newSchema = schemaTree.map<TreeNode>((catalogNode) => {
            // catalog node
            return {
               ...catalogNode,
               children: catalogNode.children.map((databaseNode) => {
                  // database node
                  return {
                     ...databaseNode,
                     children: databaseNode.children.map((tableNode) => {
                        // table node
                        if (tableNode.id === values.id) {
                           // check if table has changed
                           if (tableNode.description !== values.description) {
                              const i = updates.findIndex((u) => u.id === tableNode.id);
                              if (i === -1) {
                                 updates.push({
                                    id: tableNode.id,
                                    description: values.description,
                                 });
                              } else {
                                 updates[i] = {
                                    id: tableNode.id,
                                    description: values.description,
                                 };
                              }
                           }

                           return {
                              ...tableNode,
                              children: values.children.map((newColumn) => {
                                 // column node
                                 const oldColumn = tableNode.children.find(
                                    (child) => child.id === newColumn.id
                                 );
                                 if (!oldColumn) throw new Error('Column not found');
                                 const newColumnNode = {
                                    ...oldColumn,
                                    ...newColumn,
                                 };
                                 // Check if column has changed
                                 if (
                                    newColumn.description !== oldColumn.description ||
                                    newColumn.exampleData !== oldColumn.exampleData
                                 ) {
                                    const i = updates.findIndex((u) => u.id === newColumnNode.id);
                                    if (i === -1) {
                                       updates.push({
                                          id: newColumnNode.id,
                                          description: newColumn.description,
                                          exampleData: newColumn.exampleData,
                                       });
                                    } else {
                                       updates[i] = {
                                          id: newColumnNode.id,
                                          description: newColumn.description,
                                          exampleData: newColumn.exampleData,
                                       };
                                    }
                                 }
                                 return newColumnNode;
                              }),
                              description: values.description,
                           };
                        } else {
                           return tableNode;
                        }
                     }),
                  };
               }),
            };
         });
         setPendingUpdates(updates);
         setSchemaTree(newSchema);
      }
   };

   const { updateSchema, state: updateSchemaState } = useUpdateSchema();

   async function handleRefreshClick(dataConnectionId: number): Promise<void> {
      setPendingUpdates([]);
      reset({});
      toast.info('Refreshing schema and generating metadata.  This can take a few minutes.');
      try {
         await updateSchema(dataConnectionId);
      } catch (error) {
         // error is shown by LoadingErrors
      }
   }

   const now = Date.now();

   return (
      <Page header="Data Sources & Metadata" nav={<SourceNav />}>
         <div>
            <div className="mb-2">
               {!dataConnections.data && <LoadingSpinner />}
               {dataConnections.data && (
                  <Stack direction="horizontal" gap={2}>
                     <Dropdown>
                        <Dropdown.Toggle className="btn btn-sm btn-secondary fs-12p">
                           {dataConnectionId
                              ? dataConnections.data?.find((w) => w.id === dataConnectionId)
                                   ?.name ?? ''
                              : 'Pick a data source'}
                           <BiChevronDown size={16} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="fs-10p">
                           {dataConnections.data
                              ?.filter((c) => c.connectionAccessType !== ConnectionAccessType.DEMO)
                              ?.map((dataConnection) => (
                                 <Dropdown.Item
                                    active={dataConnectionId === dataConnection.id}
                                    key={dataConnection.id}
                                    onClick={() => {
                                       setDataConnectionId(dataConnection.id);
                                       setSelectedNode(null);
                                    }}
                                 >
                                    {dataConnection.name}
                                 </Dropdown.Item>
                              ))}
                        </Dropdown.Menu>
                     </Dropdown>
                     {dataConnectionId && (
                        <OverlayTrigger
                           overlay={
                              <Tooltip id="refreshSchema">
                                 Refresh Schema and Generate Metadata
                              </Tooltip>
                           }
                        >
                           <button
                              className="btn btn-link custom-button-link-no-spacing-middle p-0 mb-1"
                              onClick={() => handleRefreshClick(dataConnectionId)}
                           >
                              <BiRefresh size={16} />
                           </button>
                        </OverlayTrigger>
                     )}
                  </Stack>
               )}

               {dataConnectionQuery.isLoading && <LoadingSpinner />}
               {dataConnectionQuery.isError && (
                  <LoadingError message={getErrorMessage(dataConnectionQuery.error)} />
               )}

               {updateSchemaState.isLoading ? (
                  <LoadingSpinner />
               ) : updateSchemaState.isError ? (
                  <LoadingError message={getErrorMessage(updateSchemaState.error)} />
               ) : (
                  !!schemaTree?.length && (
                     <div className="other-cards card bg-secondary mx-auto p-2 border-1 w-100 mt-3">
                        Pick a table
                        <MetaSchemaTree
                           expandLevels={[
                              'connectionNode',
                              'databaseNode',
                              'tableNode',
                              'catalogNode',
                           ]}
                           nodeClicked={handleNodeClick}
                           pendingUpdates={pendingUpdates}
                           showLevels={[
                              'connectionNode',
                              'databaseNode',
                              'tableNode',
                              'catalogNode',
                           ]}
                           treeData={schemaTree}
                        />
                     </div>
                  )
               )}
            </div>
            {dataConnectionQuery.data && (
               <div className="h-100 overflow-auto flex-grow-1">
                  <Form onSubmit={handleSubmit(onSubmitHandler)}>
                     {selectedNode ? (
                        <>
                           <Form.Group className="mb-3">
                              <Form.Label>{selectedNode?.label} Description</Form.Label>
                              <Form.Control
                                 {...register('description')}
                                 as="textarea"
                                 placeholder="Table description"
                                 rows={3}
                              />
                           </Form.Group>
                           {/* Print column headers */}
                           {selectedNode?.children && selectedNode.children.length > 0 && (
                              <div className="p-3 pb-0 pt-1 fs-10p">
                                 <Row className="justify-content-center">
                                    <Col xs={2}>
                                       <span>Column</span>
                                    </Col>
                                    <Col xs={2}>
                                       <span>Data Type</span>
                                    </Col>
                                    <Col xs={5}>
                                       <span>Description</span>
                                    </Col>
                                    <Col xs={3}>
                                       <span>Example Data</span>
                                    </Col>
                                 </Row>
                              </div>
                           )}
                           {fields.map((field, index) => {
                              const isGenerating =
                                 shouldRefresh &&
                                 selectedNode.children[index].isGenerating &&
                                 now <=
                                    new Date(selectedNode.children[index].isGenerating!).getTime() +
                                       IS_GENERATING_TIMEOUT_MS;
                              return (
                                 <Card
                                    className="border-1 mt-2 mb-2 p-3 shadow-sm fs-10p"
                                    key={field.id}
                                 >
                                    <Row className="justify-content-center">
                                       <Col xs={2}>{field.label}</Col>
                                       <Col xs={2}>{field.fieldDataType}</Col>
                                       <Col xs={5}>
                                          {isGenerating ? (
                                             <span className="text-muted">Generating...</span>
                                          ) : (
                                             <textarea
                                                className="form-control"
                                                {...register(`children.${index}.description`)}
                                             />
                                          )}
                                       </Col>
                                       <Col xs={3}>
                                          {isGenerating ? (
                                             <span className="text-muted">Generating...</span>
                                          ) : (
                                             <input
                                                className="form-control"
                                                {...register(`children.${index}.exampleData`)}
                                             />
                                          )}
                                       </Col>
                                    </Row>
                                 </Card>
                              );
                           })}
                           <Stack
                              className="mt-3 justify-content-end"
                              direction="horizontal"
                              gap={3}
                           >
                              <Link className="btn btn-sm btn-secondary" to="/sources">
                                 Cancel
                              </Link>
                              <Button
                                 className="btn btn-sm btn-primary ms-2"
                                 disabled={
                                    bulkUpdateSchemaCacheMutator.isLoading ||
                                    (pendingUpdates.length === 0 && !isDirty)
                                 }
                                 type="submit"
                              >
                                 Save
                              </Button>
                           </Stack>
                        </>
                     ) : (
                        ''
                     )}
                  </Form>
               </div>
            )}
         </div>
      </Page>
   );
}
