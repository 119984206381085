import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { walkthroughStep } from '../entities/Walkthrough';

const PATH = '/v1/walkthrough';
@injectable()
export class WalkthroughService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   async get(): Promise<Record<number, boolean>> {
      return (await this.apiService.get(`${PATH}/`)) ?? {};
   }

   async post(step: Partial<walkthroughStep>): Promise<Record<number, boolean>> {
      const result = await this.apiService.post<Record<number, boolean>>(`${PATH}/${step}`, {});
      if (!result) {
         return {};
      }
      return result;
   }
}
