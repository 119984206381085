import { VariantProps, cva, cx } from 'class-variance-authority';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const buttonVariants = cva('btn', {
   variants: {
      colorScheme: {
         primary: 'btn-primary',
         secondary: 'btn-secondary',
         danger: 'btn-danger',
         link: '',
      },
      variant: {
         solid: '',
         outline: '',
         link: 'btn-link',
      },
      size: {
         sm: 'btn-sm p-1',
         md: 'btn-md p-2',
         lg: 'btn-lg p-3',
      },
   },
   compoundVariants: [
      {
         colorScheme: 'primary',
         variant: 'outline',
         className: 'btn-outline-primary',
      },
      {
         colorScheme: 'secondary',
         variant: 'outline',
         className: 'btn-outline-secondary',
      },
   ],
   defaultVariants: {
      colorScheme: 'primary',
      size: 'sm',
      variant: 'solid',
   },
});

const spinnerVariants = cva('spinner-border', {
   variants: {
      size: {
         sm: 'me-1',
         md: 'me-2',
         lg: 'me-3',
      },
   },
   defaultVariants: {
      size: 'sm',
   },
});

export interface ButtonProps
   extends ButtonHTMLAttributes<HTMLButtonElement>,
      VariantProps<typeof buttonVariants> {
   colorScheme?: 'primary' | 'secondary' | 'danger' | 'link';
   href?: string;
   isLoading?: boolean;
   loadingMessage?: string;
   message?: string;
   size?: 'sm' | 'md' | 'lg';
   type?: 'button' | 'submit';
   variant?: 'solid' | 'outline' | 'link';
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   (
      {
         colorScheme,
         message,
         size = 'sm',
         variant,
         className,
         children,
         isLoading,
         loadingMessage,
         disabled,
         type = 'button',
         href,
         ...props
      },
      ref
   ) => {
      const minWidth = message ? message.length * 6 : 50;
      const buttonClasses = cx(
         buttonVariants({
            colorScheme: href || variant === 'link' ? 'link' : colorScheme,
            variant,
            size,
         }),
         className
      );

      return href || variant === 'link' ? (
         <Link
            className={buttonClasses}
            ref={ref as React.Ref<HTMLAnchorElement>}
            to={href ?? '/'}
            {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
         >
            {message}
         </Link>
      ) : (
         <BootstrapButton
            className={buttonClasses}
            disabled={disabled || isLoading}
            href={href}
            ref={ref}
            type={type}
            {...props}
            style={{
               minWidth: isLoading ? minWidth : undefined,
               margin: 2,
            }}
            variant={colorScheme === 'danger' ? 'danger' : undefined}
         >
            {isLoading && (
               <Spinner
                  animation="border"
                  className={cx(spinnerVariants({ size: size }))}
                  role="status"
                  style={{
                     width: size === 'sm' ? '12px' : size === 'md' ? '20px' : '24px',
                     height: size === 'sm' ? '12px' : size === 'md' ? '20px' : '24px',
                  }}
               />
            )}
            {isLoading ? loadingMessage ?? 'Loading...' : children ?? message}
         </BootstrapButton>
      );
   }
);

export default Button;
