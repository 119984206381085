import React, { createContext, useContext, useState, useEffect } from 'react';

type ThemeContextProps = {
   mode: string;
   toggleMode: () => void;
};

export const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export function ThemeProvider(props: { children: React.ReactNode }): JSX.Element {
   const [mode, setMode] = useState(
      window.localStorage.getItem('mode') ?? global.runql?.systemDarkMode ? 'dark' : 'light'
   );

   useEffect(() => {
      const html = document.getElementsByTagName('html')[0];
      if (mode === 'dark') {
         html.classList.add('dark-mode');
      } else {
         html.classList.remove('dark-mode');
      }
   }, [mode]);

   const toggleMode = () => {
      const newMode = mode === 'light' ? 'dark' : 'light';
      setMode(newMode);
      window.localStorage.setItem('mode', newMode);
   };

   return (
      <ThemeContext.Provider value={{ mode, toggleMode }}>{props.children}</ThemeContext.Provider>
   );
}

export function useTheme() {
   const themeContext = useContext(ThemeContext);
   return themeContext?.mode ?? 'light';
}
