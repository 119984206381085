import React, { createContext, useState, useContext, Dispatch } from 'react';

type QueryPanelContextType = {
   collapsedSize: number;
   expanded: boolean;
   setCollapsedSize: Dispatch<React.SetStateAction<number>>;
   setExpanded: Dispatch<React.SetStateAction<boolean>>;
};

const QueryPanelContext = createContext<QueryPanelContextType>({
   expanded: false,
   setExpanded: () => {
      return false;
   },
   collapsedSize: 30,
   setCollapsedSize: () => {
      return 30;
   },
});

export function QueryPanelProvider({ children }: { children: React.JSX.Element }) {
   const [expanded, setExpanded] = useState<boolean>(false);
   const [collapsedSize, setCollapsedSize] = useState(30);

   return (
      <QueryPanelContext.Provider
         value={{ expanded, setExpanded, collapsedSize, setCollapsedSize }}
      >
         {children}
      </QueryPanelContext.Provider>
   );
}

export function useQueryPanelContext() {
   return useContext(QueryPanelContext);
}
