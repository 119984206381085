import { getErrorMessage } from './error';

interface ApiOptions<TPayload> {
   apiVersion?: 'v1';
   body?: Partial<TPayload>;
   params?: Record<string, string>;
}

export const get = async <TReturn, TPayload = TReturn>(
   resource: string,
   options?: ApiOptions<TPayload>
): Promise<TReturn> => {
   return api<TReturn, TPayload>('GET', resource, options);
};

export const post = async <TReturn, TPayload = TReturn>(
   resource: string,
   options?: ApiOptions<TPayload>
): Promise<TReturn> => {
   return api<TReturn, TPayload>('POST', resource, options);
};

export const api = async <TReturn, TPayload = TReturn>(
   method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
   resource: string,
   options?: ApiOptions<TPayload>
): Promise<TReturn> => {
   if (!process.env.REACT_APP_API_URI) {
      throw Error('API endpoint is undefined');
   }
   const endpoint = process.env.REACT_APP_API_URI;

   const { params, body, apiVersion } = options ?? {};

   if (resource.at(0) !== '/') {
      resource = `/${resource}`;
   }

   const url = new URL(`${apiVersion ?? 'v1'}${resource}`, endpoint);

   const searchParams = params ? `?${new URLSearchParams(params).toString()}` : '';

   //console.debug('API: Request URL', url.toString() + searchParams);

   //console.debug('API: Request Body', body);
   try {
      const response = await fetch(url + searchParams, {
         method: method,
         credentials: 'include',
         headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
         },
         body: body ? JSON.stringify(body) : undefined,
      });

      //console.debug('API: Status', response.status);
      const len = response.headers.get('Content-Length');
      const json = len && parseInt(len) > 0 ? await response.json() : {};
      //console.debug('API: Response Body', json);

      if (!response.ok) {
         console.debug('API: Not OK');
         if (json && json.message) {
            throw new Error(json.message);
         }
         throw new Error(`HTTP error ${response.status}`);
      }

      return json;
   } catch (err) {
      throw new Error(getErrorMessage(err));
   }
};
