import { Base } from './Base';

export enum walkthroughStep {
   PERSONAL_DEMO_WORKSPACE = 1,
   IDE_CELL = 2,
   SUGGESTED_QUERY = 3,
   FIRST_RUN_BUTTON = 4,
   CHART_TAB = 5,
   SECOND_TAB = 6,
   PYTHON_RUN_BUTTON = 7,
   PYTHON_DOCUMENTATION = 8,
   PYTHON_SAVE = 9,
   VERSIONS = 10,
   SAVED_QUERIES = 11,
   DATA_SOURCES = 12,
   METADATA_TAB = 13,
   WORKSPACES_TAB = 14,
   CREATE_WORKSPACE = 15,
   TEAM_PLAYGROUND = 16,
}

export interface Walkthrough extends Base {
   createdByPersonId?: number;
   modifiedByPersonId?: number;
   orgId?: number;
   walkthroughStep?: walkthroughStep;
}

export type WalkthroughFlags = {
   [key in walkthroughStep]?: boolean;
};
