import { inject, injectable } from 'inversify';
import { TreeNode } from '../components';
import { Person, Workspace } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/workspace';

export interface GetOptionsWorkspace {
   connectionDetails?: boolean;
   includeCustomTags?: boolean;
   includeOrgPeople?: boolean;
   savedQueryCount?: boolean;
}

export interface ListOptionsWorkspace {
   connectionDetails?: boolean;
   includeOrgPeople?: boolean;
   includeSavedQueries?: boolean;
   savedQueryCount?: boolean;
}

@injectable()
export class WorkspaceService implements ApiServiceInterface<Workspace> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(id: string | number, params?: Record<string, string>): Promise<Workspace | undefined> {
      const result = await this.apiService.get<Workspace>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      const data = result as Workspace;
      this.cast(data);
      return data;
   }

   async getOptions(
      id: string | number,
      getOptions?: GetOptionsWorkspace
   ): Promise<Workspace | undefined> {
      const params: Record<string, string> = {};

      if (getOptions !== undefined) {
         if (getOptions.connectionDetails !== undefined && getOptions.connectionDetails) {
            params['connectionDetails'] = getOptions.connectionDetails.toString();
         }

         if (getOptions.includeCustomTags !== undefined && getOptions.includeCustomTags) {
            params['includeCustomTags'] = getOptions.includeCustomTags.toString();
         }

         if (getOptions.includeOrgPeople !== undefined && getOptions.includeOrgPeople) {
            params['includeOrgPeople'] = getOptions.includeOrgPeople.toString();
         }

         if (getOptions.savedQueryCount !== undefined && getOptions.savedQueryCount) {
            params['savedQueryCount'] = getOptions.savedQueryCount.toString();
         }
      }

      return this.get(id, params);
   }

   async list(params?: Record<string, string>): Promise<Workspace[]> {
      const result = await this.apiService.get<Workspace[]>(path, params);
      if (!result) {
         return [];
      }

      const data = result as Workspace[];
      data.forEach((item) => this.cast(item));
      return data;
   }

   async recent(params?: Record<string, string>): Promise<Workspace[]> {
      const result = await this.apiService.get<Workspace[]>(`${path}/recent`, params);
      if (!result) {
         return [];
      }

      const data = result as Workspace[];
      data.forEach((item) => this.cast(item));
      return data;
   }

   async listOptions(listOptions?: ListOptionsWorkspace): Promise<Workspace[]> {
      const params: Record<string, string> = {};

      if (listOptions !== undefined) {
         if (listOptions.savedQueryCount) {
            params['savedQueryCount'] = listOptions.savedQueryCount.toString();
         }

         if (listOptions.includeOrgPeople) {
            params['includeOrgPeople'] = listOptions.includeOrgPeople.toString();
         }

         if (listOptions.connectionDetails) {
            params['connectionDetails'] = listOptions.connectionDetails.toString();
         }

         if (listOptions.includeSavedQueries) {
            params['includeSavedQueries'] = listOptions.includeSavedQueries.toString();
         }
      }

      return this.list(params);
   }

   async listEligibleCollaborators(workspaceId: number): Promise<Person[]> {
      if (workspaceId < 0) {
         return [];
      }

      const result = await this.apiService.get<Person[]>(
         `${path}/${workspaceId}/EligibleCollaborators`
      );
      if (!result) {
         return [];
      }

      const data = result as Person[];
      return data;
   }

   async patch(
      id: string | number,
      body: Workspace,
      params?: Record<string, string>
   ): Promise<Workspace | undefined> {
      const result = await this.apiService.patch<Workspace>(`${path}/${id}`, body, params);
      if (!result) {
         return undefined;
      }

      const data = result as Workspace;
      this.cast(data);
      return data;
   }

   async post(body: Workspace, params?: Record<string, string>): Promise<Workspace | undefined> {
      const result = await this.apiService.post<Workspace>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }

      const data = result as Workspace;
      this.cast(data);
      return data;
   }

   async put(
      body: Workspace,
      params?: Record<string, string> | undefined
   ): Promise<Workspace | undefined> {
      throw new Error('Method not implemented.');
   }

   async uploadFile(id: number, body: FormData): Promise<Workspace | undefined> {
      const result = await this.apiService.post<Workspace>(`${path}/${id}/importZip`, body);
      if (!result) {
         return undefined;
      }

      return result;
   }

   async getSchemaTree(workspaceId: number): Promise<TreeNode[]> {
      const result = await this.apiService.get<TreeNode[]>(`${path}/${workspaceId}/schemaTree`);
      if (!result) {
         return [];
      }
      return result;
   }

   exportWorkspaceUrl(workspaceId: number) {
      const url = new URL(`${path}/${workspaceId}/exportZip`, this.apiService.endpoint);
      return url.toString();
   }

   cast(item: Workspace): Workspace | undefined {
      if (!item) {
         return;
      }

      for (const [key, value] of Object.entries(item)) {
         if (value === null) item[key as keyof Workspace] = undefined;
      }

      return item;
   }
}
