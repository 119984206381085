import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { WalkthroughService } from '../services';
import { TYPES } from '../types';
import { walkthroughStep } from '../entities/Walkthrough';
import { QueryKey } from '../enums';
import { get, handleError } from '../utilities';
import { useExplorer } from '.';

export const useGetWalkthroughs = (callbacks?: {
   errorCallback?: (error: unknown) => void;
   successCallback?: (data: Record<number, boolean>) => void;
}) => {
   const { data = {}, isLoading } = useQuery<Record<number, boolean>>(
      [QueryKey.Walkthrough],
      () => get<Record<number, boolean>>('/walkthrough/'),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: false,
         refetchOnReconnect: false,
         staleTime: Infinity,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback ?? handleError,
      }
   );
   return { data, isLoading };
};

const useCreateWalkthroughMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: walkthroughStep, context: unknown) => void;
   onSuccessCallback?: () => void;
}) => {
   const service = useInjection<WalkthroughService>(TYPES.walkthroughService);
   const queryClient = useQueryClient();

   return useMutation<void, unknown, walkthroughStep, unknown>({
      mutationFn: async (step: walkthroughStep) => {
         await service.post(step);
         await queryClient.invalidateQueries(QueryKey.Walkthrough);
      },
      async onSuccess(data) {
         return;
      },
      onError: callbacks?.onErrorCallback,
   });
};

const isCurrentStep = (
   data: Record<number, boolean>,
   step: walkthroughStep,
   excludeStep?: walkthroughStep
): boolean => {
   for (const key in walkthroughStep) {
      const stepNumber = Number(key);
      if (isNaN(stepNumber)) continue;

      if (stepNumber < step && stepNumber !== excludeStep) {
         if (!data[stepNumber]) return false;
      } else if (stepNumber >= step && stepNumber !== excludeStep) {
         if (data[stepNumber]) return false;
      }
   }
   return true;
};

export const useWalkthroughStep = (step: walkthroughStep): [boolean, () => Promise<void>] => {
   const person = useExplorer();
   const { data, isLoading } = useGetWalkthroughs();
   const mutator = useCreateWalkthroughMutator();

   const set = useCallback(async () => {
      try {
         await mutator.mutateAsync(step);
      } catch (err) {
         throw err;
      }
   }, [mutator, step]);

   if (isLoading || (person?.created && person.created < '2024-09-16')) {
      return [false, set];
   }

   let isStepSet = true;
   if (step === walkthroughStep.DATA_SOURCES) {
      isStepSet = isCurrentStep(data, step, walkthroughStep.SAVED_QUERIES);
   } else if (step === walkthroughStep.SAVED_QUERIES) {
      isStepSet = isCurrentStep(data, step, walkthroughStep.DATA_SOURCES);
   } else {
      isStepSet = isCurrentStep(data, step);
   }

   return [isStepSet, set];
};
