import { useState } from 'react';

import StartingBlock from '../components/StartingBlock';

export const Extension = (): JSX.Element => {
   const savedWorkspace = window.localStorage.getItem('extension-workspace');
   const [workspaceId, setWorkspaceId] = useState<number | undefined>(
      savedWorkspace ? Number(savedWorkspace) : undefined
   );
   return (
      <StartingBlock
         workspaceId={workspaceId}
         workspaceSelector={(id?: number) => {
            setWorkspaceId(id);
            window.localStorage.setItem('extension-workspace', id?.toString() ?? '');
         }}
      />
   );
};

export default Extension;
