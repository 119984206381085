import { memo } from 'react';
import { Badge, NavDropdown, Stack } from 'react-bootstrap';
import { MdKeyboardArrowDown, MdCable } from 'react-icons/md';

import LoadingError from '../components/UI/LoadingError';
import LoadingSpinner from '../components/UI/LoadingSpinner';
import { useListWorkspaceConnectionsQuery, useFeature } from '../hooks';
import { getErrorMessage } from '../utilities';
import { ConnectionAccessType, DataConnection, StepType } from '../entities';

const WorkspaceConnectionSelector = memo(
   ({
      onChange,
      dataConnectionId,
      type,
      readOnly,
      workspaceId,
      onlyShared,
   }: {
      dataConnectionId?: number;
      onChange: (dataConnection: DataConnection | undefined, type: StepType) => void;
      onlyShared?: boolean;
      readOnly?: boolean;
      type: StepType;
      workspaceId?: number;
   }): JSX.Element => {
      const federationEnabled = useFeature('federation');
      const pythonEnabled = useFeature('python');

      //Queries
      const workspaceConnectionQuery = useListWorkspaceConnectionsQuery({
         workspaceId: workspaceId,
         includeConnectionDetails: true,
      });

      // Render component
      if (workspaceConnectionQuery.isLoading) {
         return <LoadingSpinner />;
      }

      if (workspaceConnectionQuery.isError) {
         return <LoadingError message={getErrorMessage(workspaceConnectionQuery.error)} />;
      }

      const pythonLabel = (
         <Stack direction="horizontal" gap={1}>
            <div>Python</div>
            <Badge>beta</Badge>
         </Stack>
      );
      const federatedLabel = (
         <Stack direction="horizontal" gap={1}>
            <div>Federated</div>
            <Badge>beta</Badge>
         </Stack>
      );

      let label = <>None</>;
      switch (type) {
         case StepType.DATA_CONNECTION:
            if (dataConnectionId) {
               const connection = workspaceConnectionQuery.data?.find(
                  (val) => val.dataConnectionId === dataConnectionId
               );
               label = <>{connection?.dataConnection?.name ?? 'Unknown'}</>;
            }
            break;
         case StepType.FEDERATED:
            label = federatedLabel;
            break;
         case StepType.PYTHON:
            label = pythonLabel;
            break;
      }

      const connections = workspaceConnectionQuery.data ?? [];

      if (readOnly) {
         return (
            <Stack className="fs-11p" direction="horizontal" gap={1}>
               <MdCable size={14} />
               {label}
            </Stack>
         );
      }

      return (
         <NavDropdown
            className="fs-10p ps-0 dropdownMenuSmall w-auto"
            onSelect={(val) => {
               if (val === 'federated') {
                  onChange(undefined, StepType.FEDERATED);
               } else if (val === 'python') {
                  onChange(undefined, StepType.PYTHON);
               } else if (val) {
                  const connectionId = Number.parseInt(val);
                  onChange(
                     workspaceConnectionQuery?.data?.find(
                        (connection) => connection.id === connectionId
                     )?.dataConnection,
                     StepType.DATA_CONNECTION
                  );
               }
            }}
            title={
               <>
                  <MdCable size={14} />
                  &nbsp;{label}
                  <MdKeyboardArrowDown size={14} />
               </>
            }
         >
            {connections
               .filter(
                  (c) =>
                     c.dataConnection?.connectionAccessType !== ConnectionAccessType.INDIVIDUAL ||
                     !onlyShared
               )
               .map((connection) => {
                  return (
                     <NavDropdown.Item
                        className="fs-11p"
                        eventKey={connection.id}
                        key={connection.id}
                     >
                        {connection.dataConnection?.name ?? 'Unknown'}
                     </NavDropdown.Item>
                  );
               })}
            {pythonEnabled && (
               <NavDropdown.Item className="fs-11p" eventKey="python" key="python">
                  {pythonLabel}
               </NavDropdown.Item>
            )}
            {federationEnabled && (
               <NavDropdown.Item className="fs-11p" eventKey="federated" key="federated">
                  {federatedLabel}
               </NavDropdown.Item>
            )}
         </NavDropdown>
      );
   }
);

export default WorkspaceConnectionSelector;
