import { useInjection } from 'inversify-react';
import { useMutation, useQueryClient } from 'react-query';
import { QueryKey } from '../../enums';
import { BulkUpdateSchemaCache, SchemaCacheService } from '../../services';
import { TYPES } from '../../types';

const useSchemaCacheService = () => {
   return useInjection<SchemaCacheService>(TYPES.schemaCacheService);
};

export const useBulkUpdateSchemaCacheMutation = () => {
   const queryClient = useQueryClient();
   const schemaCacheService = useSchemaCacheService();

   return useMutation({
      mutationFn: async (vars: {
         dataConnectionId: number;
         schemaCache: BulkUpdateSchemaCache[];
      }) => {
         return schemaCacheService.bulkUpdate(vars.dataConnectionId, vars.schemaCache);
      },
      onSuccess: (data, variables, context) => {
         queryClient.invalidateQueries([
            QueryKey.SchemaTreeForDataSource,
            variables.dataConnectionId,
         ]);
      },
   });
};
