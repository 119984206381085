import { Form } from 'react-bootstrap';

export const SearchInput = ({
   onTextChanged,
   entityName,
   value,
}: {
   entityName?: string;
   onTextChanged?(newText: string): void;
   value?: string;
}): JSX.Element => {
   return (
      <Form.Control
         className="search-max-width small-form-control-input"
         onChange={(event) => onTextChanged && onTextChanged(event.currentTarget.value)}
         placeholder={`Search ${entityName ?? ''}`}
         type="text"
         value={value}
      />
   );
};

export default SearchInput;
