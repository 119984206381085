import React from 'react';

import { Tooltip, Form, OverlayTrigger } from 'react-bootstrap';
import { IconInformation } from '../../../../utilities';
import { ConnectionAccessType } from '../../../../entities';

const tooltipHideDetails = (
   <Tooltip id="tooltipHideDetails">
      The explorer role can use this connection, but cannot see its details such as the host and
      port.
   </Tooltip>
);

export const HideDetailsField = React.forwardRef((props: any, ref) => (
   <Form.Group>
      <Form.Label>
         Hide Connection Details
         <OverlayTrigger overlay={tooltipHideDetails} placement="auto">
            <span>
               <IconInformation size={16} />
            </span>
         </OverlayTrigger>
      </Form.Label>
      <Form.Check {...props} ref={ref} type="switch" />
   </Form.Group>
));

export const AccessTypeField = React.forwardRef((props: any, ref) => (
   <>
      <hr />
      <Form.Group>
         <Form.Label>Credentials</Form.Label>
         <Form.Select {...props} ref={ref}>
            <option value={ConnectionAccessType.INDIVIDUAL}>
               Individual (stored only on your computer)
            </option>
            <option value={ConnectionAccessType.SHARED}>Shared (stored in the cloud)</option>
         </Form.Select>
      </Form.Group>
   </>
));
