import React, { createContext, useState, useContext, useCallback } from 'react';

import { QueryVersion } from '../entities';

type QueryProviderProps = {
   // If not set, queryVersion is read-only
   onChange?: (
      changes: Partial<
         Pick<QueryVersion, 'steps' | 'title' | 'description' | 'question' | 'parameters'>
      >,
      options: { debounce?: boolean }
   ) => void;
   queryVersion?: QueryVersion;
};

type QueryContextType = QueryProviderProps & {
   onParamChange: (key: string, value: string) => void;
   paramOverrides: Record<string, string>;
};

const QueryContext = createContext<QueryContextType>({
   onParamChange: (_, __) => {},
   paramOverrides: {},
});

export function QueryProvider({
   onChange,
   children,
   ...rest
}: QueryProviderProps & {
   children: React.JSX.Element;
}) {
   const [paramOverrides, setParamOverrides] = useState<Record<string, string>>({});
   const onParamChange = useCallback(
      (key: string, value: string) => {
         // If changing the query is supported, we don't need param overrides
         if (onChange) return;
         setParamOverrides((params) => ({ ...params, [key]: value }));
      },
      [onChange]
   );

   return (
      <QueryContext.Provider value={{ ...rest, paramOverrides, onChange, onParamChange }}>
         {children}
      </QueryContext.Provider>
   );
}

export function useCurrentQuery() {
   return useContext(QueryContext);
}
