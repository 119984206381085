import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Person, PersonUtilities, Invite, Workspace, WorkspaceStatus } from '../../entities';
import { PersonService, InviteService } from '../../services';
import { QueryKey } from '../../enums';
import { TYPES } from '../../types';
import { getShortDateString, handleError, notUndefined } from '../../utilities';
import LoadingError from '../../components/UI/LoadingError';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { Col, Card, Form, Row, Dropdown, Modal, Button, Collapse } from 'react-bootstrap';
import WorkspaceIcon from '../../components/UI/WorkspaceIcon';
import { Link } from 'react-router-dom';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { PersonRole, ROLE_NAMES } from '../../enums/personRole';
import { useGetAuthorizedExplorerQuery } from '../../hooks';

interface Props {
   account: Person;
   invite?: Invite;
   readonly?: boolean;
}

const AccountCard = (props: Props): JSX.Element => {
   //State variables
   const [editMode, setEditMode] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [collapseOpen, setCollapseOpen] = useState(false);

   const [firstName, setFirstName] = useState(props.account.firstName ?? '');
   const [lastName, setLastName] = useState(props.account.lastName ?? '');
   const [email, setEmail] = useState(props.account.email ?? '');
   const [role, setRole] = useState(
      Object.keys(PersonRole)[
         Object.values(PersonRole).indexOf(props.account.role as unknown as PersonRole)
      ]
   );
   useEffect(() => {
      // Update state when props change
      setFirstName(props.account.firstName ?? '');
      setLastName(props.account.lastName ?? '');
      setEmail(props.account.email ?? '');
      setRole(
         Object.keys(PersonRole)[
            Object.values(PersonRole).indexOf(props.account.role as unknown as PersonRole)
         ]
      );
   }, [props]);

   const handleEditAccountClick = () => {
      setEditMode(true);
      setCollapseOpen(true);
   };

   //Data Services
   const queryClient = useQueryClient();
   const personService = useInjection<PersonService>(TYPES.personService);
   const inviteService = useInjection<InviteService>(TYPES.inviteService);

   //Queries
   const getExplorerQuery = useGetAuthorizedExplorerQuery();
   const explorer = getExplorerQuery.data;

   //Mutators
   const editFormMutator = useMutation({
      mutationFn: async (editedAccount: Person) => {
         try {
            return await personService.patch(notUndefined(props.account.id), editedAccount);
         } catch (e) {
            handleError(e);
         }
      },
      onSettled: () => {
         queryClient.invalidateQueries([QueryKey.Person, 'list']);
      },
   });

   const deletePersonMutation = useMutation({
      mutationFn: async (personId: number) => {
         return await personService.delete(personId).catch((err) => {
            handleError(err);
         });
      },
      onSuccess: async () => {
         await queryClient.invalidateQueries([QueryKey.Person, 'list']);
      },
   });

   const deleteInviteMutation = useMutation({
      mutationFn: async (inviteId: number) => {
         return await inviteService.delete(inviteId).catch((err) => {
            handleError(err);
         });
      },
      onSuccess: async () => {
         await queryClient.invalidateQueries([QueryKey.Invite, 'list']);
      },
   });

   const resetForm = () => {
      setEditMode(false);
      setCollapseOpen(false);
      setFirstName(props.account.firstName ?? '');
      setLastName(props.account.lastName ?? '');
      setEmail(props.account.email ?? '');
      setRole(
         Object.keys(PersonRole)[
            Object.values(PersonRole).indexOf(props.account.role as unknown as PersonRole)
         ]
      );
   };

   const submitForm = () => {
      setEditMode(false);
      setCollapseOpen(false);
      let modified = false;
      const editedAccount: Person = {};
      editedAccount.id = props.account.id;
      if (firstName !== props.account.firstName) {
         modified = true;
         editedAccount.firstName = firstName;
      }
      if (lastName !== props.account.lastName) {
         modified = true;
         editedAccount.lastName = lastName;
      }
      if (email !== props.account.email) {
         modified = true;
         editedAccount.email = email;
      }
      if (role !== props.account.role) {
         modified = true;
         editedAccount.role = PersonRole[role as keyof typeof PersonRole];
      }
      if (modified) {
         editFormMutator.mutateAsync(editedAccount);
      }
   };

   const handleDeleteClick = (personId: number, inviteId: number) => {
      setShowDeleteModal(false);
      deletePersonMutation.mutateAsync(personId);
      deleteInviteMutation.mutateAsync(inviteId);
   };

   if (editFormMutator.isLoading || getExplorerQuery.isLoading) {
      return (
         <Col xs={12}>
            <Card className="border-1 p-3 shadow-sm">
               <LoadingSpinner />
            </Card>
         </Col>
      );
   }

   if (editFormMutator.isError || getExplorerQuery.isError) {
      return (
         <Col xs={12}>
            <Card className="border-1 p-4 shadow-sm">
               <LoadingError />
            </Card>
         </Col>
      );
   }

   return (
      <Card className="other-cards border-0 bg-secondary py-2 px-3 fs-12p">
         <Form className="">
            <Row className="align-items-center">
               <Col xs={2}>
                  <Row>
                     <Col>
                        <Form.Control
                           className="pt-0 pb-0 fw-500"
                           onChange={(event) => setFirstName(event.currentTarget.value)}
                           plaintext={!editMode}
                           readOnly={!editMode}
                           value={firstName}
                        ></Form.Control>
                     </Col>
                     <Col>
                        <Form.Control
                           className="pt-0 pb-0 fw-500"
                           onChange={(event) => setLastName(event.currentTarget.value)}
                           plaintext={!editMode}
                           readOnly={!editMode}
                           value={lastName}
                        ></Form.Control>
                     </Col>
                  </Row>
               </Col>
               <Col xs={2}>
                  <span className="fw-normal text-muted">
                     {/* Hide emails for investor demo account */}
                     {explorer?.person.orgId !== 5 ? (
                        <Form.Control
                           onChange={(event) => setEmail(event.currentTarget.value)}
                           plaintext={!editMode}
                           readOnly={!editMode}
                           value={email}
                        ></Form.Control>
                     ) : (
                        `[viewable by organization admins]`
                     )}
                  </span>
               </Col>
               <Col xs={1}>
                  {editMode ? (
                     <Form.Select
                        aria-label="Role Select"
                        onChange={(event) => {
                           setRole(event.target.value);
                        }}
                        value={role}
                     >
                        {Object.entries(PersonRole).map((role) => (
                           <option key={role[0]} value={role[0]}>
                              {ROLE_NAMES[role[1]]}
                           </option>
                        ))}
                     </Form.Select>
                  ) : (
                     <span className="fw-normal text-muted">
                        {ROLE_NAMES[PersonRole[role as keyof typeof PersonRole]]}
                     </span>
                  )}
               </Col>
               <Col className="d-flex flex-wrap align-items-center justify-content-start" xs={2}>
                  {props.account.adminWorkspaces
                     ?.filter((workspace) => workspace.status !== WorkspaceStatus.PROTECTED)
                     ?.map((workspace: Workspace) => (
                        <Link
                           className="text-decoration-none"
                           key={`${workspace.id}`}
                           to={`/workspaces/${workspace.id?.toString()}`}
                        >
                           <WorkspaceIcon workspaceName={`${workspace.name}`} />
                        </Link>
                     ))}
                  {props.account.workspaceCollaborators?.map((collaborator) =>
                     collaborator.workspace?.status !== WorkspaceStatus.PROTECTED ? (
                        <Link
                           className="text-decoration-none"
                           key={`${collaborator.workspace?.id}`}
                           to={`/workspaces/${collaborator.workspace?.id?.toString()}`}
                        >
                           <WorkspaceIcon workspaceName={`${collaborator.workspace?.name}`} />
                        </Link>
                     ) : (
                        <></>
                     )
                  )}
               </Col>
               <Col xs={2}>
                  {props.invite ? PersonUtilities.getFullName(props.invite.createdByPerson) : null}
               </Col>
               <Col xs={1}>
                  <span className="">{getShortDateString(props.account.created)}</span>
               </Col>
               <Col xs={1}>
                  {props.invite && (
                     <span className="">{getShortDateString(props.invite.expiry)}</span>
                  )}
               </Col>
               {!props.readonly && (
                  <Col xs={1}>
                     <div className="d-flex justify-content-end">
                        <div style={{ display: 'inline-block' }}>
                           <Dropdown>
                              <Dropdown.Toggle className="btn btn-link pe-0 p-1" variant="link">
                                 <BiDotsVerticalRounded size={24} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu as="ul" style={{ cursor: 'pointer' }}>
                                 {/* <Dropdown.Item as="li" href="#" onClick={handleOpenCollapse}>
                                    {collapseOpen ? 'Hide Details' : 'Show Details'}
                                 </Dropdown.Item> */}
                                 <Dropdown.Item as="li" href="#" onClick={handleEditAccountClick}>
                                    Edit Account
                                 </Dropdown.Item>
                                 <Dropdown.Item
                                    as="li"
                                    href="#"
                                    onClick={() => setShowDeleteModal(true)}
                                 >
                                    Delete Account
                                 </Dropdown.Item>
                              </Dropdown.Menu>
                           </Dropdown>
                        </div>
                     </div>
                  </Col>
               )}
            </Row>
            <Modal onHide={() => setShowDeleteModal(false)} show={showDeleteModal}>
               <Modal.Header closeButton>
                  <Modal.Title className="fs-14p">Delete Account?</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                  {props.account.lastName ? (
                     <span>
                        Are you sure you wish to delete{' '}
                        {`${props.account.firstName} ${props.account.lastName}`}?
                     </span>
                  ) : (
                     <span>Are you sure you wish to delete this account?</span>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                     <Button
                        className={'btn-xs btn-secondary'}
                        onClick={() => setShowDeleteModal(false)}
                     >
                        Cancel
                     </Button>
                     <Button
                        className={'btn-xs ms-2'}
                        onClick={() =>
                           handleDeleteClick(
                              notUndefined(props.account.id),
                              notUndefined(props?.invite?.id)
                           )
                        }
                        type="button"
                     >
                        Delete
                     </Button>
                  </div>
               </Modal.Body>
            </Modal>
            <Collapse in={collapseOpen}>
               <div className="mt-4">
                  <Row xs={12}>
                     <Col className="d-flex justify-content-end mt-4">
                        {editMode === true ? (
                           <span>
                              <Button
                                 bsPrefix="custom-button"
                                 className="btn btn-xs btn-secondary"
                                 onClick={resetForm}
                              >
                                 Cancel
                              </Button>
                              <Button
                                 bsPrefix="custom-button"
                                 className="btn btn-xs btn-primary ms-1"
                                 onClick={submitForm}
                              >
                                 Save
                              </Button>
                           </span>
                        ) : (
                           <span></span>
                        )}
                     </Col>
                  </Row>
               </div>
            </Collapse>
         </Form>
      </Card>
   );
};

export default AccountCard;
