import { inject, injectable } from 'inversify';
import { WorkspaceDataConnection } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const path = '/v1/workspaceDataConnection';

export interface WorkspaceDataConnectionListOptions {
   includeConnectionDetails?: boolean;
   workspaceId?: number;
}

export interface WorkspaceDataConnectionGetOptions {
   includeConnectionDetails: boolean;
}

export interface RunWorkspaceQueryBody {
   exploreTabId: number;
   query: string;
   querySavedId?: number;
}

@injectable()
export class WorkspaceDataConnectionService
   implements ApiServiceInterface<WorkspaceDataConnection>
{
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<WorkspaceDataConnection | undefined> {
      const result = await this.apiService.get<WorkspaceDataConnection>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   getOptions(id: number, options?: WorkspaceDataConnectionGetOptions) {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      return this.get(id, params);
   }

   async list(params: Record<string, string>): Promise<WorkspaceDataConnection[]> {
      const result = await this.apiService.get<WorkspaceDataConnection[]>(path, params);
      if (!result) {
         return [];
      }

      return result;
   }

   listOptions(options?: WorkspaceDataConnectionListOptions): Promise<WorkspaceDataConnection[]> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async patch(
      _id: string | number,
      _body: WorkspaceDataConnection,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceDataConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   async post(
      _body: WorkspaceDataConnection,
      _params?: Record<string, string> | undefined
   ): Promise<WorkspaceDataConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   async put(
      body: WorkspaceDataConnection,
      params?: Record<string, string> | undefined
   ): Promise<WorkspaceDataConnection | undefined> {
      const result = await this.apiService.put<WorkspaceDataConnection>(`${path}`, body, params);
      if (!result) {
         return undefined;
      }

      return result;
   }
}
