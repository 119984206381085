import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Invite, NewInvite, PaginatedResult } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import {
   CheckInviteLinkResult,
   ConvertPayload,
   GetOptionsInvite,
   InviteService,
   ListOptionsInvite,
} from '../../services';
import { TYPES } from '../../types';

const useInviteService = () => {
   return useInjection<InviteService>(TYPES.inviteService);
};

export const useListInviteQuery = ({
   callbacks,
   listOptions,
}: {
   callbacks?: {
      onError?: (err: unknown) => void;
      onSuccess?: (data: PaginatedResult<Invite>) => void;
   };
   listOptions?: ListOptionsInvite;
} = {}) => {
   const service = useInviteService();
   return useQuery<PaginatedResult<Invite>>(
      getInviteQueryKey({ type: QueryKeyType.LIST, options: listOptions }),
      () => service.listOptions(listOptions),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         retry: false,
         onSuccess: callbacks?.onSuccess,
         onError: callbacks?.onError,
      }
   );
};

export const useCheckInviteLinkQuery = (
   link: string | undefined,
   callbacks?: { onSuccess?: (data: CheckInviteLinkResult) => void }
) => {
   const service = useInviteService();
   return useQuery<CheckInviteLinkResult>([undefined], () => service.checkLink(link), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      onSuccess: callbacks?.onSuccess,
      enabled: !!link,
   });
};

export const useCreateInviteMutator = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: NewInvite, context: unknown) => void;
   onSuccessCallback?: (
      newInvite: Invite | undefined,
      variables: NewInvite,
      context: unknown
   ) => void;
}) => {
   const service = useInviteService();
   const queryClient = useQueryClient();
   return useMutation({
      mutationFn: async (newInvite: NewInvite) => {
         return service.post(newInvite);
      },
      async onSuccess(newInvite, variables, context) {
         if (callbacks?.onSuccessCallback)
            callbacks.onSuccessCallback(newInvite, variables, context);

         await queryClient.invalidateQueries(getInviteQueryKey({ type: QueryKeyType.LIST }));
      },
      onError: callbacks?.onErrorCallback,
   });
};

export const useAcceptInviteMutator = (callbacks?: {
   onSuccess?: (
      data: { message: string; title: string } | undefined,
      variables: { convertBody: ConvertPayload; link: string },
      context: unknown
   ) => void;
}) => {
   const service = useInviteService();
   return useMutation({
      mutationFn: async (vars: { convertBody: ConvertPayload; link: string }) => {
         return service.accept(vars.link, vars.convertBody);
      },
      onSuccess(data, variables, context) {
         if (callbacks?.onSuccess) callbacks.onSuccess(data, variables, context);
      },
   });
};

export function getInviteQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsInvite | GetOptionsInvite;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.Invite, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
