import { inject, injectable } from 'inversify';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { QUERY_SOURCE, QUERY_DESTINATION } from '../enums';

export { type QUERY_SOURCE, type QUERY_DESTINATION } from '../enums';

const PATH = '/v1/stats';

export interface UseQueryClickPayload {
   destination: QUERY_DESTINATION;
   query: string;
   queryVersionId?: number;
   source: QUERY_SOURCE;
   workspaceId?: number;
}

@injectable()
export class StatsService {
   constructor(@inject(TYPES.apiService) private apiService: ApiService) {}

   addUseQueryClick(body: UseQueryClickPayload) {
      this.apiService.post(`${PATH}/useQueryClick`, body);
   }

   addDownload(body: object) {
      this.apiService.post(`${PATH}/resultsDownload`, body);
   }
}
