import { memo, useMemo, useState } from 'react';

import LoadingError from './UI/LoadingError';
import LoadingSpinner from './UI/LoadingSpinner';
import { useListQueryLogs } from '../hooks/entities/queryLogHooks';
import { QueryVersion, createQueryVersionFromLogs, groupLogs } from '../entities';
import { getErrorMessage } from '../utilities';
import { QueryList } from './QueryVersionList';

const PAGE_SIZE = 10;

export const QueryLogList = memo(
   ({
      currentVersion,
      queryId,
      enabled,
      pageSize,
      collapse,
   }: {
      collapse?: boolean;
      currentVersion?: QueryVersion;
      enabled?: boolean;
      pageSize?: number;
      queryId?: number;
   }): JSX.Element => {
      pageSize ??= PAGE_SIZE;

      // State variables
      const [skip, setSkip] = useState(0);

      const queryLogList = useListQueryLogs({
         listOptions: {
            includeCreatedPerson: true,
            includeDataSource: true,
            myQueries: true,
            newestFirst: true,
            queryId,
            skip: skip,
            take: pageSize,
         },
         queryOptions: {
            enabled: enabled,
         },
      });
      const queries = useMemo(() => {
         return (
            queryLogList?.data &&
            groupLogs(queryLogList?.data?.items).map((logs) => createQueryVersionFromLogs(logs))
         );
      }, [queryLogList?.data]);

      if (!queries || queryLogList.isLoading) {
         return <LoadingSpinner />;
      }

      if (queryLogList.isError) {
         return <LoadingError message={getErrorMessage(queryLogList.error)} />;
      }

      if (queries.length === 0) {
         return <i>Run this query to create a log.</i>;
      }

      return (
         <QueryList
            collapse={collapse}
            currentVersion={currentVersion}
            pageSize={pageSize}
            queries={queries}
            setSkip={setSkip}
            skip={skip}
            totalRows={queryLogList.data?.totalItems ?? 0}
         />
      );
   }
);

export default QueryLogList;
