import { useInjection } from 'inversify-react';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { ExploreTabPatch } from '../../entities';
import { QueryKey, QueryKeyType } from '../../enums';
import { ExploreTabService, GetOptionsExploreTab, ListOptionsExploreTab } from '../../services';
import { TYPES } from '../../types';
import { handleError, notUndefined } from '../../utilities';

export const useEditExploreTabMutation = (callbacks?: {
   onErrorCallback?: (error: unknown, variables: ExploreTabPatch, context: unknown) => void;
   onSettled?: (
      data: ExploreTabPatch | undefined,
      error: unknown,
      variables: ExploreTabPatch,
      context: ExploreTabPatch | undefined
   ) => void;
   onSuccessCallback?: (
      updatedTab: ExploreTabPatch | undefined,
      variables: ExploreTabPatch,
      context: unknown
   ) => void;
}) => {
   const queryClient = useQueryClient();
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   return useMutation({
      mutationFn: (updatedTab: ExploreTabPatch) => {
         return exploreTabService.patch(notUndefined(updatedTab.id), updatedTab);
      },
      onMutate: async (updatedExploreTab: ExploreTabPatch) => {
         const queryKeyGet = getExploreTabQueryKey({ type: QueryKeyType.GET });
         await queryClient.cancelQueries(queryKeyGet);
         const previousTab = queryClient.getQueryData<ExploreTabPatch>(queryKeyGet);
         if (previousTab) {
            queryClient.setQueryData(queryKeyGet, _.merge(updatedExploreTab, previousTab));
         }

         return previousTab;
      },
      async onSuccess(data, variables, context) {
         await queryClient.invalidateQueries(getExploreTabQueryKey({ type: QueryKeyType.GET }));
         await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', data?.workspaceId]);
         if (callbacks?.onSuccessCallback) {
            return callbacks.onSuccessCallback(data, variables, context);
         }
      },
      onError(error, variables, context) {
         if (callbacks?.onErrorCallback) {
            return callbacks.onErrorCallback(error, variables, context);
         }

         handleError(error);
      },
      onSettled(data, error, variables, context) {
         if (callbacks?.onSettled) callbacks.onSettled(data, error, variables, context);
      },
   });
};

export function getExploreTabQueryKey(keyParams: {
   id?: number;
   options?: ListOptionsExploreTab | GetOptionsExploreTab;
   type: QueryKeyType;
}): any[] {
   const queryKey: any[] = [QueryKey.ExploreTab, keyParams.type];
   if (keyParams.id) queryKey.push(keyParams.id);
   if (keyParams.type) if (keyParams.options) queryKey.push(keyParams.options);

   return queryKey;
}
