import { useInjection } from 'inversify-react';
import { Stack } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { SnippetService } from '../services';
import { Snippet } from '../entities';
import { QueryKey } from '../enums';
import { TYPES } from '../types';
import { handleError } from '../utilities';
import { useCurrentStep, useSaveSnippet } from '../hooks';

export const QuerySnippets = () => {
   const saveSnippet = useSaveSnippet();
   const currentStep = useCurrentStep();
   const dataConnectionId = currentStep?.queryStep.dataConnectionId;
   const snippetService = useInjection<SnippetService>(TYPES.snippetService);
   const snippetKey = [QueryKey.Snippet, dataConnectionId];
   const snippets = useQuery<Snippet[] | undefined>(
      snippetKey,
      () => (dataConnectionId ? snippetService.list(dataConnectionId) : undefined),
      {
         keepPreviousData: false,
         refetchOnWindowFocus: false,
         refetchOnMount: true,
         refetchOnReconnect: true,
         retry: false,
         onError(err) {
            handleError(err);
         },
      }
   );

   const queryClient = useQueryClient();
   const touchSnippetMutation = useMutation({
      mutationFn: async (id: number) => {
         await snippetService.use({ id } as any);
         await queryClient.invalidateQueries(snippetKey);
      },
   });

   let selection = currentStep?.selection;
   if (snippets.data?.some((s) => s.snippet === selection)) {
      selection = undefined;
   }

   return (
      <Stack gap={2}>
         <div className="snippets-top-title-starting-block px-2 fs-10p fw-500">
            <div className="snippets-top-title-starting-block-underline d-inline-block">
               My Snippets
            </div>
         </div>
         <div className="dimmed-queries">
            {!selection && (
               <div className="px-2 fs-10p text-muted mb-2">
                  Highlight text in the query editor to save a snippet.
               </div>
            )}
            <Stack className="px-2" gap={2}>
               {selection && (
                  <div className="save-snippet-card">
                     <div
                        className="border-0 query-card card px-2 py-2 query-card-hover save-snippet-border"
                        onClick={(e) => {
                           e.preventDefault();
                           if (!selection) return;
                           saveSnippet(selection);
                        }}
                     >
                        <div className="query-card-top-right">
                           <span className="query-card-action border-0 fs-10p btn btn-white btn-sm">
                              Save
                           </span>
                        </div>
                        <div className="queryFontSmall cm-editor">{selection}</div>
                     </div>
                  </div>
               )}
               {snippets.data?.map((snippet, i) => (
                  <div
                     autoFocus={i === 0}
                     className="border-0 query-card card px-2 py-2 query-card-hover"
                     key={snippet.id}
                     onClick={(e) => {
                        e.preventDefault();
                        currentStep?.insert?.(snippet.snippet);
                        touchSnippetMutation.mutateAsync(snippet.id!);
                     }}
                  >
                     <div className="query-card-top-right">
                        <span className="query-card-action border-0 fs-10p btn btn-white btn-sm">
                           Insert
                        </span>
                     </div>
                     <div className="dimmed-query queryFontSmall cm-editor">{snippet.snippet}</div>
                  </div>
               ))}
            </Stack>
         </div>
      </Stack>
   );
};

export default QuerySnippets;
