import { memo, useEffect, useState } from 'react';

import { BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { DBMS } from '../../enums/dbms';
import { QueryInlineComment } from '../../entities';

const CodeViewer = memo(
   ({
      dialect,
      query,
      collapseTo,
      schema,
      comments,
      hideCopy,
   }: {
      collapseTo?: number;
      comments?: QueryInlineComment[];
      dialect?: DBMS;
      hideCopy?: boolean;
      query?: string;
      schema?: Record<string, string[]>;
   }): JSX.Element => {
      const [collapsed, setCollapsed] = useState(collapseTo !== undefined);

      useEffect(() => {
         setCollapsed(!!collapseTo);
      }, [collapseTo]);

      if (comments && !Array.isArray(comments)) {
         comments = [comments];
      }

      return (
         <div style={{ position: 'relative' }}>
            <div className="ps-0 pt-2 pb-2 pe-4 dimmed-query">
               <code
                  className={`cm-editor code-viewer ${collapsed ? 'collapsed-code-viewer' : ''}`}
               >
                  {collapsed && (query ?? '').replaceAll(/--[^\n]*/gm, '')}
                  {!collapsed &&
                     (query ?? '').split('\n').map((line, index) => {
                        const comment = comments?.find((c) => c.line === index)?.comment;
                        return (
                           <div className="query-line" key={index}>
                              {line}
                              {comment && (
                                 <>
                                    &nbsp;<i>--&nbsp;{comment}</i>
                                    <br />
                                 </>
                              )}
                           </div>
                        );
                     })}
               </code>
            </div>

            {collapsed && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                     setCollapsed(false);
                  }}
                  style={{ position: 'absolute', bottom: 9, right: 9 }}
               >
                  <BiExpandVertical className="code-view-icon-overlay" size={14} />
               </div>
            )}
            {collapseTo && !collapsed && (
               <div
                  onClick={(e) => {
                     e.stopPropagation();
                     setCollapsed(true);
                  }}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
               >
                  <BiCollapseVertical className="code-view-icon-overlay" size={14} />
               </div>
            )}
         </div>
      );
   }
);
export default CodeViewer;
